<div class="dialogBox" [ngClass]="{ 'optadata-theme': imagelang === 'odfin' }">
  <h3 appTranslationModal [translate]="headerText"></h3>
  <p>Gesamtergebnis: {{ decision }}</p>
  <p>Schufa: {{ schufa }}</p>
  <p>Crefo: {{ crefo }}</p>
  <br />
  <div>
    <p>Text Schufa:</p>
    <p *ngFor="let text of textSchufa">{{ text }}</p>
  </div>
  <br />
  <p>Text Crefo: {{ textCrefo }}</p>
  <div style="display: flex; justify-content: space-evenly" class="mt-4">
    <button mat-stroked-button color="accent" [mat-dialog-close]="false">Schließen</button>
  </div>
</div>
