import { IEnvironment } from "./env-model";

export const environment: IEnvironment = {
  production: true,
  name: 'dev',
  apiURL: 'https://dev-optadata.consileon.de/backend',
  finapi: {
    targetUrl: 'https://webform-sandbox.finapi.io',
  },
  agree21: {
    Vertragsdaten: {
      NrBerater: 1300,
      KundensegmentMcc: 51,
      KundensegmentOdfin: 52,
    },
    Wirtschaftsdaten: {
      BerufsgruppeMcc: 'Arzt, Apotheker',
      BerufsgruppeOdfin: 'Übrige Gesundheitsdienstberufe',
    },
    Legitimationsdaten: {
      GeprueftVonID: 'YC9BMMW',
      AusfOrganisation: 'Bankfiliale',
    },
  },
  oneDashboardURL: 'https://integration.entwickler.one',
  mccKeycloakConfig: {
    url: 'https://login.integration.entwickler.one/auth',
    realm: 'one',
    clientId: 'mcc',
  },
  odfinKeycloakConfig: {
    url: 'https://login.integration.entwickler.one',
    realm: 'one',
    clientId: 'od-fin',
  },
  KwsAPI: {
    url: 'https://bankhausbauer.kontowechselservice.de/INTE/SSO.aspx',
  },
};

// ng build --aot --build-optimizer --common-chunk --configuration=test --lazy-modules --optimization --progress --service-worker
