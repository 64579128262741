import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { NgClass, NgFor } from '@angular/common';
import { TranslationDirective } from '../../translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-credit-check-dialog',
    templateUrl: './credit-check-dialog.component.html',
    styleUrls: ['./credit-check-dialog.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        TranslationDirective,
        TranslateModule,
        NgFor,
        MatButton,
        MatDialogClose,
    ],
})
export class CreditCheckDialogComponent {
  imagelang: string;
  headerText: string;
  decision: string;
  schufa: string;
  crefo: string;
  textSchufa: [];
  textCrefo: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService
  ) {
    this.imagelang = this.auth.user.lang;
    this.headerText = this.data.headerText;
    this.decision = this.data.decision;
    this.schufa = this.data.schufa ? this.data.schufa : 'NA';
    this.crefo = this.data.crefo ? this.data.crefo : 'NA';
    this.textSchufa = this.data.textSchufa;
    this.textCrefo = this.data.textCrefo;
  }
}
