import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductMatrixGroup } from '../admin/children/product-matrix/product-matrix.component';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class productMatrixService {
  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {}

  saveGroup(group: ProductMatrixGroup): Observable<ProductMatrixGroup> {
    return this.http.post<ProductMatrixGroup>(`${environment.apiURL}/productMatrixRouter/saveProductMatrix`, {
      group,
      _id: this.auth.user._id,
    });
  }

  getGroups(): Observable<ProductMatrixGroup[]> {
    return this.http.get<ProductMatrixGroup[]>(`${environment.apiURL}/productMatrixRouter/getProductMatrix`);
  }

  getBerufsGruppe(berufsgruppeName: string) {
    return this.http.get<any[]>(`${environment.apiURL}/productMatrixRouter/getBerufsgruppeByName/${berufsgruppeName}`);
  }

  deleteGroup(groupId: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiURL}/productMatrixRouter/deleteGroup/${groupId}`);
  }

  deleteBerufGruppe(groupId: string, berufGruppeId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiURL}/productMatrixRouter/deleteBerufGruppe/${groupId}/berufGruppen/${berufGruppeId}`
    );
  }

  updateProductMatrixImmediately(): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      `${environment.apiURL}/productMatrixRouter/immediateUpdateProductMatrixGroups`,
      {}
    );
  }

  scheduleUpdate(groupId: string, date: Date): Observable<void> {
    return this.http.post<void>(`${environment.apiURL}/productMatrixRouter/scheduleUpdate`, {
      groupId,
      updateDate: date,
      _id: this.auth.user._id,
    });
  }

  scheduleUpdated(date: Date): Observable<void> {
    return this.http.post<void>(`${environment.apiURL}/productMatrixRouter/scheduleUpdate`, {
      updateDate: date,
      _id: this.auth.user._id,
    });
  }

  uploadProductMatrixGroups(file: File, selectedDate: Date): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('_id', this.auth.user._id);
    formData.append('selectedDate', selectedDate.toISOString());

    return this.http.post(`${environment.apiURL}/productMatrixRouter/uploadProductMatrixGroups`, formData, {
      responseType: 'text',
    });
  }
}
