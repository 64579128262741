<div class="row justify-content-center">
  <div id="header">
    <div class="lang-form-wrapper">
      <mat-form-field floatLabel="always" appearance="outline" id="lang-form">
        <mat-label appTranslationModal [translate]="'Sprachwelt auswahlen'"></mat-label>
        <mat-select (selectionChange)="loadLang($event.value)" [(ngModel)]="selectedLang">
          <mat-option *ngFor="let lang of langList" [value]="lang">
            {{ lang }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <form id="form">
        <div class="div-style">
          <mat-form-field floatLabel="always" appearance="outline" id="keyTranslate">
            <mat-label appTranslationModal [translate]="'Die Text key muss sein wie: PAGE.ELEMENT_KEY '"></mat-label>
            <input matInput placeholder="Key" name="tranlation_key" [(ngModel)]="selectedTranslation.name" />
          </mat-form-field>
        </div>

        <div class="div-style">
          <mat-form-field floatLabel="always" appearance="outline" id="keyTranslate">
            <mat-label appTranslationModal [translate]="'translation_text'"></mat-label>
            <textarea
              matInput
              placeholder="Text"
              name="translation_text"
              [(ngModel)]="selectedTranslation.value"
            ></textarea>
          </mat-form-field>
        </div>

        <div class="div-style" *ngIf="checkTranslationArray() && isZinsen">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label>ValueGültigAb </mat-label>
            <input
              matInput
              placeholder="YYYY-MM-DD"
              [(ngModel)]="selectedDate"
              [ngModelOptions]="{ standalone: true }"
              pattern="^\d{4}-\d{2}-\d{2}$"
              required
            />
          </mat-form-field>
        </div>

        <div class="div-style">
          <button
            *ngIf="checkTranslationArray() && isZinsen; else saveTranslationButton"
            mat-raised-button
            color="primary"
            type="submit"
            (click)="saveTranslationLogs()"
            [disabled]="!dateInputValid()"
          >
            <span appTranslationModal translate="Save"></span>
          </button>
          <ng-template #saveTranslationButton>
            <button mat-raised-button color="primary" type="submit" (click)="saveTranslation()">
              <span appTranslationModal translate="Save"></span>
            </button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>

  <div>
    <div class="table-container" style="padding-bottom: 1rem">
      <mat-tab-group *ngIf="translationArray" [ngClass]="{ zinsenTabColor: checkTranslationArray() }">
        <ng-container *ngFor="let group of translationArray">
          <mat-tab *ngIf="group.name !== 'Zinsen' || hasAdminBB" [label]="group.name">
            <mat-form-field floatLabel="always" appearance="outline" id="searchFF">
              <mat-label>Suche</mat-label>
              <input
                matInput
                (keyup)="applyFilter($any($event.target).value, tabelle)"
                placeholder="Filter"
                name="filter"
              />
            </mat-form-field>

            <table mat-table [dataSource]="group.value" class="full-width" #tabelle>
              <ng-container matColumnDef="key">
                <th mat-header-cell *matHeaderCellDef class="columnWidth">Key</th>
                <td mat-cell *matCellDef="let element" class="columnWidth">
                  <div>
                    {{ element.name }}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="text">
                <th mat-header-cell *matHeaderCellDef class="textWidth">Text</th>
                <td mat-cell *matCellDef="let element" class="textWidth">{{ element.value }}</td>
              </ng-container>

              <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef [ngClass]="checkTranslationArray() ? 'columnWidth' : 'btnWidth'">
                  Aktion
                </th>
                <td mat-cell *matCellDef="let element" [ngClass]="checkTranslationArray() ? 'columnWidth' : 'btnWidth'">
                  <!-- Inline div style -->
                  <div>
                    <button
                      class="formsBtn"
                      *ngIf="checkTranslationArray() && group.name === 'Zinsen'"
                      (click)="openTranslationLog()"
                    >
                      <mat-icon>open_in_new</mat-icon>
                    </button>
                    <button class="formsBtn" (click)="editTranslation(group.name, element)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button class="formsBtn" (click)="deleteTranslation(group.name, element)" id="deleteBtn">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="['key', 'text', 'buttons']"></tr>
              <tr mat-row *matRowDef="let row; columns: ['key', 'text', 'buttons']"></tr>
            </table>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>
  </div>
</div>
