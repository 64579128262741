import { User } from 'src/app/models/user';
import { QuestionTemplate } from 'src/app/pages/questionnaire/questions/question-template';
import { NotificationService } from 'src/app/services/notification.service';

import { Component, Input } from '@angular/core';
import { DepotbankWahlComponent } from '../../../../pages/questionnaire/questions/depotbank-wahl/depotbank-wahl.component';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

/**
 * Questionnaire antworten drinnen ein benutzer
 */
@Component({
    selector: 'app-question-answer',
    templateUrl: './question-answer.component.html',
    styleUrls: ['./question-answer.component.scss'],
    standalone: true,
    imports: [
        DepotbankWahlComponent,
        MatButton,
        TranslateModule,
    ],
})
export class QuestionAnswerComponent {
  @Input() user: User;

  constructor(private notification: NotificationService) {}

  saveUser(component: QuestionTemplate) {
    if (component && component.answerFormGroup.valid)
      component.saveAnswer().subscribe(() => {
        this.notification.showSuccess('ADMIN.USER_QUESTION_DATA_SAVED_SUCCESSFULLY');
      });
  }
}
