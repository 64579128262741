<div id="dialog-header">
  <span> Translation Log </span>
</div>
<mat-dialog-content class="mat-typography">
  <table mat-table [dataSource]="translationLogs" class="full-width">
    <ng-container matColumnDef="institute">
      <th mat-header-cell *matHeaderCellDef>institute</th>
      <td mat-cell *matCellDef="let element">
        {{ element.institute }}
      </td>
    </ng-container>

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef>Key</th>
      <td mat-cell *matCellDef="let element">
        <div>
          {{ element.key }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef>Text</th>
      <td mat-cell *matCellDef="let element">
        {{ element.newValue }}
      </td>
    </ng-container>

    <ng-container matColumnDef="geändertVon">
      <th mat-header-cell *matHeaderCellDef>geändertVon</th>
      <td mat-cell *matCellDef="let element">
        {{ element.changedBy }}
      </td>
    </ng-container>

    <ng-container matColumnDef="geprüftVon">
      <th mat-header-cell *matHeaderCellDef>geprüftVon</th>
      <td mat-cell *matCellDef="let element">
        {{ element.confirmedBy }}
      </td>
    </ng-container>

    <ng-container matColumnDef="geprüftAm">
      <th mat-header-cell *matHeaderCellDef>geprüftAm</th>
      <td mat-cell *matCellDef="let element">
        {{ element.confirmedOn }}
      </td>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef>Aktion</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div *ngIf="user.email !== translationLogs[i].changedBy">
          <button *ngIf="!element.confirmed" (click)="confirmTranslation(i)">
            <mat-icon>done</mat-icon>
          </button>
          <button *ngIf="!element.confirmed && !element.isValueUpdated" (click)="rejectTranslation(i)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
