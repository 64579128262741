export const land3alpha: Map<String, String> = new Map([
  ['AF', 'AFG'],
  ['AL', 'ALB'],
  ['DZ', 'DZA'],
  ['AS', 'ASM'],
  ['AD', 'AND'],
  ['AO', 'AGO'],
  ['AI', 'AIA'],
  ['AQ', 'ATA'],
  ['AG', 'ATG'],
  ['AR', 'ARG'],
  ['AM', 'ARM'],
  ['AW', 'ABW'],
  ['AU', 'AUS'],
  ['AT', 'AUT'],
  ['AZ', 'AZE'],
  ['BS', 'BHS'],
  ['BH', 'BHR'],
  ['BD', 'BGD'],
  ['BB', 'BRB'],
  ['BY', 'BLR'],
  ['BE', 'BEL'],
  ['BZ', 'BLZ'],
  ['BJ', 'BEN'],
  ['BM', 'BMU'],
  ['BT', 'BTN'],
  ['BO', 'BOL'],
  ['BA', 'BIH'],
  ['BW', 'BWA'],
  ['BV', 'BVT'],
  ['BR', 'BRA'],
  ['IO', 'IOT'],
  ['BN', 'BRN'],
  ['BN', 'BRN'],
  ['BG', 'BGR'],
  ['BF', 'BFA'],
  ['BI', 'BDI'],
  ['KH', 'KHM'],
  ['CM', 'CMR'],
  ['CA', 'CAN'],
  ['CV', 'CPV'],
  ['KY', 'CYM'],
  ['CF', 'CAF'],
  ['TD', 'TCD'],
  ['CL', 'CHL'],
  ['CN', 'CHN'],
  ['CX', 'CXR'],
  ['CC', 'CCK'],
  ['CO', 'COL'],
  ['KM', 'COM'],
  ['CG', 'COG'],
  ['CD', 'COD'],
  ['CK', 'COK'],
  ['CR', 'CRI'],
  ['CI', 'CIV'],
  ['CI', 'CIV'],
  ['HR', 'HRV'],
  ['CU', 'CUB'],
  ['CY', 'CYP'],
  ['CZ', 'CZE'],
  ['DK', 'DNK'],
  ['DJ', 'DJI'],
  ['DM', 'DMA'],
  ['DO', 'DOM'],
  ['EC', 'ECU'],
  ['EG', 'EGY'],
  ['SV', 'SLV'],
  ['GQ', 'GNQ'],
  ['ER', 'ERI'],
  ['EE', 'EST'],
  ['ET', 'ETH'],
  ['FK', 'FLK'],
  ['FO', 'FRO'],
  ['FJ', 'FJI'],
  ['FI', 'FIN'],
  ['FR', 'FRA'],
  ['GF', 'GUF'],
  ['PF', 'PYF'],
  ['TF', 'ATF'],
  ['GA', 'GAB'],
  ['GM', 'GMB'],
  ['GE', 'GEO'],
  ['DE', 'DEU'],
  ['GH', 'GHA'],
  ['GI', 'GIB'],
  ['GR', 'GRC'],
  ['GL', 'GRL'],
  ['GD', 'GRD'],
  ['GP', 'GLP'],
  ['GU', 'GUM'],
  ['GT', 'GTM'],
  ['GG', 'GGY'],
  ['GN', 'GIN'],
  ['GW', 'GNB'],
  ['GY', 'GUY'],
  ['HT', 'HTI'],
  ['HM', 'HMD'],
  ['VA', 'VAT'],
  ['HN', 'HND'],
  ['HK', 'HKG'],
  ['HU', 'HUN'],
  ['IS', 'ISL'],
  ['IN', 'IND'],
  ['ID', 'IDN'],
  ['IR', 'IRN'],
  ['IQ', 'IRQ'],
  ['IE', 'IRL'],
  ['IM', 'IMN'],
  ['IL', 'ISR'],
  ['IT', 'ITA'],
  ['JM', 'JAM'],
  ['JP', 'JPN'],
  ['JE', 'JEY'],
  ['JO', 'JOR'],
  ['KZ', 'KAZ'],
  ['KE', 'KEN'],
  ['KI', 'KIR'],
  ['KP', 'PRK'],
  ['KR', 'KOR'],
  ['KR', 'KOR'],
  ['KW', 'KWT'],
  ['KG', 'KGZ'],
  ['LA', 'LAO'],
  ['LV', 'LVA'],
  ['LB', 'LBN'],
  ['LS', 'LSO'],
  ['LR', 'LBR'],
  ['LY', 'LBY'],
  ['LY', 'LBY'],
  ['LI', 'LIE'],
  ['LT', 'LTU'],
  ['LU', 'LUX'],
  ['MO', 'MAC'],
  ['MK', 'MKD'],
  ['MG', 'MDG'],
  ['MW', 'MWI'],
  ['MY', 'MYS'],
  ['MV', 'MDV'],
  ['ML', 'MLI'],
  ['MT', 'MLT'],
  ['MH', 'MHL'],
  ['MQ', 'MTQ'],
  ['MR', 'MRT'],
  ['MU', 'MUS'],
  ['YT', 'MYT'],
  ['MX', 'MEX'],
  ['FM', 'FSM'],
  ['MD', 'MDA'],
  ['MC', 'MCO'],
  ['MN', 'MNG'],
  ['ME', 'MNE'],
  ['MS', 'MSR'],
  ['MA', 'MAR'],
  ['MZ', 'MOZ'],
  ['MM', 'MMR'],
  ['MM', 'MMR'],
  ['NA', 'NAM'],
  ['NR', 'NRU'],
  ['NP', 'NPL'],
  ['NL', 'NLD'],
  ['AN', 'ANT'],
  ['NC', 'NCL'],
  ['NZ', 'NZL'],
  ['NI', 'NIC'],
  ['NE', 'NER'],
  ['NG', 'NGA'],
  ['NU', 'NIU'],
  ['NF', 'NFK'],
  ['MP', 'MNP'],
  ['NO', 'NOR'],
  ['OM', 'OMN'],
  ['PK', 'PAK'],
  ['PW', 'PLW'],
  ['PS', 'PSE'],
  ['PA', 'PAN'],
  ['PG', 'PNG'],
  ['PY', 'PRY'],
  ['PE', 'PER'],
  ['PH', 'PHL'],
  ['PN', 'PCN'],
  ['PL', 'POL'],
  ['PT', 'PRT'],
  ['PR', 'PRI'],
  ['QA', 'QAT'],
  ['RE', 'REU'],
  ['RO', 'ROU'],
  ['RU', 'RUS'],
  ['RU', 'RUS'],
  ['RW', 'RWA'],
  ['SH', 'SHN'],
  ['KN', 'KNA'],
  ['LC', 'LCA'],
  ['PM', 'SPM'],
  ['VC', 'VCT'],
  ['VC', 'VCT'],
  ['VC', 'VCT'],
  ['WS', 'WSM'],
  ['SM', 'SMR'],
  ['ST', 'STP'],
  ['SA', 'SAU'],
  ['SN', 'SEN'],
  ['RS', 'SRB'],
  ['SC', 'SYC'],
  ['SL', 'SLE'],
  ['SG', 'SGP'],
  ['SK', 'SVK'],
  ['SI', 'SVN'],
  ['SB', 'SLB'],
  ['SO', 'SOM'],
  ['ZA', 'ZAF'],
  ['GS', 'SGS'],
  ['SS', 'SSD'],
  ['ES', 'ESP'],
  ['LK', 'LKA'],
  ['SD', 'SDN'],
  ['SR', 'SUR'],
  ['SJ', 'SJM'],
  ['SZ', 'SWZ'],
  ['SE', 'SWE'],
  ['CH', 'CHE'],
  ['SY', 'SYR'],
  ['TW', 'TWN'],
  ['TW', 'TWN'],
  ['TJ', 'TJK'],
  ['TZ', 'TZA'],
  ['TH', 'THA'],
  ['TL', 'TLS'],
  ['TG', 'TGO'],
  ['TK', 'TKL'],
  ['TO', 'TON'],
  ['TT', 'TTO'],
  ['TN', 'TUN'],
  ['TR', 'TUR'],
  ['TM', 'TKM'],
  ['TC', 'TCA'],
  ['TV', 'TUV'],
  ['UG', 'UGA'],
  ['UA', 'UKR'],
  ['AE', 'ARE'],
  ['GB', 'GBR'],
  ['US', 'USA'],
  ['UM', 'UMI'],
  ['UY', 'URY'],
  ['UZ', 'UZB'],
  ['VU', 'VUT'],
  ['VE', 'VEN'],
  ['VE', 'VEN'],
  ['VN', 'VNM'],
  ['VN', 'VNM'],
  ['VG', 'VGB'],
  ['VI', 'VIR'],
  ['WF', 'WLF'],
  ['EH', 'ESH'],
  ['YE', 'YEM'],
  ['ZM', 'ZMB'],
  ['ZW', 'ZWE'],
]);

export const Land: Map<String, String> = new Map([
  ['D', 'Deutschland'],
  ['DE', 'Deutschland'],
  ['DEU', 'Deutschland'],
  ['FR', 'Frankreich u. Monaco'],
  ['IT', 'Italien'],
  ['AT', 'Österreich'],
  ['NL', 'Niederlande'],
  ['CH', 'Schweiz'],
  ['IL', 'Israel'],
  ['GB', 'Great'],
  ['US', 'USA'],
  ['HU', 'Ungarn'],
  ['ES', 'Spanien'],
  ['MY', 'Malaysia'],
  ['IT', 'Italien'],
  ['IE', 'Irland'],
  ['DK', 'Dänemark'],
  ['FR', 'Frankreich'],
  ['RU', 'Russland'],
  ['PT', 'Portugal'],
  ['GR', 'Griechenland'],
  ['PT', 'Portugal -einschl. Azoren und Madeira'],
  ['ES', 'Spanien, Kanarische Inseln'],
  ['BE', 'Belgien'],
  ['MC', 'Monaco'],
  ['LU', 'Luxemburg'],
  ['LB', 'Libanon'],
  ['NOR', 'Norwegen'],
  ['CZ', 'Tschechien'],
  ['AU', 'Australien'],
  ['PL', 'Polen'],
  ['IE', 'Irland'],
  ['I', 'Italien'],
  ['BG', 'Bulgarien'],
  ['E', 'Spanien'],
  ['A', 'Österreich'],
  ['F', 'Frankreich'],
  ['RUS', 'Russland'],
  ['RS', 'Serbien'],
  ['EE', 'Estland'],
  ['FI', 'Finnland'],
  ['CE', 'Ceuta'],
  ['ML', 'Melilla'],
  ['IS', 'Island'],
  ['NO', 'Norwegen, Svalbart und Spitzbergen'],
  ['SE', 'Schweden'],
  ['FI', 'Finnland'],
  ['LI', 'Liechtenstein'],
  ['LT', 'Litauen'],
  ['LV', 'Lettland'],
  ['AT', 'Österreich'],
  ['CH', 'Schweiz und Büsingen'],
  ['FO', 'Färöer'],
  ['AD', 'Andorra'],
  ['GI', 'Gibraltar'],
  ['VA', 'Vatikanstadt'],
  ['MT', 'Malta'],
  ['RO', 'Rumänien'],
  ['SE', 'Schweden'],
  ['SI', 'Slowenien'],
  ['SK', 'Slowakei'],
  ['CY', 'Zypern'],
  ['ZA', 'Südafrika'],
  ['IND', 'Indien'],
  ['IR', 'Iran'],
  ['HR', 'Kroatien'],
  ['SM', 'San Marino'],
  ['TR', 'Türkei'],
  ['EE', 'Estland'],
  ['LV', 'Lettland'],
  ['LT', 'Litauen'],
  ['PL', 'Polen'],
  ['CZ', 'Tschechische Republik'],
  ['SK', 'Slowakei'],
  ['HU', 'Ungarn'],
  ['RO', 'Rumänien'],
  ['BG', 'Bulgarien'],
  ['AL', 'Albanien'],
  ['UA', 'Ukraine'],
  ['BY', 'Belarus (ehem. Weißrussland)'],
  ['MD', 'Moldau Republik (Moldavien)'],
  ['RU', 'Russische Föderation'],
  ['GE', 'Georgien'],
  ['AM', 'Armenien'],
  ['AZ', 'Aserbaidschan'],
  ['CA', 'Kanada'],
  ['CN', 'China'],
  ['KZ', 'Kasachstan'],
  ['TM', 'Turkmenistan'],
  ['UZ', 'Usbekistan'],
  ['TJ', 'Tadschikistan'],
  ['KG', 'Kirgisistan'],
  ['SI', 'Slowenien'],
  ['HR', 'Kroatien'],

  ['SG', 'Singapur'],
  ['BY', 'Weißrussland'],
  ['BA', 'Bosnien-Herzegowina'],
  ['RS-ME-XK', 'Serbien, Montenegro und Kosovo (Alt)'],
  ['XK', 'Kosovo'],
  ['MK', 'Mazedonien'],
  ['ME', 'Montenegro'],
  ['RS', 'Serbien'],
  ['AD', 'Andorra'],
  ['BEL', 'Belgien'],
  ['CS', 'Tschechien'],
  ['RS-KS', 'Serbien (einschl. Kosovo) - deaktiv 27.09.2017'],
  ['GB', 'Großbritannien, Nordirland, Kanalinseln'],
  ['GG', 'Guernsey'],
  ['JE', 'Jersey'],
  ['IM', 'Insel Man'],
  ['MA', 'Marokko'],
  ['DZ', 'Algerien'],
  ['ET', 'Äthiopien'],
  ['FL', 'Liechtenstein'],
  ['TN', 'Tunesien'],
  ['LY', 'Libysch-Arabische-Dschamahirija'],
  ['EG', 'Aegypten'],
  ['SD', 'Sudan'],
  ['SS', 'Süd-Sudan'],
  ['MR', 'Mauretanien'],
  ['IN', 'Indien'],
  ['ML', 'Mali'],
  ['BF', 'Burkina Faso (ehem. Obervolta)'],
  ['NE', 'Niger'],
  ['TD', 'Tschad'],
  ['CV', 'Kap Verde -Republik-'],
  ['SN', 'Senegal'],
  ['GM', 'Gambia'],
  ['KW', 'Kuwait'],
  ['GW', 'Guinea-Bissau, ehem. Port.-Guinea'],
  ['GN', 'Guinea -Republik-'],
  ['SL', 'Sierra Leone'],
  ['LR', 'Liberia'],
  ['CI', "Cote d' Ivoire (ehem. Elfenbeinküste)"],
  ['GH', 'Ghana'],
  ['TG', 'Togo'],
  ['BJ', 'Benin -ehemalig Dahome-'],
  ['NG', 'Nigeria'],
  ['P', 'Portugal'],
  ['S', 'Schweden'],
  ['CM', 'Kamerun'],
  ['CF', 'Zentralafrikanische Republik'],
  ['GQ', 'Aequatorialguinea'],
  ['ST', 'Sao Tomé und Principe'],
  ['GA', 'Gabun'],
  ['CG', 'Kongo -Republik'],
  ['CD', 'Kongo (ehem. Zaire)'],
  ['RW', 'Ruanda'],
  ['BI', 'Burundi'],
  ['SH', 'St.Helena,Ascension,Gough,Trist. d.Cunha'],
  ['AO', 'Angola / Cabinda'],
  ['ET', 'Äthiopien'],
  ['ER', 'Eritrea'],
  ['DJ', 'Dschibuti'],
  ['SO', 'Somalia'],
  ['SH', 'St.'],
  ['SR', 'Suriname'],
  ['GBR', 'Great'],
  ['USA', 'USA'],
  ['G', 'Griechenland'],
  ['KE', 'Kenia'],
  ['UG', 'Uganda'],
  ['TZ', 'Tansania Vereinigte Republik'],
  ['SC', 'Seychellen einschließl. Amiranteninseln'],
  ['IO', 'Brit. Territ. im Indisch. Ozean'],
  ['MZ', 'Mosambik'],
  ['MG', 'Madagaskar'],
  ['MU', 'Mauritius'],
  ['KM', 'Komoren'],
  ['YT', 'Mayotte'],
  ['L', 'Luxemburg'],
  ['UA', 'Ukraine'],
  ['MEX', 'Mexico'],
  ['ZM', 'Sambia'],
  ['ZW', 'Simbabwe (ehem. Rhodesien)'],
  ['MW', 'Malawi'],
  ['ZA', 'Südafrika'],
  ['NA', 'Namibia'],
  ['AL', 'Albanien'],
  ['BW', 'Botsuana'],
  ['BIH', 'Bosnien-Herzegowina'],
  ['SZ', 'Swasiland'],
  ['LS', 'Lesotho'],
  ['US', 'USA und Puerto Rico'],
  ['CA', 'Kanada'],
  ['GL', 'Grönland'],
  ['RI', 'Indonesien'],
  ['PM', 'St. Pierre und Miquelon'],
  ['MX', 'Mexiko'],
  ['BM', 'Bermuda'],
  ['GT', 'Guatemala'],
  ['BZ', 'Belize - ehemalig Britisch-Honduras'],
  ['HN', 'Honduras -Republik-'],
  ['SV', 'El Salvador'],
  ['NI', 'Nicaragua'],
  ['HKG', 'Hong'],
  ['LAO', 'Laos'],
  ['KHM', 'Kambodbcha'],
  ['KOR', 'Südkorea'],
  ['CR', 'Costa Rica'],
  ['PA', 'Panama'],
  ['AI', 'Anguilla'],
  ['CU', 'Kuba'],
  ['KN', 'St. Kitts u. Nevis'],
  ['HT', 'Haiti -Republik-'],
  ['BS', 'Bahamas'],
  ['TC', 'Turks-und Caicosinseln'],
  ['DO', 'Dominikanische Republik'],
  ['VI', 'Jungferninseln Amerikanisch'],
  ['AG', 'Antigua und Barbuda'],
  ['DM', 'Dominica'],
  ['KY', 'Kaimaninseln'],
  ['JM', 'Jamaika'],
  ['LC', 'St. Lucia'],
  ['VC', 'St. Vincent und Nordgrenadinen'],
  ['VG', 'Jungferninseln Britisch'],
  ['BB', 'Barbados'],
  ['MS', 'Montserrat'],
  ['B', 'Belgien'],
  ['LKA', 'Sri'],
  ['GEO', 'Georgien'],
  ['EGY', 'Ägypten'],
  ['BRN', 'Brunei'],
  ['ZWE', 'Simbabwe'],
  ['UAE', 'Vereinigte'],
  ['THA', 'Thailand'],
  ['TT', 'Trinidad und Tobago'],
  ['GD', 'Grenada (Südgrenadinen)'],
  ['AW', 'Aruba'],
  ['BQ', 'Bonaire, Saba und St. Eustatius'],
  ['CW', 'Curacao'],
  ['AN', 'Niederländische Antillen, Curacao'],
  ['SX', 'Sint Maarten'],
  ['CO', 'Kolumbien'],
  ['VE', 'Venezuela'],
  ['GY', 'Guyana-Republik'],
  ['SR', 'Surinam'],
  ['EC', 'Ecuador'],
  ['PE', 'Peru'],
  ['BR', 'Brasilien'],
  ['CL', 'Chile'],
  ['BO', 'Bolivien'],
  ['PY', 'Paraguay'],
  ['UY', 'Uruguay'],
  ['AR', 'Argentinien'],
  ['FK', 'Falklandinseln'],
  ['CY', 'Zypern'],
  ['LB', 'Libanon'],
  ['SY', 'Syrien (Arabische Republik)'],
  ['IQ', 'Irak'],
  ['IR', 'Iran (Islamische Republik)'],
  ['IL', 'Israel'],
  ['PS', 'Palästinensische Gebiete'],
  ['TL', 'Timor-Leste'],
  ['JO', 'Jordanien'],
  ['SA', 'Saudi-Arabien'],
  ['KW', 'Kuwait'],
  ['BH', 'Bahrain'],
  ['QA', 'Katar'],
  ['AE', 'Vereinigte Arabische Emirate'],
  ['OM', 'Oman'],
  ['YE', 'Jemen'],
  ['AF', 'Afghanistan'],
  ['PK', 'Pakistan'],
  ['IN', 'Indien - einschließlich Sikkim'],
  ['BD', 'Bangladesh'],
  ['MV', 'Malediven'],
  ['LK', 'Sri Lanka (Ceylon)'],
  ['NP', 'Nepal'],
  ['BT', 'Bhutan'],
  ['MM', 'Myanmar (ehem. Birma)'],
  ['TH', 'Thailand - Siam'],
  ['LA', 'Laos (Demokratische Volksrepublik)'],
  ['VN', 'Vietnam'],
  ['KH', 'Kambodscha'],
  ['ID', 'Indonesien'],
  ['MY', 'Malaysia,Sabah,Sarawak,Labuan'],
  ['BN', 'Brunei Darussalam'],
  ['SG', 'Singapur'],
  ['PH', 'Philippinen'],
  ['MN', 'Mongolei'],
  ['CN', 'China, Mandschurei und Tibet'],
  ['KP', 'Korea Dem. VR (Nordkorea)'],
  ['KR', 'Korea Republik (Südkorea)'],
  ['JP', 'Japan'],
  ['TW', 'Taiwan -Formosa-'],
  ['HK', 'Hongkong'],
  ['MO', 'Macau'],
  ['AU', 'Australien'],
  ['PG', 'Papua-Neuguinea'],
  ['NR', 'Nauru'],
  ['NZ', 'Neuseeland'],
  ['SB', 'Salomonen'],
  ['TV', 'Tuvalu'],
  ['NC', 'Neukaledonien'],
  ['WF', 'Wallis und Futuna'],
  ['KI', 'Kiribati'],
  ['PN', 'Pitcairninseln'],
  ['FJ', 'Fidschi'],
  ['VU', 'Vanuatu (ehem. Neue Hebriden)'],
  ['TO', 'Tonga'],
  ['WS', 'Samoa'],
  ['MP', 'Nördliche Marianen'],
  ['PF', 'Französich-Polynesien'],
  ['FM', 'Mikronesien (Föderierte Staaten)'],
  ['MH', 'Marshallinseln'],
  ['PW', 'Palau'],
  ['AS', 'Amerikanisch-Samoa'],
  ['GU', 'Guam'],
  ['UM', 'Amerikanische Überseeinseln'],
  ['CC', 'Kokosinseln'],
  ['CX', 'Weihnachtsinseln (Ind. Ozean)'],
  ['HM', 'Heard und MCDonaldinseln'],
  ['NF', 'Norfolkinseln'],
  ['CK', 'Cookinseln'],
  ['NU', 'Niue'],
  ['TK', 'Tokelau'],
  ['UN', 'Nicht ermittelbare Länder und Gebiete'],
  ['AQ', 'Antarktis'],
  ['BV', 'Bouvetinseln'],
  ['GS', 'Südgeorgien'],
  ['TF', 'Franz. Südgebiete'],
  ['IMF', 'Internationaler Währungsfonds (IWF)'],
  ['WB', 'Weltbank (IBRD)'],
  ['IFC', 'Internationale Finanz-Corporation (IFC)'],
  ['IDA', 'Internationale Entwicklungsorgan. (IDA)'],
  ['MIGA', 'Multilat.Investm.Guarantee-Agency(MIGA)'],
  ['EBU', 'Union d. Europ. Rundfunkorgan. (UER)'],
  ['EUMETSAT', 'Europ.Organ.f.meteor.Satel.(EUMETSAT)'],
  ['ESO', 'Europ.Organ.f.astronom.Forsch.(ESO)'],
  ['ECMWF', 'Europ.Zentr.f.mfr.Wettervorhers.(ECMWF)'],
  ['EMBL', 'Europ.Labor.f.Molekularbiologie (EMBL)'],
  ['EU', 'Europ. Gemeinschaften (EG)'],
  ['EIB', 'Europ.Investitionsbank (EIB)'],
  ['ECSC', 'Europäische Gemeinschaft für Kohle und Stahl (EGKS)'],
  ['EURATOM', 'Europäische Atomgemeinschaft (EURATOM)'],
  ['EBRD', 'Europ.Bank f.Wiederaufbau u.Entw. (EBRD)'],
  ['EMS', 'Europ. Währungssystem (EWS)'],
  ['EMI', 'Europ. Währungsinstitut (EWI/EMI)'],
  ['EIF', 'Europ. Investitionsfonds'],
  ['EPO', 'Europ. Patentorganisation (EPO)'],
  ['OECD', 'Org.f.wirtsch.Zusammenarb.u.Entw.(OECD)'],
  ['EUROCHEMIC', 'Europ.Ges.f.chem.Aufber. (EUROCHEMIC)'],
  ['EFSF', 'Europäische Finanzstabilisierungsfazilität'],
  ['ESM', 'Europäischer Stabilitätsmechanismus (ESM)'],
  ['CABEI', 'Central American Bank for Economic Integration (CABEI)'],
  ['BIS', 'Bank f.Internat.Zahlungsausgleich. (BIZ)'],
  ['Afreximbank', 'African Export-Import Bank (Afreximbank)'],
  ['EUROFIMA', 'EUROFIMA - European Company for the Financing of Railroad Rolling Stock'],
  ['UN', 'Vereinte Nationen (UNO)'],
  ['UNHCR', 'Hoh.Kommiss.d.UN f.Flüchtl.Fragen(UNHCR)'],
  ['UNRWA', 'Hilfsprog.d.UN f.arab.Flüchtl.(UNRWA)'],
  ['FLAR', 'Fondo Latino Americano de Reservas (FLAR)'],
  ['FBC', "Fonds Belgo-Congolais D'Amortissement et de Gestion"],
  ['IFFIm', 'International Finance Facility for Immunisation (IFFIm)'],
  ['FAO', 'Ernähr-.u.Landwirtsch.-Organ.d.UN (FAO)'],
  ['UNESCO', 'Org.d.UN f.Erzieh./Wiss./Kultur(UNESCO)'],
  ['IAEA', 'Internat. Atomenergie-Kommission (IAEA)'],
  ['WHO', 'Weltgesundheits-Organisation (WHO)'],
  ['UNICEF', 'Weltkinderhilfswerk (UNICEF)'],
  ['GATT', 'Genfer Allg. Zoll-u. Handelsabk. (GATT)'],
  ['ILO', 'Internationale Arbeitsorganisation (ILO)'],
  ['ITU', 'Internationaler Fernmeldeverein (ITU)'],
  ['S&L', 'Schiffs-und Luftfahrzeugbedarf'],
  ['TDB', 'Ost- und Südafrikanische Handels- und Entwicklungsbank (TDB)'],
  ['ITA', 'Internat. Zinn-Org. / Zinnrat (ITA)'],
  ['ADF', 'Asiatischer Entwicklungsfonds'],
  ['AIIB', 'Asiatische Infrastrukturinvestmentbank (AIIB)'],
  ['CEB', 'Entwicklungsbank des Europarats (CEB)'],
  ['IDB', 'Islamische Entwicklungsbank (IDB)'],
  ['IMC', 'Zw.Staatl. Komitee f.Auswand. (IMC/CIM)'],
  ['ICRC', 'Internat. Komitee v. Roten Kreuz (ICRC)'],
  ['NATO', 'Nordatlantikpakt-Organisation (NATO)'],
  ['SRM', 'Einheitliches Bankenabwicklungsgremium'],
  ['COE', 'Europa-Rat (EURAT)'],
  ['CERN', 'Europ. Organ. f. Kernforschung (CERN)'],
  ['ESA', 'Europ. Weltraum-Organisation (ESA)'],
  ['EUROCONTROL', 'Europ.Org.z.Sich.d.Luftf. (EUROCONTROL)'],
  ['IDB', 'Interamerikan.Entwicklungsbank (IDB)'],
  ['ADF', 'Afrikanischer Entwicklungsfonds'],
  ['UIOK', 'Uebr.Intern.Organ.-Kreditinst. (UIOK)'],
  ['AsDB', 'Asiatische Entwicklungsbank (AsDB)'],
  ['AfDB', 'Afrikanische Entwicklungsbank (AfDB)'],
  ['IIC', 'Interamerikan.Investm.-Corporation (IIC)'],
  ['EUTELSAT', 'Europ.Fernmeldesatelliten-Org.(EUTELSAT)'],
  ['INTELSAT', 'Intern.Telecomm.Satellite.Org.(INTELSAT)'],
  ['IFAD', 'Intern.Fond.f.landwirt.Entwicklung(IFAD)'],
  ['CDB', 'Karibische Entwicklungsbank (CDB)'],
  ['IBEC', 'Intern.Bank f.wirtsch.Zusammenarb.(IBEC)'],
  ['IIB', 'Internationale Investitionsbank (IIB)'],
  ['UIOH', 'Uebr.Intern.Org.-öffentl.Haushalte(UIOH)'],
  ['ADC', 'Andean Development Corporation (ADC)'],
  ['NIB', 'Nordic Investment Bank (NIB)'],
  ['AMF', 'Arab Monetary Fund (AMF)'],
]);

export const Anrede = new Map([
  [0, 'Keine Anrede'],
  [1, 'Herr'],
  [2, 'Frau'],
  [4, 'Firma'],
  [5, 'Herren'],
  [7, 'Eheleute'],
  [8, 'Herr und Frau'],
  [9, 'Damen'],
]);

export const Title = new Map([
  [64, 'Dr.'],
  [67, 'Dr. Dr.'],
  [68, 'Dr. Dr. med.'],
  [100, 'Dres.'],
  [175, 'Prof.'],
  [177, 'Prof. Dr.'],
  [178, 'Prof. Dr. Dr.'],
  [179, 'Prof. Dr. Dr. med.'],
  [184, 'Prof. Dr. med.'],
  [191, 'Univ.-Prof.'],
  [192, 'Univ.-Prof. Dr.'],
  [193, 'Univ.-Prof. Dr. med'],
]);

export const Nationalität = new Map([
  [0, 'Deutschland'],
  [1, 'Frankreich u. Monaco'],
  [3, 'Niederlande'],
  [5, 'Italien'],
  [7, 'Irland'],
  [8, 'Dänemark'],
  [9, 'Griechenland'],
  [10, 'Portugal -einschl. Azoren und Madeira'],
  [11, 'Spanien, Kanarische Inseln'],
  [17, 'Belgien'],
  [18, 'Luxemburg'],
  [21, 'Ceuta'],
  [23, 'Melilla'],
  [24, 'Island'],
  [28, 'Norwegen, Svalbart und Spitzbergen'],
  [30, 'Schweden'],
  [32, 'Finnland'],
  [37, 'Liechtenstein'],
  [38, 'Österreich'],
  [39, 'Schweiz und Büsingen'],
  [41, 'Färöer'],
  [43, 'Andorra'],
  [44, 'Gibraltar'],
  [45, 'Vatikanstadt'],
  [46, 'Malta'],
  [47, 'San Marino'],
  [52, 'Türkei'],
  [53, 'Estland'],
  [54, 'Lettland'],
  [55, 'Litauen'],
  [60, 'Polen'],
  [61, 'Tschechische Republik'],
  [63, 'Slowakei'],
  [64, 'Ungarn'],
  [66, 'Rumänien'],
  [68, 'Bulgarien'],
  [70, 'Albanien'],
  [72, 'Ukraine'],
  [73, 'Belarus (ehem. Weißrussland)'],
  [74, 'Moldau Republik (Moldavien)'],
  [75, 'Russische Föderation'],
  [76, 'Georgien'],
  [77, 'Armenien'],
  [78, 'Aserbaidschan'],
  [79, 'Kasachstan'],
  [80, 'Turkmenistan'],
  [81, 'Usbekistan'],
  [82, 'Tadschikistan'],
  [83, 'Kirgisistan'],
  [91, 'Slowenien'],
  [92, 'Kroatien'],
  [93, 'Bosnien-Herzegowina'],
  [94, 'Serbien, Montenegro und Kosovo (Alt)'],
  [95, 'Kosovo'],
  [96, 'Mazedonien'],
  [97, 'Montenegro'],
  [98, 'Serbien'],
  [99, 'Serbien (einschl. Kosovo) - deaktiv 27.09.2017'],
  [106, 'Großbritannien, Nordirland, Kanalinseln'],
  [107, 'Guernsey'],
  [108, 'Jersey'],
  [109, 'Insel Man'],
  [204, 'Marokko'],
  [208, 'Algerien'],
  [212, 'Tunesien'],
  [216, 'Libysch-Arabische-Dschamahirija'],
  [220, 'Aegypten'],
  [224, 'Sudan'],
  [225, 'Süd-Sudan'],
  [228, 'Mauretanien'],
  [232, 'Mali'],
  [236, 'Burkina Faso (ehem. Obervolta)'],
  [240, 'Niger'],
  [244, 'Tschad'],
  [247, 'Kap Verde -Republik-'],
  [248, 'Senegal'],
  [252, 'Gambia'],
  [257, 'Guinea-Bissau, ehem. Port.-Guinea'],
  [260, 'Guinea -Republik-'],
  [264, 'Sierra Leone'],
  [268, 'Liberia'],
  [272, "Cote d' Ivoire (ehem. Elfenbeinküste)"],
  [276, 'Ghana'],
  [280, 'Togo'],
  [284, 'Benin -ehemalig Dahome-'],
  [288, 'Nigeria'],
  [302, 'Kamerun'],
  [306, 'Zentralafrikanische Republik'],
  [310, 'Aequatorialguinea'],
  [311, 'Sao Tomé und Principe'],
  [314, 'Gabun'],
  [318, 'Kongo -Republik'],
  [322, 'Kongo (ehem. Zaire)'],
  [324, 'Ruanda'],
  [328, 'Burundi'],
  [329, 'St.Helena,Ascension,Gough,Trist. d.Cunha'],
  [330, 'Angola / Cabinda'],
  [334, 'Äthiopien'],
  [336, 'Eritrea'],
  [338, 'Dschibuti'],
  [342, 'Somalia'],
  [346, 'Kenia'],
  [350, 'Uganda'],
  [352, 'Tansania Vereinigte Republik'],
  [355, 'Seychellen einschließl. Amiranteninseln'],
  [357, 'Brit. Territ. im Indisch. Ozean'],
  [366, 'Mosambik'],
  [370, 'Madagaskar'],
  [373, 'Mauritius'],
  [375, 'Komoren'],
  [377, 'Mayotte'],
  [378, 'Sambia'],
  [382, 'Simbabwe (ehem. Rhodesien)'],
  [386, 'Malawi'],
  [388, 'Südafrika'],
  [389, 'Namibia'],
  [391, 'Botsuana'],
  [393, 'Swasiland'],
  [395, 'Lesotho'],
  [400, 'USA und Puerto Rico'],
  [404, 'Kanada'],
  [406, 'Grönland'],
  [408, 'St. Pierre und Miquelon'],
  [412, 'Mexiko'],
  [413, 'Bermuda'],
  [416, 'Guatemala'],
  [421, 'Belize - ehemalig Britisch-Honduras'],
  [424, 'Honduras -Republik-'],
  [428, 'El Salvador'],
  [432, 'Nicaragua'],
  [436, 'Costa Rica'],
  [442, 'Panama'],
  [446, 'Anguilla'],
  [448, 'Kuba'],
  [449, 'St. Kitts u. Nevis'],
  [452, 'Haiti -Republik-'],
  [453, 'Bahamas'],
  [454, 'Turks-und Caicosinseln'],
  [456, 'Dominikanische Republik'],
  [457, 'Jungferninseln Amerikanisch'],
  [459, 'Antigua und Barbuda'],
  [460, 'Dominica'],
  [463, 'Kaimaninseln'],
  [464, 'Jamaika'],
  [465, 'St. Lucia'],
  [467, 'St. Vincent und Nordgrenadinen'],
  [468, 'Jungferninseln Britisch'],
  [469, 'Barbados'],
  [470, 'Montserrat'],
  [472, 'Trinidad und Tobago'],
  [473, 'Grenada (Südgrenadinen)'],
  [474, 'Aruba'],
  [475, 'Bonaire, Saba und St. Eustatius'],
  [477, 'Curacao'],
  [478, 'Niederländische Antillen, Curacao'],
  [479, 'Sint Maarten'],
  [480, 'Kolumbien'],
  [484, 'Venezuela'],
  [488, 'Guyana-Republik'],
  [492, 'Surinam'],
  [500, 'Ecuador'],
  [504, 'Peru'],
  [508, 'Brasilien'],
  [512, 'Chile'],
  [516, 'Bolivien'],
  [520, 'Paraguay'],
  [524, 'Uruguay'],
  [528, 'Argentinien'],
  [529, 'Falklandinseln'],
  [600, 'Zypern'],
  [604, 'Libanon'],
  [608, 'Syrien (Arabische Republik)'],
  [612, 'Irak'],
  [616, 'Iran (Islamische Republik)'],
  [624, 'Israel'],
  [625, 'Palästinensische Gebiete'],
  [626, 'Timor-Leste'],
  [628, 'Jordanien'],
  [632, 'Saudi-Arabien'],
  [636, 'Kuwait'],
  [640, 'Bahrain'],
  [644, 'Katar'],
  [647, 'Vereinigte Arabische Emirate'],
  [649, 'Oman'],
  [653, 'Jemen'],
  [660, 'Afghanistan'],
  [662, 'Pakistan'],
  [664, 'Indien - einschließlich Sikkim'],
  [666, 'Bangladesh'],
  [667, 'Malediven'],
  [669, 'Sri Lanka (Ceylon)'],
  [672, 'Nepal'],
  [675, 'Bhutan'],
  [676, 'Myanmar (ehem. Birma)'],
  [680, 'Thailand - Siam'],
  [684, 'Laos (Demokratische Volksrepublik)'],
  [690, 'Vietnam'],
  [696, 'Kambodscha'],
  [700, 'Indonesien'],
  [701, 'Malaysia,Sabah,Sarawak,Labuan'],
  [703, 'Brunei Darussalam'],
  [706, 'Singapur'],
  [708, 'Philippinen'],
  [716, 'Mongolei'],
  [720, 'China, Mandschurei und Tibet'],
  [724, 'Korea Dem. VR (Nordkorea)'],
  [728, 'Korea Republik (Südkorea)'],
  [732, 'Japan'],
  [736, 'Taiwan -Formosa-'],
  [740, 'Hongkong'],
  [743, 'Macau'],
  [800, 'Australien'],
  [801, 'Papua-Neuguinea'],
  [803, 'Nauru'],
  [804, 'Neuseeland'],
  [806, 'Salomonen'],
  [807, 'Tuvalu'],
  [809, 'Neukaledonien'],
  [811, 'Wallis und Futuna'],
  [812, 'Kiribati'],
  [813, 'Pitcairninseln'],
  [815, 'Fidschi'],
  [816, 'Vanuatu (ehem. Neue Hebriden)'],
  [817, 'Tonga'],
  [819, 'Samoa'],
  [820, 'Nördliche Marianen'],
  [822, 'Französich-Polynesien'],
  [823, 'Mikronesien (Föderierte Staaten)'],
  [824, 'Marshallinseln'],
  [825, 'Palau'],
  [830, 'Amerikanisch-Samoa'],
  [831, 'Guam'],
  [832, 'Amerikanische Überseeinseln'],
  [833, 'Kokosinseln'],
  [834, 'Weihnachtsinseln (Ind. Ozean)'],
  [835, 'Heard und MCDonaldinseln'],
  [836, 'Norfolkinseln'],
  [837, 'Cookinseln'],
  [838, 'Niue'],
  [839, 'Tokelau'],
  [858, 'Nicht ermittelbare Länder und Gebiete'],
  [891, 'Antarktis'],
  [892, 'Bouvetinseln'],
  [893, 'Südgeorgien'],
  [894, 'Franz. Südgebiete'],
  [901, 'Internationaler Währungsfonds (IWF)'],
  [902, 'Weltbank (IBRD)'],
  [903, 'Internationale Finanz-Corporation (IFC)'],
  [904, 'Internationale Entwicklungsorgan. (IDA)'],
  [905, 'Multilat.Investm.Guarantee-Agency(MIGA)'],
  [906, 'Union d. Europ. Rundfunkorgan. (UER)'],
  [907, 'Europ.Organ.f.meteor.Satel.(EUMETSAT)'],
  [908, 'Europ.Organ.f.astronom.Forsch.(ESO)'],
  [909, 'Europ.Zentr.f.mfr.Wettervorhers.(ECMWF)'],
  [910, 'Europ.Labor.f.Molekularbiologie (EMBL)'],
  [911, 'Europ. Gemeinschaften (EG)'],
  [912, 'Europ.Investitionsbank (EIB)'],
  [913, 'Europäische Gemeinschaft für Kohle und Stahl (EGKS)'],
  [914, 'Europäische Atomgemeinschaft (EURATOM)'],
  [915, 'Europ.Bank f.Wiederaufbau u.Entw. (EBRD)'],
  [917, 'Europ. Währungssystem (EWS)'],
  [918, 'Europ. Währungsinstitut (EWI/EMI)'],
  [919, 'Europ. Investitionsfonds'],
  [920, 'Europ. Patentorganisation (EPO)'],
  [921, 'Org.f.wirtsch.Zusammenarb.u.Entw.(OECD)'],
  [923, 'Europ.Ges.f.chem.Aufber. (EUROCHEMIC)'],
  [925, 'Europäische Finanzstabilisierungsfazilität'],
  [926, 'Europäischer Stabilitätsmechanismus (ESM)'],
  [927, 'Central American Bank for Economic Integration (CABEI)'],
  [928, 'Bank f.Internat.Zahlungsausgleich. (BIZ)'],
  [929, 'African Export-Import Bank (Afreximbank)'],
  [930, 'EUROFIMA - European Company for the Financing of Railroad Rolling Stock'],
  [931, 'Vereinte Nationen (UNO)'],
  [932, 'Hoh.Kommiss.d.UN f.Flüchtl.Fragen(UNHCR)'],
  [933, 'Hilfsprog.d.UN f.arab.Flüchtl.(UNRWA)'],
  [934, 'Fondo Latino Americano de Reservas (FLAR)'],
  [935, "Fonds Belgo-Congolais D'Amortissement et de Gestion"],
  [936, 'International Finance Facility for Immunisation (IFFIm)'],
  [937, 'Ernähr-.u.Landwirtsch.-Organ.d.UN (FAO)'],
  [938, 'Org.d.UN f.Erzieh./Wiss./Kultur(UNESCO)'],
  [939, 'Internat. Atomenergie-Kommission (IAEA)'],
  [941, 'Weltgesundheits-Organisation (WHO)'],
  [942, 'Weltkinderhilfswerk (UNICEF)'],
  [943, 'Genfer Allg. Zoll-u. Handelsabk. (GATT)'],
  [945, 'Internationale Arbeitsorganisation (ILO)'],
  [946, 'Internationaler Fernmeldeverein (ITU)'],
  [950, 'Schiffs-und Luftfahrzeugbedarf'],
  [951, 'Ost- und Südafrikanische Handels- und Entwicklungsbank (TDB)'],
  [955, 'Internat. Zinn-Org. / Zinnrat (ITA)'],
  [956, 'Asiatischer Entwicklungsfonds'],
  [957, 'Asiatische Infrastrukturinvestmentbank (AIIB)'],
  [958, 'Entwicklungsbank des Europarats (CEB)'],
  [959, 'Islamische Entwicklungsbank (IDB)'],
  [960, 'Zw.Staatl. Komitee f.Auswand. (IMC/CIM)'],
  [963, 'Internat. Komitee v. Roten Kreuz (ICRC)'],
  [971, 'Nordatlantikpakt-Organisation (NATO)'],
  [972, 'Einheitliches Bankenabwicklungsgremium'],
  [973, 'Europa-Rat (EURAT)'],
  [974, 'Europ. Organ. f. Kernforschung (CERN)'],
  [975, 'Europ. Weltraum-Organisation (ESA)'],
  [977, 'Europ.Org.z.Sich.d.Luftf. (EUROCONTROL)'],
  [978, 'Interamerikan.Entwicklungsbank (IDB)'],
  [979, 'Afrikanischer Entwicklungsfonds'],
  [980, 'Uebr.Intern.Organ.-Kreditinst. (UIOK)'],
  [981, 'Asiatische Entwicklungsbank (AsDB)'],
  [982, 'Afrikanische Entwicklungsbank (AfDB)'],
  [983, 'Interamerikan.Investm.-Corporation (IIC)'],
  [984, 'Europ.Fernmeldesatelliten-Org.(EUTELSAT)'],
  [985, 'Intern.Telecomm.Satellite.Org.(INTELSAT)'],
  [986, 'Intern.Fond.f.landwirt.Entwicklung(IFAD)'],
  [987, 'Karibische Entwicklungsbank (CDB)'],
  [988, 'Intern.Bank f.wirtsch.Zusammenarb.(IBEC)'],
  [989, 'Internationale Investitionsbank (IIB)'],
  [990, 'Uebr.Intern.Org.-öffentl.Haushalte(UIOH)'],
  [996, 'Andean Development Corporation (ADC)'],
  [997, 'Nordic Investment Bank (NIB)'],
  [998, 'Arab Monetary Fund (AMF)'],
]);

export const Geburtsland = new Map([
  [4, 'Afghanistan'],
  [8, 'Albanien'],
  [10, 'Antarktis'],
  [12, 'Algerien'],
  [16, 'Amerikanisch Samoa'],
  [20, 'Andorra'],
  [24, 'Angola'],
  [28, 'Antigua und Barbuda'],
  [31, 'Aserbaidschan'],
  [32, 'Argentinien'],
  [36, 'Australien'],
  [40, 'Österreich'],
  [44, 'Bahamas'],
  [48, 'Bahrain'],
  [50, 'Bangladesh'],
  [51, 'Armenien'],
  [52, 'Barbados'],
  [56, 'Belgien'],
  [60, 'Bermuda'],
  [64, 'Bhutan'],
  [68, 'Bolivien'],
  [70, 'Bosnien und Herzegowina'],
  [72, 'Botswana'],
  [74, 'Bouvet Insel'],
  [76, 'Brasilien'],
  [84, 'Belize'],
  [86, 'Britisch Territ. im Indisch. Ozean'],
  [90, 'Salomonen-Inseln'],
  [92, 'Jungferninseln Britisch'],
  [96, 'Brunei Darussalem'],
  [100, 'Bulgarien'],
  [104, 'Myanmar'],
  [108, 'Burundi'],
  [112, 'Belarus (ehem. Weißrußland)'],
  [116, 'Kambodscha'],
  [120, 'Kamerun'],
  [124, 'Kanada'],
  [132, 'Kap Verde'],
  [136, 'Kaimaninseln (Cayman I.)'],
  [140, 'Zentralafrikanische Republik'],
  [144, 'Sri Lanka'],
  [148, 'Tschad'],
  [152, 'Chile'],
  [156, 'Volksrepublik China'],
  [158, 'Taiwan'],
  [162, 'Weihnachtsinseln'],
  [166, 'Kokosinseln'],
  [170, 'Kolumbien'],
  [174, 'Komoren'],
  [175, 'Mayotte'],
  [178, 'Republik Kongo'],
  [180, 'Kongo Dem.Rep. (ehem.Zaire)'],
  [184, 'Cookinseln'],
  [188, 'Costa Rica'],
  [191, 'Kroatien'],
  [192, 'Kuba'],
  [196, 'Zypern'],
  [203, 'Tschechische Republik'],
  [204, 'Benin'],
  [208, 'Dänemark'],
  [212, 'Dominica'],
  [214, 'Dominikanische Republik'],
  [218, 'Ecuador'],
  [222, 'El Salvador'],
  [226, 'Äquatorialguinea'],
  [231, 'Äthiopien'],
  [232, 'Eritrea'],
  [233, 'Estland'],
  [234, 'Faröer'],
  [238, 'Malvinas (Falklandinseln)'],
  [239, 'South Sandwich Islands'],
  [242, 'Fidschi Inseln'],
  [246, 'Finnland'],
  [248, 'Aland'],
  [250, 'Frankreich'],
  [254, 'Französisch Guayana'],
  [258, 'Französisch Polynesien'],
  [260, 'Französische Süd-Territorien'],
  [262, 'Dschibuti'],
  [266, 'Gabun'],
  [268, 'Georgien'],
  [270, 'Gambia'],
  [275, 'Palästinensische Gebiete'],
  [276, 'Deutschland'],
  [288, 'Ghana'],
  [292, 'Gibraltar'],
  [296, 'Kiribati'],
  [300, 'Griechenland'],
  [304, 'Grönland'],
  [308, 'Grenada (Südgrenadinen)'],
  [312, 'Guadeloupe'],
  [316, 'Guam'],
  [320, 'Guatemala'],
  [324, 'Guinea'],
  [328, 'Guayana'],
  [332, 'Haiti'],
  [334, 'Heardinseln und Mc Donald Inseln'],
  [336, 'Vatikanstadt'],
  [340, 'Honduras'],
  [344, 'Hongkong'],
  [348, 'Ungarn'],
  [352, 'Island'],
  [356, 'Indien'],
  [360, 'Indonesien'],
  [364, 'Iran Islamische Rep.'],
  [368, 'Irak'],
  [372, 'Irland'],
  [376, 'Israel'],
  [380, 'Italien'],
  [384, 'Cote d-Ivoire (ehem. Elfenbeinküste)'],
  [388, 'Jamaika'],
  [392, 'Japan'],
  [398, 'Kasachstan'],
  [400, 'Jordanien'],
  [404, 'Kenia'],
  [408, 'Korea Dem. VR (Nordkorea)'],
  [410, 'Korea Republik (Südkorea)'],
  [414, 'Kuwait'],
  [417, 'Kirgisistan'],
  [418, 'Laos Dem. Rep.'],
  [422, 'Libanon'],
  [426, 'Lesotho'],
  [428, 'Lettland'],
  [430, 'Liberia'],
  [434, 'Libysch-Arabisch Dschamahirija'],
  [438, 'Liechtenstein'],
  [440, 'Litauen'],
  [442, 'Luxemburg'],
  [446, 'Macao'],
  [450, 'Madagaskar'],
  [454, 'Malawi'],
  [458, 'Malaysia'],
  [462, 'Malediven'],
  [466, 'Mali'],
  [470, 'Malta'],
  [474, 'Martinique'],
  [478, 'Mauretanien'],
  [480, 'Mauritius'],
  [484, 'Mexico'],
  [492, 'Monaco'],
  [496, 'Mongolei'],
  [498, 'Moldau Rep. (Moldawien)'],
  [499, 'Montenegro'],
  [500, 'Montserrat'],
  [504, 'Marokko'],
  [508, 'Mozambik (Mozambique)'],
  [512, 'Oman'],
  [516, 'Namibia'],
  [520, 'Nauru'],
  [524, 'Nepal'],
  [528, 'Niederlande'],
  [530, 'Niederländische Antillen (ehemalig)'],
  [531, 'Curacao'],
  [533, 'Aruba'],
  [534, 'Sint Maarten (niederl. Teil)'],
  [535, 'Bonaire, Sint Eustatius und Saba (Niederlande)'],
  [540, 'Neukaledonien'],
  [548, 'Vanuatu'],
  [554, 'Neuseeland'],
  [558, 'Nicaragua'],
  [562, 'Niger'],
  [566, 'Nigeria'],
  [570, 'Niue'],
  [574, 'Norfolkinseln'],
  [578, 'Norwegen'],
  [580, 'Nördliche Marianeninseln'],
  [581, 'US-Inseln (kleinere äußere Inseln)'],
  [583, 'Mikronesien (Föderierte Staaten)'],
  [584, 'Marshallinseln'],
  [585, 'Palau'],
  [586, 'Pakistan'],
  [591, 'Panama'],
  [598, 'Papua Neuguinea'],
  [600, 'Paraguay'],
  [604, 'Peru'],
  [608, 'Philippinen'],
  [612, 'Pitcairn-Inseln'],
  [616, 'Polen'],
  [620, 'Portugal'],
  [624, 'Guinea-Bissau'],
  [626, 'Ost-Timor'],
  [630, 'Puerto Rico'],
  [634, 'Katar (Qatar)'],
  [638, 'Reunion'],
  [642, 'Rumänien'],
  [643, 'Russische Föderation'],
  [646, 'Ruanda'],
  [652, 'Saint-Barthelemy'],
  [654, 'St. Helena'],
  [659, 'St. Kitts/Nevis'],
  [660, 'Anguilla'],
  [662, 'St. Lucia'],
  [663, 'Saint-Martin (franz.Teil)'],
  [666, 'Miquelon (St. Pierre u. M.)'],
  [670, 'St. Vincent u. Nordgrenadinen'],
  [674, 'San Marino'],
  [678, 'Principe (Sao Tome u.P.)'],
  [682, 'Saudi-Arabien'],
  [686, 'Senegal'],
  [688, 'Serbien'],
  [690, 'Seychellen'],
  [694, 'Sierra Leone'],
  [702, 'Singapur'],
  [703, 'Slowakische Republik'],
  [704, 'Vietnam'],
  [705, 'Slowenien'],
  [706, 'Somalia'],
  [710, 'Südafrika'],
  [716, 'Simbabwe (Zimbabwe)'],
  [724, 'Spanien'],
  [728, 'Südsudan'],
  [729, 'Sudan'],
  [732, 'Westsahara'],
  [736, 'Sudan (alt)'],
  [740, 'Suriname'],
  [744, 'Jan Mayen (Svalbard u J.M.)'],
  [748, 'Eswatini'],
  [752, 'Schweden'],
  [756, 'Schweiz'],
  [760, 'Syrien Arabische Rep.'],
  [762, 'Tadschikistan'],
  [764, 'Thailand'],
  [768, 'Togo'],
  [772, 'Tokelauinseln'],
  [776, 'Tonga'],
  [780, 'Tobago (Trinidad u.T.)'],
  [784, 'Arabische Emirate (Vereinigte AE)'],
  [788, 'Tunesien'],
  [792, 'Türkei'],
  [795, 'Turkmenistan'],
  [796, 'Caicosinseln und Turkinseln'],
  [798, 'Tuvalu'],
  [800, 'Uganda'],
  [804, 'Ukraine'],
  [807, 'Nordmazedonien'],
  [818, 'Ägypten'],
  [826, 'Großbritannien und Nordirland'],
  [827, 'Guernsey (alt)'],
  [828, 'Isle of Man (alt)'],
  [829, 'Jersey (Alt)'],
  [831, 'Guernsey (Kanalinsel)'],
  [832, 'Jersey'],
  [833, 'Insel Man'],
  [834, 'Tansania Ver. Rep.'],
  [840, 'USA'],
  [850, 'Jungferninseln Amerikanisch'],
  [854, 'Burkina Faso'],
  [858, 'Uruguay'],
  [860, 'Usbekistan'],
  [862, 'Venezuela'],
  [876, 'Futuna Inseln (Wallis u.F.)'],
  [882, 'Samoa'],
  [887, 'Jemen (Arabische Republik)'],
  [891, 'Serbien u. Montenegro (ehemalig)'],
  [894, 'Sambia'],
  [999, 'Sonstiges Land'],
]);

export const BundesLand = new Map([
  [0, 'nicht bekannt'],
  [1, 'Baden-Württemberg'],
  [2, 'Bayern'],
  [3, 'Berlin'],
  [4, 'Brandenburg'],
  [5, 'Bremen'],
  [6, 'Hamburg'],
  [7, 'Hessen'],
  [8, 'Mecklenburg-Vorpommern'],
  [9, 'Niedersachsen'],
  [10, 'Nordrhein-Westfalen'],
  [11, 'Rheinland-Pfalz'],
  [12, 'Saarland'],
  [13, 'Sachsen'],
  [14, 'Sachsen-Anhalt'],
  [15, 'Schleswig-Holstein'],
  [16, 'Thüringen'],
]);

export const Ausweisart = new Map([
  [1, 'Personalausweis'],
  [2, 'Reisepass'],
  [8, 'Personalausweis (vorläufig ausgestellt)'],
  [9, 'Reisepass (vorläufig ausgestellt)'],
]);

export const Berufsgruppe = new Map([
  [100, 'Landwirtschaftlicher Beruf'],
  [200, 'Tierwirtschaftlicher Beruf'],
  [300, 'Verwalter, Berater, techn.Fachkraft in Land- und Tierwirtschaft'],
  [500, 'Gartenbauberuf'],
  [600, 'Forst- u.Jagdberufe'],
  [700, 'Bergleute'],
  [800, 'Mineral-, Erdöl-, Erdgasgewinner und -aufbereiter'],
  [1000, 'Steinbearbeiter'],
  [1100, 'Baustoffhersteller'],
  [1200, 'Keramiker'],
  [1300, 'Glasherstellungs-, Glasbearbeitungsberuf'],
  [1400, 'Chemieberuf'],
  [1500, 'Kunststoffverarbeiter'],
  [1600, 'Papierhersteller, -verarbeiter'],
  [1700, 'Druck-, Druckweiterverarbeitungsberuf'],
  [1800, 'Holzbearbeiter, Holz-, Flechtwarenhersteller'],
  [1900, 'Beruf in der Hütten- und Halbzeugindustrie'],
  [2000, 'Giessereiberuf'],
  [2100, 'Metallverformer (spanlos)'],
  [2200, 'Metallverformer (spanend)'],
  [2300, 'Metalloberflächenbearbeiter, -vergüter, -beschichter'],
  [2400, 'Metallverbindungsberuf'],
  [2500, 'Metall- und Anlagenbauberuf (Schmiede)'],
  [2600, 'Feinblechner, Installateure'],
  [2700, 'Maschinenbau-, Maschinenwartungsberuf'],
  [2800, 'Flugzeugbau-, Fahrzeugbau-, -wartungsberuf, Mechaniker'],
  [2900, 'Werkzeugmacher'],
  [3000, 'Feinmechaniker, Industriemechaniker (Geräte-und Feinwerktechnik)'],
  [3100, 'Elektriker, Elektroinstallateur'],
  [3200, 'Montierer, Metallberuf'],
  [3300, 'Spinnberufe'],
  [3400, 'Textilhersteller'],
  [3500, 'Textilverarbeiter'],
  [3600, 'Textilveredler'],
  [3700, 'Lederhersteller, Leder- u. Fellverarbeiter'],
  [3900, 'Back-, Konditor-, Süsswarenhersteller'],
  [4000, 'Fleischer'],
  [4100, 'Koch'],
  [4200, 'Getränke-, Genussmittelhersteller'],
  [4300, 'Übrige Ernährungsberufe'],
  [4400, 'Hochbauberuf, Maurer, Betonbauer, Gerüstbauer'],
  [4600, 'Tiefbau-, Strassenbau-, Gleisbauberuf'],
  [4700, 'Bauhilfsarbeiter'],
  [4800, 'Ausbauberuf, Stukkateur, Glaser, Fliesenleger, Zimmerer, Dachdecker'],
  [4900, 'Raumausstatter, Polsterer'],
  [5000, 'Holz-, Kunststoffverarbeitung, Tischler, Modellbauer, Holzmechanik'],
  [5100, 'Maler, Lackierer u. verwandte Berufe'],
  [5200, 'Warenprüfer, Versandfertigmacher'],
  [5300, 'Hilfsarbeiter ohne nähere Tätigkeitsangabe'],
  [5400, 'Maschinenführer (z.B. Kranführer), Anlagenführer'],
  [5500, 'Maschineneinrichter'],
  [6000, 'Ingenieure'],
  [6100, 'Chemiker, Physiker, Mathematiker'],
  [6200, 'Techniker, Technischer Angestellter'],
  [6300, 'Technische Sonderfachkräfte'],
  [6400, 'Technischer Zeichner'],
  [6500, 'Industrie-, Werk-, Ausbildungsmeister'],
  [6600, 'Verkäufer'],
  [6700, 'Gross-, Einzelhandelskaufmann,'],
  [6800, 'Warenkaufleute, Vertreter'],
  [6900, 'Bank-, Versicherungskaufmann, Bausparkassenfachmann'],
  [7000, 'Andere Dienstleistungskaufleute und zugehörige Berufe'],
  [7100, 'Berufe des Landverkehrs'],
  [7200, 'Berufe des Wasser- und Luftverkehrs'],
  [7300, 'Berufe des Nachrichtenverkehrs'],
  [7400, 'Lagerverwalter, Lager-, Transportarbeiter'],
  [7500, 'Unternehmer, Unternehmensberater, Wirtschaftsprüfer, Steuerberufe'],
  [7600, 'Abgeordnete, administrativ entscheidende Berufstätige'],
  [7700, 'Rechnungskaufleute, Datenverarbeitungsberufe'],
  [7800, 'Bürofach-, Bürohilfskräfte, Kaufmännischer Angestellter'],
  [7900, 'Dienst-, Wachberufe'],
  [8000, 'Sicherheitsberufe'],
  [8100, 'Beruf im Rechts- und Vollstreckungswesen'],
  [8200, 'Publizisten, Dolmetscher, Bibliothekare'],
  [8300, 'Künstlerische und zugeordnete Berufe'],
  [8400, 'Arzt, Apotheker'],
  [8500, 'Übrige Gesundheitsdienstberufe'],
  [8600, 'Sozialer Beruf'],
  [8700, 'Lehrer'],
  [8800, 'Geistes- u.naturwissenschaftliche Berufe'],
  [8900, 'Berufe in der Seelsorge'],
  [9000, 'Friseure, Kosmetiker, Berufe in der Körperpflege'],
  [9100, 'Hotel-,Gaststättenberufe'],
  [9200, 'Haus-, Ernährungswirtschaftlicher Beruf'],
  [9300, 'Reinigungs-, Entsorgungsberuf'],
  [9700, 'Mithelfende Familienangehörige ausserhalb der Landwirtschaft'],
  [9800, 'Arbeitskräfte mit (noch) nicht bestimmten Beruf'],
  [9900, 'Arbeitskräfte ohne nähere Tätigkeitsangabe'],
]);

export const Registergericht = new Map([
  [1, 'Aachen'],
  [2, 'Altenburg'],
  [3, 'Amberg'],
  [4, 'Ansbach'],
  [5, 'Apolda'],
  [6, 'Arnsberg'],
  [7, 'Arnstadt'],
  [8, 'Arnstadt Zweigstelle Ilmenau'],
  [9, 'Aschaffenburg'],
  [10, 'Augsburg'],
  [11, 'Aurich'],
  [12, 'Bad Hersfeld'],
  [13, 'Bad Homburg v.d.H.'],
  [14, 'Bad Kreuznach'],
  [15, 'Bad Oeynhausen'],
  [16, 'Bad Salzungen'],
  [17, 'Bamberg'],
  [18, 'Bayreuth'],
  [19, 'Berlin (Charlottenburg)'],
  [20, 'Bielefeld'],
  [21, 'Bochum'],
  [22, 'Bonn'],
  [23, 'Braunschweig'],
  [24, 'Bremen'],
  [25, 'Bückeburg / ungültig'],
  [26, 'Chemnitz'],
  [27, 'Coburg'],
  [28, 'Coesfeld'],
  [29, 'Cottbus'],
  [30, 'Darmstadt'],
  [31, 'Deggendorf'],
  [32, 'Delmenhorst / ungültig'],
  [33, 'Dortmund'],
  [34, 'Dresden'],
  [35, 'Duisburg'],
  [36, 'Düren'],
  [37, 'Düsseldorf'],
  [38, 'Eisenach'],
  [39, 'Erfurt'],
  [40, 'Eschwege'],
  [41, 'Essen'],
  [42, 'Flensburg'],
  [43, 'Frankfurt am Main'],
  [44, 'Frankfurt (Oder)'],
  [45, 'Freiburg'],
  [46, 'Friedberg'],
  [47, 'Fritzlar'],
  [48, 'Fulda'],
  [49, 'Fürth'],
  [50, 'Gelsenkirchen'],
  [51, 'Gera'],
  [52, 'Gießen'],
  [53, 'Gotha'],
  [54, 'Göttingen'],
  [55, 'Greiz'],
  [56, 'Gütersloh'],
  [57, 'Hagen'],
  [58, 'Hamburg'],
  [59, 'Hamm'],
  [60, 'Hanau'],
  [61, 'Hannover'],
  [62, 'Heilbad Heiligenstadt'],
  [63, 'Hildburghausen'],
  [64, 'Hildesheim'],
  [65, 'Hof'],
  [66, 'Homburg'],
  [67, 'Ingolstadt'],
  [68, 'Iserlohn'],
  [69, 'Jena'],
  [70, 'Kaiserslautern'],
  [71, 'Kassel'],
  [72, 'Kempten (Allgäu)'],
  [73, 'Kiel'],
  [74, 'Kleve'],
  [75, 'Koblenz'],
  [76, 'Köln'],
  [77, 'Königstein'],
  [78, 'Korbach'],
  [79, 'Krefeld'],
  [80, 'Landau'],
  [81, 'Landshut'],
  [82, 'Langenfeld / ungültig'],
  [83, 'Lebach'],
  [84, 'Leipzig'],
  [85, 'Lemgo'],
  [86, 'Limburg'],
  [87, 'Lübeck'],
  [88, 'Ludwigshafen a.Rhein (Ludwigshafen)'],
  [89, 'Lüneburg'],
  [90, 'Mainz'],
  [91, 'Mannheim'],
  [92, 'Marburg'],
  [93, 'Meiningen'],
  [94, 'Memmingen'],
  [95, 'Merzig'],
  [96, 'Mönchengladbach'],
  [97, 'Montabaur'],
  [98, 'Mühlhausen'],
  [99, 'Mühlhausen Zweigstelle Bad Langensalza / ungültig'],
  [100, 'München'],
  [101, 'Münster'],
  [102, 'Neubrandenburg'],
  [103, 'Neunkirchen'],
  [104, 'Neuruppin'],
  [105, 'Neuss'],
  [106, 'Nienburg (Weser) / ungültig'],
  [107, 'Nordhausen'],
  [108, 'Nürnberg'],
  [109, 'Offenbach am Main'],
  [110, 'Oldenburg (Oldenburg)'],
  [111, 'Osnabrück'],
  [112, 'Osterholz-Scharmbeck /ungültig'],
  [113, 'Ottweiler'],
  [114, 'Paderborn'],
  [115, 'Passau'],
  [116, 'Pinneberg'],
  [117, 'Pößneck'],
  [118, 'Pößneck Zweigstelle Bad Lobenstein'],
  [119, 'Potsdam'],
  [120, 'Recklinghausen'],
  [121, 'Regensburg'],
  [122, 'Rinteln / ungültig'],
  [123, 'Rostock'],
  [124, 'Rotenburg (Wümme) (Rotenburg Wümme) / ungültig'],
  [125, 'Rudolstadt'],
  [126, 'Rudolstadt Zweigstelle Saalfeld'],
  [127, 'Saarbrücken'],
  [128, 'Saarlouis'],
  [129, 'Schweinfurt'],
  [130, 'Schwerin'],
  [131, 'Siegburg'],
  [132, 'Siegen'],
  [133, 'Sömmerda'],
  [134, 'Sondershausen'],
  [135, 'Sonneberg'],
  [136, 'St. Ingbert (St Ingbert)'],
  [137, 'St. Wendel (St Wendel)'],
  [138, 'Stadthagen'],
  [139, 'Stadtroda'],
  [140, 'Steinfurt'],
  [141, 'Stendal'],
  [142, 'Stralsund'],
  [143, 'Straubing'],
  [144, 'Stuttgart'],
  [145, 'Suhl'],
  [146, 'Tostedt'],
  [147, 'Traunstein'],
  [148, 'Ulm'],
  [149, 'Vechta / ungültig'],
  [150, 'Verden (Aller) / ungültig'],
  [151, 'Völklingen'],
  [152, 'Walsrode'],
  [153, 'Weiden i. d. OPf.'],
  [154, 'Weimar'],
  [155, 'Wetzlar'],
  [156, 'Wiesbaden'],
  [157, 'Wittlich'],
  [158, 'Wuppertal'],
  [159, 'Würzburg'],
  [160, 'Zweibrücken'],
  [161, 'Greifswald'],
  [162, 'Güstrow'],
  [163, 'Ludwigslust'],
  [164, 'Pasewalk'],
  [165, 'Ribnitz-Damgarten'],
  [166, 'Waren (Müritz)'],
  [167, 'Wismar'],
  [999, 'Sonstiges Gericht'],
]);

export const Wirtschaftzweig = new Map([
  [1000, 'Landwirtschaft und Jagd'],
  [1100, 'Pflanzenbau'],
  [1110, 'Ackerbau'],
  [1111, 'Getreidebau'],
  [1112, 'Allgemeiner Ackerbau'],
  [1120, 'Gartenbau'],
  [1121, 'Gemüsebau'],
  [1122, 'Zierpflanzenbau'],
  [1123, 'Baumschulen'],
  [1124, 'Allgemeiner Gartenbau'],
  [1130, 'Dauerkulturbau'],
  [1131, 'Obstbau'],
  [1132, 'Weinbau'],
  [1133, 'Allgemeiner Dauerkulturbau'],
  [1200, 'Tierhaltung'],
  [1210, 'Haltung von Rindern'],
  [1211, 'Milchviehhaltung'],
  [1212, 'Gemischte Rindviehhaltung'],
  [1213, 'Gemischte Weideviehhaltung'],
  [1220, 'Haltung von Schafen, Ziegen, Pferden und Eseln'],
  [1221, 'Haltung von Schafen und Ziegen'],
  [1222, 'Haltung von Pferden und Eseln'],
  [1230, 'Haltung von Schweinen'],
  [1240, 'Haltung von Geflügel'],
  [1250, 'Sonstige Tierhaltung'],
  [1300, 'Gemischte Landwirtschaft'],
  [1301, 'Gemischte Landwirtschaft, ohne ausgeprägten Schwerpunkt'],
  [1302, 'Gemischte Landwirtschaft, mit Schwerpunkt Pflanzenbau'],
  [1303, 'Gemischte Landwirtschaft, mit Schwerpunkt Tierhaltung'],
  [1400, 'Erbringung von landwirtschaftlichen und gärtnerischen Dienstleistungen'],
  [
    1410,
    'Erbringung von landwirtschaftlichen Dienstleistungen für den Pflanzenbau sowie von gärtnerischen Dienstleistungen',
  ],
  [1411, 'Erbringung von landwirtschaftlichen Dienstleistungen für den Pflanzenbau'],
  [1412, 'Garten- und Landschaftsbau'],
  [1413, 'Erbringung von gärtnerischen Dienstleistungen (ohne Garten- und Landschaftsbau)'],
  [1420, 'Erbringung von landwirtschaftlichen Dienstleistungen für die Tierhaltung'],
  [1500, 'Jagd'],
  [2000, 'Forstwirtschaft'],
  [2010, 'Forstwirtschaft (ohne Erbringung von forstwirtschaftlichen Dienstleistungen)'],
  [2020, 'Erbringung von forstwirtschaftlichen Dienstleistungen'],
  [5000, 'Fischerei und Fischzucht'],
  [5010, 'Fischerei'],
  [5011, 'Hochsee- und Küstenfischerei'],
  [5012, 'Fluss- und Seenfischerei'],
  [5020, 'Teichwirtschaft und Fischzucht'],
  [10000, 'Kohlenbergbau, Torfgewinnung'],
  [10100, 'Steinkohlenbergbau und -brikettherstellung'],
  [10101, 'Steinkohlenbergbau'],
  [10102, 'Herstellung von Steinkohlenbriketts'],
  [10200, 'Braunkohlenbergbau und -veredlung'],
  [10300, 'Torfgewinnung und -veredlung'],
  [11000, 'Gewinnung von Erdöl und Erdgas, Erbringung damit verbundener Dienstleistungen|'],
  [11100, 'Gewinnung von Erdöl und Erdgas'],
  [11200, 'Erbringung von Dienstleistungen bei der Gewinnung von Erdöl und Erdgas'],
  [12000, 'Bergbau auf Uran- und Thoriumerze'],
  [13000, 'Erzbergbau'],
  [13100, 'Eisenerzbergbau'],
  [13200, 'NE-Metallerzbergbau (ohne Bergbau auf Uran- und Thoriumerze)'],
  [14000, 'Gewinnung von Steinen und Erden, sonstiger Bergbau'],
  [14100, 'Gewinnung von Natursteinen'],
  [14110, 'Gewinnung von Naturwerksteinen und Natursteinen, anderweitig nicht genannt'],
  [14111, 'Gewinnung von Naturwerksteinen, anderweitig nicht genannt'],
  [14112, 'Gewinnung von Natursteinen, anderweitig nicht genannt'],
  [14120, 'Gewinnung von Kalk- und Gipsstein sowie Anhydrit, Dolomit und Kreide'],
  [14121, 'Gewinnung von Kalkstein, Dolomitstein und Kreide'],
  [14122, 'Gewinnung von Gips- und Anhydritstein'],
  [14130, 'Gewinnung von Schiefer'],
  [14200, 'Gewinnung von Kies, Sand, Ton und Kaolin'],
  [14210, 'Gewinnung von Kies und Sand'],
  [14220, 'Gewinnung von Ton und Kaolin'],
  [14221, 'Gewinnung von Ton'],
  [14222, 'Gewinnung von Kaolin'],
  [14300, 'Gewinnung von Mineralien für die Herstellung von chemischen Erzeugnissen'],
  [14301, 'Kaligewinnung'],
  [14302, 'Gewinnung von Mineralien, anderweitig nicht genannt'],
  [14400, 'Gewinnung von Salz'],
  [14500, 'Gewinnung von Steinen und Erden, anderweitig nicht genannt, sonstiger Bergbau'],
  [15000, 'Ernährungsgewerbe'],
  [15001, 'Herstellung von Tafelsalz (ohne Gewinnung von Salz)'],
  [15100, 'Schlachten und Fleischverarbeitung'],
  [15110, 'Schlachten (ohne Schlachten von Geflügel)'],
  [15111, 'Schlachten (ohne Schlachten von Geflügel, Talgschmelzen und Schmalzsiedereien)'],
  [15112, 'Talgschmelzen und Schmalzsiedereien'],
  [15120, 'Schlachten von Geflügel'],
  [15130, 'Fleischverarbeitung'],
  [15200, 'Fischverarbeitung'],
  [15300, 'Obst- und Gemüseverarbeitung'],
  [15310, 'Kartoffelverarbeitung'],
  [15320, 'Herstellung von Frucht- und Gemüsesäften'],
  [15330, 'Obst- und Gemüseverarbeitung, anderweitig nicht genannt'],
  [15331, 'Herstellung von Obst- und Gemüsekonserven'],
  [15332, 'Herstellung von Sauerkonserven'],
  [15334, 'Herstellung von Konfitüren sowie von Brotaufstrichen auf Fruchtbasis'],
  [15335, 'Herstellung von Fruchtzubereitungen für die Milch- und Eiscremeindustrie'],
  [15400, 'Herstellung von pflanzlichen und tierischen Ölen und Fetten'],
  [15410, 'Herstellung von rohen Ölen und Fetten'],
  [15420, 'Herstellung von raffinierten Ölen und Fetten'],
  [15430, 'Herstellung von Margarine u.ä. Nahrungsfetten'],
  [15500, 'Milchverarbeitung, Herstellung von Speiseeis'],
  [15510, 'Milchverarbeitung'],
  [15520, 'Herstellung von Speiseeis'],
  [15600, 'Mahl- und Schälmühlen, Herstellung von Stärke und Stärkeerzeugnissen'],
  [15610, 'Mahl- und Schälmühlen'],
  [15611, 'Mahlmühlen'],
  [15612, 'Schälmühlen'],
  [15620, 'Herstellung von Stärke und Stärkeerzeugnisse'],
  [15700, 'Herstellung von Futtermitteln'],
  [15710, 'Herstellung von Futtermitteln für Nutztiere'],
  [15720, 'Herstellung von Futtermitteln für sonstige Tiere'],
  [15800, 'Sonstiges Ernährungsgewerbe (ohne Getränkeherstellung)'],
  [15810, 'Herstellung von Backwaren (ohne Dauerbackwaren)'],
  [15811, 'Herstellung von Backwaren (ohne Konditor- und Dauerbackwaren)'],
  [15812, 'Herstellung von Konditorwaren'],
  [15820, 'Herstellung von Dauerbackwaren'],
  [15830, 'Herstellung von Zucker'],
  [15840, 'Herstellung von Süßwaren (ohne Dauerbackwaren)'],
  [15850, 'Herstellung von Teigwaren'],
  [15860, 'Verarbeitung von Kaffee und Tee, Herstellung von Kaffee-Ersatz'],
  [15870, 'Herstellung von Würzmitteln und Saucen'],
  [15880, 'Herstellung von homogenisierten und diätetischen Nahrungsmitteln'],
  [15890, 'Herstellung von sonstigen Nahrungsmitteln (ohne Getränke)'],
  [15891, 'Herstellung von Backmitteln'],
  [15892, 'Herstellung von Nahrungsmitteln, anderweitig nicht genannt (ohne Getränke)'],
  [15900, 'Herstellung von Getränken'],
  [15910, 'Herstellung von Spirituosen'],
  [15920, 'Herstellung von Alkohol'],
  [15930, 'Herstellung von Traubenwein'],
  [15940, 'Herstellung von Apfelwein und sonstigen Fruchtweinen'],
  [15950, 'Herstellung von Wermutwein und sonstigen aromatisierten Weinen'],
  [15960, 'Herstellung von Bier'],
  [15970, 'Herstellung von Malz'],
  [15980, 'Gewinnung natürlicher Mineralwässer, Herstellung von Erfrischungsgetränken'],
  [16000, 'Tabakverarbeitung'],
  [16001, 'Tabakverarbeitung (ohne Herstellung von Zigaretten)'],
  [16002, 'Herstellung von Zigaretten'],
  [17000, 'Textilgewerbe'],
  [17100, 'Spinnstoffaufbereitung und Spinnerei'],
  [17110, 'Baumwollaufbereitung und -spinnerei'],
  [17120, 'Wollaufbereitung und Streichgarnspinnerei'],
  [17130, 'Wollaufbereitung und Kammgarnspinnerei'],
  [17140, 'Flachsaufbereitung und -spinnerei'],
  [17150, 'Zwirnen und Texturieren von Filamentgarnen, Seidenaufbereitung und -spinnerei'],
  [17160, 'Herstellung von Nähgarn'],
  [17170, 'Sonstige Spinnstoffaufbereitung und Spinnerei'],
  [17200, 'Weberei'],
  [17210, 'Baumwollweberei'],
  [17211, 'Baumwollweberei (ohne Möbel-, Dekorations- und Gardinenstoffweberei)'],
  [17212, 'Möbel- und Dekorationsstoffweberei'],
  [17213, 'Gardinenstoffweberei'],
  [17220, 'Streichgarnweberei'],
  [17230, 'Kammgarnweberei'],
  [17240, 'Seiden- und Filamentgarnweberei'],
  [17250, 'Sonstige Weberei'],
  [17300, 'Textilveredlung'],
  [17400, 'Herstellung von konfektionierten Textilwaren (ohne Bekleidung)'],
  [17401, 'Herstellung von Haus-, Bett- und Tischwäsche'],
  [17402, 'Herstellung von Bettwaren (ohne Matratzen)'],
  [17403, 'Herstellung von konfektionierten textilen Artikeln für die Innenausstattung'],
  [17404, 'Herstellung von sonstigen konfektionierten textilen Artikeln, anderweitig nicht genannt'],
  [17500, 'Sonstiges Textilgewerbe (ohne Herstellung von Maschenware)'],
  [17510, 'Herstellung von Teppichen'],
  [17520, 'Herstellung von Seilerwaren'],
  [17530, 'Herstellung von Vliesstoff und Erzeugnissen daraus (ohne Bekleidung)'],
  [17540, 'Textilgewerbe, anderweitig nicht genannt'],
  [17541, 'Bandweberei und -flechterei'],
  [17542, 'Sonstiges Textilgewerbe, anderweitig nicht genannt'],
  [17600, 'Herstellung von gewirktem und gestricktem Stoff'],
  [17601, 'Herstellung von gewirktem und gestricktem Stoff (ohne Gardinenstoff)'],
  [17602, 'Herstellung von gewirktem Gardinenstoff'],
  [17700, 'Herstellung von gewirkten und gestrickten Fertigerzeugnissen'],
  [17710, 'Herstellung von Strumpfwaren'],
  [17720, 'Herstellung von Pullovern, Strickjacken u.ä. Waren'],
  [18000, 'Bekleidungsgewerbe'],
  [18100, 'Herstellung von Lederbekleidung'],
  [18200, 'Herstellung von Bekleidung (ohne Lederbekleidung)'],
  [18210, 'Herstellung von Arbeits- und Berufsbekleidung'],
  [18220, 'Herstellung von Oberbekleidung (ohne Arbeits- und Berufsbekleidung)'],
  [18221, 'Herstellung von gewebter Oberbekleidung für Herren und Knaben'],
  [18222, 'Herstellung von gewebter Oberbekleidung für Damen und Mädchen'],
  [18223, 'Herstellung von gewirkter und gestrickter Oberbekleidung'],
  [18230, 'Herstellung von Wäsche'],
  [18231, 'Herstellung von gewebter Wäsche (ohne Miederwaren)'],
  [18232, 'Herstellung von gewirkter und gestrickter Wäsche (ohne Miederwaren)'],
  [18233, 'Herstellung von Miederwaren'],
  [18240, 'Herstellung von sonstiger Bekleidung und Bekleidungszubehör'],
  [18241, 'Herstellung von Sportbekleidung'],
  [18242, 'Herstellung von Hüten und sonstigen Kopfbedeckungen'],
  [18243, 'Herstellung von Bekleidung und Bekleidungszubehör für Kleinkinder'],
  [18244, 'Herstellung von sonstigen gewirkten und gestrickten Fertigerzeugnissen'],
  [18245, 'Herstellung von Bekleidungszubehör, anderweitig nicht genannt'],
  [18300, 'Zurichtung und Färben von Fellen, Herstellung von Pelzwaren'],
  [19000, 'Ledergewerbe'],
  [19100, 'Herstellung von Leder und Lederfaserstoff'],
  [19200, 'Lederverarbeitung (ohne Herstellung von Lederbekleidung und Schuhen)'],
  [19300, 'Herstellung von Schuhen'],
  [19301, 'Herstellung von Sportschuhen'],
  [19302, 'Herstellung von sonstigen Schuhen'],
  [20000, 'Holzgewerbe (ohne Herstellung von Möbeln)'],
  [20100, 'Säge-, Hobel- und Holzimprägnierwerke'],
  [20200, 'Herstellung von Furnier-, Sperrholz-, Holzfaser- und Holzspanplatten'],
  [20201, 'Herstellung von Furnier-, Sperrholz- und Holzfaserplatten'],
  [20202, 'Herstellung von Holzspanplatten'],
  [20300, 'Herstellung von Konstruktionsteilen, Fertigbauteilen, Ausbauelementen und Fertigteilbauten aus Holz'],
  [20301, 'Herstellung von Konstruktionsteilen, Fertigbauteilen und Ausbauelementen aus Holz'],
  [20302, 'Herstellung von Bausätzen für Fertigteilbauten aus Holz im Hochbau sowie von Fertigteilbauten daraus'],
  [20400, 'Herstellung von Verpackungsmitteln, Lagerbehältern und Ladungsträgern aus Holz'],
  [
    20500,
    'Herstellung von Holzwaren, anderweitig nicht genannt, sowie von Kork-, Flecht- und Korbwaren (ohne Herstellung von Möbeln)',
  ],
  [20510, 'Herstellung von Holzwaren, anderweitig nicht genannt (ohne Herstellung von Möbeln)'],
  [20520, 'Herstellung von Kork-, Flecht- und Korbwaren (ohne Herstellung von Möbeln)'],
  [21000, 'Papiergewerbe'],
  [21100, 'Herstellung von Holz- und Zellstoff, Papier, Karton und Pappe'],
  [21110, 'Herstellung von Holz- und Zellstoff'],
  [21120, 'Herstellung von Papier, Karton und Pappe'],
  [21200, 'Herstellung von Waren aus Papier, Karton und Pappe'],
  [21210, 'Herstellung von Wellpapier und -pappe sowie von Verpackungsmitteln aus Papier, Karton und Pappe'],
  [21220, 'Herstellung von Haushalts-, Hygiene- und Toilettenartikeln aus Zellstoff, Papier und Pappe'],
  [21230, 'Herstellung von Schreibwaren und Bürobedarf aus Papier, Karton und Pappe'],
  [21240, 'Herstellung von Tapeten'],
  [21250, 'Herstellung von sonstigen Waren aus Papier, Karton und Pappe'],
  [22000, 'Verlagsgewerbe, Druckgewerbe, Vervielfältigung von bespielten Ton-, Bild- und Datenträgern'],
  [22100, 'Verlagsgewerbe'],
  [22110, 'Verlegen von Büchern'],
  [22111, 'Verlegen von Büchern (ohne Adressbücher)'],
  [22112, 'Verlegen von Adressbüchern'],
  [22120, 'Verlegen von Zeitungen'],
  [22121, 'Verlegen von Tageszeitungen'],
  [22122, 'Verlegen von Wochen- und Sonntagszeitungen'],
  [22130, 'Verlegen von Zeitschriften'],
  [22131, 'Verlegen von Fachzeitschriften'],
  [22132, 'Verlegen von allgemeinen Zeitschriften'],
  [22133, 'Verlegen von sonstigen Zeitschriften'],
  [22140, 'Verlegen von bespielten Tonträgern und Musikalien'],
  [22141, 'Verlegen von bespielten Tonträgern'],
  [22142, 'Verlegen von Musikalien'],
  [22150, 'Sonstiges Verlagsgewerbe'],
  [22200, 'Druckgewerbe'],
  [22210, 'Drucken von Zeitungen'],
  [22220, 'Drucken anderer Druckerzeugnisse'],
  [22230, 'Druckweiterverarbeitung'],
  [22240, 'Druck- und Medienvorstufe'],
  [22250, 'Erbringung von sonstigen druckbezogenen Dienstleistungen'],
  [22300, 'Vervielfältigung von bespielten Ton-, Bild- und Datenträgern'],
  [22310, 'Vervielfältigung von bespielten Tonträgern'],
  [22320, 'Vervielfältigung von bespielten Bildträgern'],
  [22330, 'Vervielfältigung von bespielten Datenträgern'],
  [23000, 'Kokerei, Mineralölverarbeitung, Herstellung und Verarbeitung von Spalt- und Brutstoffen'],
  [23100, 'Kokerei'],
  [23200, 'Mineralölverarbeitung'],
  [23300, 'Herstellung und Verarbeitung von Spalt- und Brutstoffen'],
  [24000, 'Herstellung von chemischen Erzeugnissen'],
  [24100, 'Herstellung von chemischen Grundstoffen'],
  [24110, 'Herstellung von Industriegasen'],
  [24120, 'Herstellung von Farbstoffen und Pigmenten'],
  [24130, 'Herstellung von sonstigen anorganischen Grundstoffen und Chemikalien'],
  [24140, 'Herstellung von sonstigen organischen Grundstoffen und Chemikalien'],
  [24150, 'Herstellung von Düngemitteln und Stickstoffverbindungen'],
  [24160, 'Herstellung von Kunststoffen in Primärformen'],
  [24170, 'Herstellung von synthetischem Kautschuk in Primärformen'],
  [24200, 'Herstellung von Schädlingsbekämpfungs-, Pflanzenschutz- und Desinfektionsmitteln'],
  [24300, 'Herstellung von Anstrichmitteln, Druckfarben und Kitten'],
  [24400, 'Herstellung von pharmazeutischen Erzeugnissen'],
  [24410, 'Herstellung von pharmazeutischen Grundstoffen'],
  [24420, 'Herstellung von pharmazeutischen Spezialitäten und sonstigen pharmazeutischen Erzeugnissen'],
  [24500, 'Herstellung von Seifen, Wasch-, Reinigungs- und Körperpflegemitteln sowie von Duftstoffen'],
  [24510, 'Herstellung von Seifen, Wasch-, Reinigungs- und Poliermitteln'],
  [24520, 'Herstellung von Duftstoffen und Körperpflegemitteln'],
  [24600, 'Herstellung von sonstigen chemischen Erzeugnissen'],
  [24610, 'Herstellung von pyrotechnischen Erzeugnissen'],
  [24620, 'Herstellung von Klebstoffen und Gelatine'],
  [24630, 'Herstellung von etherischen Ölen'],
  [24640, 'Herstellung von fotochemischen Erzeugnissen'],
  [24650, 'Herstellung von unbespielten Ton-, Bild- und Datenträgern'],
  [24660, 'Herstellung von sonstigen chemischen Erzeugnissen, anderweitig nicht genannt'],
  [24700, 'Herstellung von Chemiefasern'],
  [25000, 'Herstellung von Gummi- und Kunststoffwaren'],
  [25100, 'Herstellung von Gummiwaren'],
  [25110, 'Herstellung von Bereifungen'],
  [25120, 'Runderneuerung von Bereifungen'],
  [25130, 'Herstellung von sonstigen Gummiwaren'],
  [25200, 'Herstellung von Kunststoffwaren'],
  [25210, 'Herstellung von Platten, Folien, Schläuchen und Profilen aus Kunststoffen'],
  [25220, 'Herstellung von Verpackungsmitteln aus Kunststoffen'],
  [25230, 'Herstellung von Baubedarfsartikeln aus Kunststoffen'],
  [25240, 'Herstellung von sonstigen Kunststoffwaren'],
  [25241, 'Herstellung von technischen Kunststoffteilen'],
  [25242, 'Herstellung von Fertigerzeugnissen aus Kunststoffen'],
  [26000, 'Glasgewerbe, Herstellung von Keramik, Verarbeitung von Steinen und Erden'],
  [26100, 'Herstellung von Glas und Glaswaren'],
  [26110, 'Herstellung von Flachglas'],
  [26120, 'Veredlung und Bearbeitung von Flachglas'],
  [26130, 'Herstellung von Hohlglas'],
  [26131, 'Herstellung von Behältnissen aus Glas'],
  [26132, 'Herstellung von Trinkgläsern und Tischzubehör aus Glas'],
  [26140, 'Herstellung von Glasfasern und Waren daraus'],
  [26141, 'Herstellung von Verstärkungsglasfasern'],
  [26142, 'Herstellung von Isolierglasfasern u.ä.'],
  [26150, 'Herstellung, Veredlung und Bearbeitung von sonstigem Glas einschließlich technischen Glaswaren'],
  [26200, 'Herstellung von keramischen Erzeugnissen (ohne Herstellung von Ziegeln und Baukeramik)'],
  [26210, 'Herstellung von keramischen Haushaltswaren und Ziergegenständen'],
  [26211, 'Herstellung von Haushaltswaren und Ziergegenständen aus Porzellan'],
  [26212, 'Herstellung von Haushaltswaren und Ziergegenständen aus Steingut, Steinzeug und Feinsteinzeug'],
  [26213, 'Herstellung von Haushaltswaren und Ziergegenständen aus Ton sowie von Töpferwaren'],
  [26220, 'Herstellung von Sanitärkeramik'],
  [26230, 'Herstellung von keramischen Isolatoren und Isolierteilen'],
  [26240, 'Herstellung von keramischen Erzeugnissen für sonstige technische Zwecke'],
  [26250, 'Herstellung von keramischen Erzeugnissen, anderweitig nicht genannt'],
  [26260, 'Herstellung von feuerfesten keramischen Werkstoffen und Waren'],
  [26261, 'Herstellung von Waren aus feuerfesten keramischen Werkstoffen'],
  [26262, 'Herstellung von ungeformten feuerfesten keramischen Werkstoffen'],
  [26300, 'Herstellung von keramischen Wand- und Bodenfliesen und -platten'],
  [26400, 'Herstellung von Ziegeln und sonstiger Baukeramik'],
  [26401, 'Herstellung von Ziegeln'],
  [26402, 'Herstellung von sonstiger Baukeramik'],
  [26500, 'Herstellung von Zement, Kalk und gebranntem Gips'],
  [26510, 'Herstellung von Zement'],
  [26520, 'Herstellung von Kalk'],
  [26530, 'Herstellung von gebranntem Gips'],
  [26600, 'Herstellung von Erzeugnissen aus Beton, Zement und Gips'],
  [26610, 'Herstellung von Erzeugnissen aus Beton, Zement und aus Kalksandstein für den Bau'],
  [26611, 'Herstellung von Bausätzen für Fertigteilbauten aus Beton für den Bau'],
  [
    26612,
    'Herstellung von Konstruktionsteilen und großformatigen Fertigbauteilen aus Beton sowie von sonstigen Betonerzeugnissen für den Bau',
  ],
  [26613, 'Herstellung von Erzeugnissen aus Porenbeton für den Bau'],
  [26614, 'Herstellung von Erzeugnissen aus Kalksandstein für den Bau'],
  [26620, 'Herstellung von Gipserzeugnissen für den Bau'],
  [26630, 'Herstellung von Frischbeton (Transportbeton)'],
  [26640, 'Herstellung von Mörtel und anderem Beton (Trockenbeton)'],
  [26650, 'Herstellung von Faserzementwaren'],
  [26660, 'Herstellung von Erzeugnissen aus Beton, Zement und Gips, anderweitig nicht genannt'],
  [26700, 'Be- und Verarbeitung von Naturwerksteinen und Natursteinen, anderweitig nicht genannt'],
  [26701, 'Steinbildhauerei und Steinmetzerei'],
  [26702, 'Sonstige Be- und Verarbeitung von Naturwerksteinen und Natursteinen, anderweitig nicht genannt'],
  [26800, 'Herstellung von sonstigen Erzeugnissen aus nicht metallischen Mineralien'],
  [26810, 'Herstellung von Mühl-, Mahl-, Schleif-, Wetz- und Poliersteinen sowie Schleifstoffen'],
  [26813, 'Herstellung von Schleifkörpern mit Diamant oder Bornitrid'],
  [26814, 'Herstellung von Schleifmitteln auf Unterlage'],
  [26815, 'Herstellung von Mühlsteinen und Steinen zum Zerfasern'],
  [26816, 'Herstellung von Schleifkörpern (ohne Diamantschleifkörper) und Poliersteinen'],
  [26820, 'Herstellung von sonstigen Erzeugnissen aus nicht metallischen Mineralien, anderweitig nicht genannt'],
  [27000, 'Metallerzeugung und -bearbeitung'],
  [27100, 'Erzeugung von Roheisen, Stahl und Ferrolegierungen'],
  [27200, 'Herstellung von Rohren'],
  [27210, 'Herstellung von Rohren, Rohrform-, Rohrverschluss- und Rohrverbindungsstücken aus Gusseisen'],
  [27211, 'Herstellung von Rohren aus Gusseisen'],
  [27212, 'Herstellung von Rohrform-, Rohrverschluss- und Rohrverbindungsstücken aus Gusseisen'],
  [27220, 'Herstellung von Stahlrohren, Rohrform-, Rohrverschluss- und Rohrverbindungsstücken aus Stahl'],
  [27221, 'Herstellung von Stahlrohren (ohne Präzisionsstahlrohre)'],
  [27222, 'Herstellung von Präzisionsstahlrohren'],
  [27223, 'Herstellung von Rohrform-, Rohrverschluss- und Rohrverbindungsstücken aus Stahl'],
  [27300, 'Sonstige erste Bearbeitung von Eisen und Stahl'],
  [27310, 'Herstellung von Blankstahl'],
  [27320, 'Herstellung von Kaltband mit einer Breite von weniger als 600 mm'],
  [27330, 'Herstellung von Kaltprofilen'],
  [27340, 'Herstellung von gezogenem Draht'],
  [27400, 'Erzeugung und erste Bearbeitung von NE-Metallen'],
  [27410, 'Erzeugung und erste Bearbeitung von Edelmetallen'],
  [27420, 'Erzeugung und erste Bearbeitung von Aluminium'],
  [27421, 'Erzeugung von Aluminium'],
  [27422, 'Erste Bearbeitung von Aluminium'],
  [27430, 'Erzeugung und erste Bearbeitung von Blei, Zink und Zinn'],
  [27431, 'Erzeugung von Blei, Zink und Zinn'],
  [27432, 'Erste Bearbeitung von Blei, Zink und Zinn'],
  [27440, 'Erzeugung und erste Bearbeitung von Kupfer'],
  [27441, 'Erzeugung von Kupfer'],
  [27442, 'Erste Bearbeitung von Kupfer'],
  [27450, 'Erzeugung und erste Bearbeitung von sonstigen NE-Metallen'],
  [27451, 'Erzeugung von sonstigen NE-Metallen'],
  [27452, 'Erste Bearbeitung von sonstigen NE-Metallen'],
  [27500, 'Gießereien'],
  [27510, 'Eisengießereien'],
  [27511, 'Eisengießereien (ohne Herstellung von duktilem Gusseisen)'],
  [27512, 'Herstellung von duktilem Gusseisen'],
  [27520, 'Stahlgießereien'],
  [27530, 'Leichtmetallgießereien'],
  [27540, 'Buntmetallgießereien'],
  [28000, 'Herstellung von Metallerzeugnissen'],
  [28100, 'Stahl- und Leichtmetallbau'],
  [28110, 'Herstellung von Metallkonstruktionen'],
  [28111, 'Herstellung von Metallkonstruktionen (ohne Grubenausbaukonstruktionen)'],
  [28112, 'Herstellung von Grubenausbaukonstruktionen'],
  [28120, 'Herstellung von Ausbauelementen aus Metall'],
  [
    28200,
    'Herstellung von Metallbehältern mit einem Fassungsvermögen von mehr als 300 l, Herstellung von Heizkörpern und -kesseln für Zentralheizungen',
  ],
  [28210, 'Herstellung von Metallbehältern mit einem Fassungsvermögen von mehr als 300 l'],
  [28220, 'Herstellung von Heizkörpern und -kesseln für Zentralheizungen'],
  [28300, 'Herstellung von Dampfkesseln (ohne Zentralheizungskessel)'],
  [
    28400,
    'Herstellung von Schmiede-, Press-, Zieh- und Stanzteilen, gewalzten Ringen und pulvermetallurgischen Erzeugnissen',
  ],
  [28401, 'Herstellung von schweren Freiformschmiedestücken'],
  [28402, 'Herstellung von leichten Freiformschmiedestücken'],
  [28403, 'Herstellung von Gesenkschmiedeteilen'],
  [28404, 'Herstellung von Kaltfließpressteilen'],
  [28407, 'Herstellung von pulvermetallurgischen Erzeugnissen'],
  [28408, 'Herstellung von Press-, Zieh- und Stanzteilen'],
  [28500, 'Oberflächenveredlung und Wärmebehandlung, Mechanik, anderweitig nicht genannt'],
  [28510, 'Oberflächenveredlung und Wärmebehandlung'],
  [28520, 'Mechanik, anderweitig nicht genannt'],
  [28521, 'Schlosserei und Schweißerei'],
  [28522, 'Schleiferei und Dreherei'],
  [28523, 'Beschlag- und Kunstschmieden'],
  [28600, 'Herstellung von Schneidwaren, Werkzeugen, Schlössern und Beschlägen aus unedlen Metallen'],
  [28610, 'Herstellung von Schneidwaren und Bestecken aus unedlen Metallen'],
  [28620, 'Herstellung von Werkzeugen'],
  [28621, 'Herstellung von Handwerkzeugen'],
  [28622, 'Herstellung von Sägen und von Maschinenwerkzeugen für die Holzbearbeitung'],
  [28623, 'Herstellung von Werkzeugen für das Baugewerbe'],
  [28624, 'Herstellung von auswechselbaren Werkzeugen für die Metallbearbeitung'],
  [28625, 'Herstellung von Geräten für die Landwirtschaft'],
  [28626, 'Herstellung von sonstigen Werkzeugen'],
  [28630, 'Herstellung von Schlössern und Beschlägen aus unedlen Metallen'],
  [28700, 'Herstellung von sonstigen Metallwaren'],
  [28710, 'Herstellung von Metallbehältern mit einem Fassungsvermögen von 300 l oder weniger'],
  [28720, 'Herstellung von Verpackungen und Verschlüssen aus Eisen, Stahl und NE-Metall'],
  [28730, 'Herstellung von Drahtwaren'],
  [28740, 'Herstellung von Schrauben, Nieten, Ketten und Federn'],
  [28741, 'Herstellung von Schrauben, Muttern, Bolzen und Nieten'],
  [28742, 'Herstellung von Ketten'],
  [28743, 'Herstellung von Federn'],
  [28750, 'Herstellung von sonstigen Metallwaren, anderweitig nicht genannt'],
  [28751, 'Herstellung von nicht elektrischen Haushaltsartikeln aus Metall'],
  [28752, 'Herstellung von Panzerschränken und Tresoranlagen'],
  [28753, 'Herstellung von Metallwaren, anderweitig nicht genannt'],
  [29000, 'Maschinenbau'],
  [
    29100,
    'Herstellung von Maschinen für die Erzeugung und Nutzung von mechanischer Energie (ohne Motoren für Luft- und Straßenfahrzeuge)',
  ],
  [29110, 'Herstellung von Verbrennungsmotoren und Turbinen (ohne Motoren für Luft- und Straßenfahrzeuge)'],
  [29120, 'Herstellung von Pumpen und Kompressoren'],
  [29130, 'Herstellung von Armaturen'],
  [29140, 'Herstellung von Lagern, Getrieben, Zahnrädern und Antriebselementen'],
  [29200, 'Herstellung von sonstigen nicht wirtschaftszweigspezifischen Maschinen'],
  [29210, 'Herstellung von Öfen und Brennern'],
  [29220, 'Herstellung von Hebezeugen und Fördermitteln'],
  [29230, 'Herstellung von kälte- und lufttechnischen Erzeugnissen, nicht für den Haushalt'],
  [29240, 'Herstellung von sonstigen nicht wirtschaftszweigspezifischen Maschinen, anderweitig nicht genannt'],
  [29300, 'Herstellung von land- und forstwirtschaftlichen Maschinen'],
  [29310, 'Herstellung von land- und forstwirtschaftlichen Zugmaschinen'],
  [29311, 'Herstellung von land- und forstwirtschaftlichen Zugmaschinen (ohne Reparatur)'],
  [29312, 'Instandhaltung und Reparatur von land- und forstwirtschaftlichen Zugmaschinen'],
  [29320, 'Herstellung von sonstigen land- und forstwirtschaftlichen Maschinen'],
  [29321, 'Herstellung von sonstigen land- und forstwirtschaftlichen Maschinen (ohne Reparatur)'],
  [29322, 'Instandhaltung und Reparatur von sonstigen land- und forstwirtschaftlichen Maschinen'],
  [29400, 'Herstellung von Werkzeugmaschinen'],
  [29410, 'Herstellung von handgeführten kraftbetriebenen Werkzeugen'],
  [29420, 'Herstellung von Werkzeugmaschinen für die Metallbearbeitung'],
  [29430, 'Herstellung von Werkzeugmaschinen, anderweitig nicht genannt'],
  [29431, 'Herstellung von Werkzeugmaschinen zur Bearbeitung von Steinen, Beton und sonstigen mineralischen Stoffen'],
  [29432, 'Herstellung von Werkzeugmaschinen zur Bearbeitung von sonstigen harten Stoffen'],
  [29433, 'Herstellung von Maschinenspannzeugen und sonstigem Zubehör von Werkzeugmaschinen'],
  [29434, 'Herstellung von Elektroschweiß- und -lötgeräten'],
  [29435, 'Herstellung von sonstigen Werkzeugmaschinen, anderweitig nicht genannt'],
  [29500, 'Herstellung von Maschinen für sonstige bestimmte Wirtschaftszweige'],
  [29510, 'Herstellung von Maschinen für die Metallerzeugung, von Walzwerkseinrichtungen und Gießmaschinen'],
  [29520, 'Herstellung von Bergwerks-, Bau- und Baustoffmaschinen'],
  [29521, 'Herstellung von Bergwerksmaschinen'],
  [29522, 'Herstellung von Bau- und Baustoffmaschinen'],
  [29530, 'Herstellung von Maschinen für das Ernährungsgewerbe und die Tabakverarbeitung'],
  [29540, 'Herstellung von Maschinen für das Textil-, Bekleidungs- und Ledergewerbe'],
  [29550, 'Herstellung von Maschinen für das Papiergewerbe'],
  [29560, 'Herstellung von Maschinen für bestimmte Wirtschaftszweige, anderweitig nicht genannt'],
  [29561, 'Herstellung von Maschinen für das Druckgewerbe'],
  [29563, 'Herstellung von Maschinen für die Kunststoff- und Gummibe- und -verarbeitung'],
  [29564, 'Herstellung von Maschinen für sonstige bestimmte Wirtschaftszweige, anderweitig nicht genannt'],
  [29600, 'Herstellung von Waffen und Munition'],
  [29700, 'Herstellung von Haushaltsgeräten, anderweitig nicht genannt'],
  [29710, 'Herstellung von elektrischen Haushaltsgeräten'],
  [
    29720,
    'Herstellung von nicht elektrischen Heiz-, Koch-, Heißwasser- und Heißluftgeräten, anderweitig nicht genannt',
  ],
  [29800, 'Reparatur von sonstigen Ausrüstungen'],
  [29810, 'Installation von Maschinen und Ausrüstungen a.n.g.'],
  [29830, 'Reparatur und Installation von Maschinen'],
  [29840, 'Reparatur und Installation von elektrischen und optischen Geräten'],
  [30000, 'Herstellung von Büromaschinen, Datenverarbeitungsgeräten und -einrichtungen'],
  [30010, 'Herstellung von Büromaschinen'],
  [30020, 'Herstellung von Datenverarbeitungsgeräten und -einrichtungen'],
  [30030, 'Reparatur von Datenverarbeitungs- und Telekommunikationsgeräten'],
  [31000, 'Herstellung von Geräten der Elektrizitätserzeugung, -verteilung u.ä.'],
  [31100, 'Herstellung von Elektromotoren, Generatoren und Transformatoren'],
  [31200, 'Herstellung von Elektrizitätsverteilungs- und -schalteinrichtungen'],
  [31300, 'Herstellung von isolierten Elektrokabeln, -leitungen und -drähten'],
  [31400, 'Herstellung von Akkumulatoren und Batterien'],
  [31500, 'Herstellung von elektrischen Lampen und Leuchten'],
  [31600, 'Herstellung von elektrischen Ausrüstungen, anderweitig nicht genannt'],
  [31610, 'Herstellung von elektrischen Ausrüstungen für Motoren und Fahrzeuge, anderweitig nicht genannt'],
  [31620, 'Herstellung von sonstigen elektrischen Ausrüstungen, anderweitig nicht genannt'],
  [31630, 'Reparatur von elektrischen Gartengeräten'],
  [32000, 'Rundfunk- und Nachrichtentechnik'],
  [32100, 'Herstellung von elektronischen Bauelementen'],
  [32200, 'Herstellung von Geräten und Einrichtungen der Telekommunikationstechnik'],
  [32300, 'Herstellung von Rundfunkgeräten sowie phono- und videotechnischen Geräten'],
  [33000, 'Medizin-, Mess-, Steuer- und Regelungstechnik, Optik, Herstellung von Uhren'],
  [33100, 'Herstellung von medizinischen Geräten und orthopädischen Erzeugnissen'],
  [33101, 'Herstellung von elektromedizinischen Geräten und Instrumenten'],
  [33102, 'Herstellung von medizintechnischen Geräten'],
  [33103, 'Herstellung von orthopädischen Erzeugnissen'],
  [33104, 'Zahntechnische Laboratorien'],
  [33200, 'Herstellung von Mess-, Kontroll-, Navigations- u.ä. Instrumenten und Vorrichtungen'],
  [33201, 'Herstellung von elektrischen Mess-, Kontroll-, Navigations- u.ä. Instrumenten und Vorrichtungen'],
  [
    33202,
    'Herstellung von feinmechanisch-optischen Mess-, Kontroll-, Navigations- u.ä. Instrumenten und Vorrichtungen',
  ],
  [33203, 'Herstellung von mechanischen Prüfmaschinen'],
  [33300, 'Herstellung von industriellen Prozesssteuerungseinrichtungen'],
  [33400, 'Herstellung von optischen und fotografischen Geräten'],
  [33401, 'Herstellung von augenoptischen Erzeugnissen'],
  [33402, 'Herstellung von optischen Instrumenten'],
  [33403, 'Herstellung von Foto-, Projektions- und Kinogeräten'],
  [33500, 'Herstellung von Uhren'],
  [33600, 'Reparatur von Haushaltswaren und Ziergegenständen aus Glas, Ton, Steinzeug und Porzellan'],
  [33610, 'Reparatur von nichtelektrischen Haushaltsgeräten, Schneidwaren und Bestecken aus unedlen Metallen'],
  [33620, 'Reparatur von Möbeln und Einrichtungsgegenständen'],
  [33630, 'Reparatur von sonstigen Gebrauchsgütern'],
  [34000, 'Herstellung von Kraftwagen und Kraftwagenteilen'],
  [34100, 'Herstellung von Kraftwagen und Kraftwagenmotoren'],
  [34101, 'Herstellung von Personenkraftwagen und Personenkraftwagenmotoren'],
  [34102, 'Herstellung von Nutzkraftwagen und Nutzkraftwagenmotoren'],
  [34200, 'Herstellung von Karosserien, Aufbauten und Anhängern'],
  [34300, 'Herstellung von Teilen und Zubehör für Kraftwagen und Kraftwagenmotoren'],
  [35000, 'Sonstiger Fahrzeugbau'],
  [35100, 'Schiff- und Bootsbau'],
  [35110, 'Schiffbau (ohne Boots- und Yachtbau)'],
  [35120, 'Boots- und Yachtbau'],
  [35200, 'Bahnindustrie'],
  [35206, 'Schienenfahrzeugbau'],
  [35207, 'Herstellung von Eisenbahninfrastruktur'],
  [35300, 'Luft- und Raumfahrzeugbau'],
  [35310, 'Herstellung von Interkontinentalraketen'],
  [35320, 'Herstellung von militärischen Kampffahrzeugen'],
  [35400, 'Herstellung von Krafträdern, Fahrrädern und Behindertenfahrzeugen'],
  [35410, 'Herstellung von Krafträdern'],
  [35411, 'Herstellung von Krafträdern (ohne Kraftradteile)'],
  [35412, 'Herstellung von Kraftradteilen und -zubehör'],
  [35420, 'Herstellung von Fahrrädern'],
  [35421, 'Herstellung von Fahrrädern (ohne Fahrradteile)'],
  [35422, 'Herstellung von Fahrradteilen und -zubehör'],
  [35430, 'Herstellung von Behindertenfahrzeugen'],
  [35500, 'Fahrzeugbau, anderweitig nicht genannt'],
  [36000, 'Herstellung von Möbeln, Schmuck, Musikinstrumenten, Sportgeräten, Spielwaren und sonstigen Erzeugnissen'],
  [36100, 'Herstellung von Möbeln'],
  [36110, 'Herstellung von Sitzmöbeln'],
  [36111, 'Herstellung von Polstermöbeln'],
  [36112, 'Herstellung von sonstigen Sitzmöbeln'],
  [36120, 'Herstellung von Büro- und Ladenmöbeln'],
  [36121, 'Herstellung von Büromöbeln'],
  [36122, 'Herstellung von Ladenmöbeln und sonstigen Objektmöbeln'],
  [36130, 'Herstellung von Küchenmöbeln'],
  [36140, 'Herstellung von sonstigen Möbeln'],
  [36141, 'Herstellung von Esszimmer- und Wohnzimmermöbeln'],
  [36142, 'Herstellung von Schlafzimmermöbeln'],
  [36143, 'Herstellung von Möbeln, anderweitig nicht genannt'],
  [36150, 'Herstellung von Matratzen'],
  [36200, 'Herstellung von Schmuck u.ä. Erzeugnissen'],
  [36210, 'Herstellung von Münzen'],
  [36220, 'Herstellung von Schmuck, Gold- und Silberschmiedewaren (ohne Phantasieschmuck)'],
  [36221, 'Bearbeitung von Edelsteinen, Schmucksteinen und Perlen'],
  [36222, 'Herstellung von Schmuck aus Edelmetallen und Edelmetallplattierungen'],
  [36223, 'Herstellung von Gold- und Silberschmiedewaren (ohne Tafelgeräte und Bestecke)'],
  [36224, 'Herstellung von Tafelgeräten und Bestecken aus Edelmetallen oder mit Edelmetallen überzogen'],
  [36225, 'Herstellung von Edelmetallerzeugnissen für technische Zwecke'],
  [36300, 'Herstellung von Musikinstrumenten'],
  [36400, 'Herstellung von Sportgeräten'],
  [36500, 'Herstellung von Spielwaren'],
  [36600, 'Herstellung von sonstigen Erzeugnissen'],
  [36610, 'Herstellung von Phantasieschmuck'],
  [36620, 'Herstellung von Besen und Bürsten'],
  [36630, 'Herstellung von Erzeugnissen, anderweitig nicht genannt'],
  [36631, 'Herstellung von Bodenbelägen auf textiler Unterlage'],
  [36632, 'Herstellung von sonstigem Bekleidungszubehör, anderweitig nicht genannt'],
  [36633, 'Herstellung von chemischen Erzeugnissen, anderweitig nicht genannt'],
  [36634, 'Herstellung von Kinderwagen'],
  [36635, 'Herstellung von Füllhaltern, Kugelschreibern, Filzstiften und Stempeln'],
  [36636, 'Verarbeitung von natürlichen Schnitz- und Formstoffen, Tierausstopferei'],
  [36637, 'Herstellung von Weihnachtsschmuck'],
  [36638, 'Herstellung von sonstigen Erzeugnissen, anderweitig nicht genannt'],
  [37000, 'Recycling'],
  [37100, 'Recycling von metallischen Altmaterialien und Reststoffen'],
  [37101, 'Recycling von Altmaterialien und Reststoffen aus Eisen oder Stahl'],
  [37102, 'Recycling von Altmaterialien und Reststoffen aus NE-Metallen'],
  [37200, 'Recycling von nicht metallischen Altmaterialien und Reststoffen'],
  [37201, 'Recycling von textilen Altmaterialien und Reststoffen'],
  [37202, 'Recycling von Altmaterialien und Reststoffen aus Papier, Karton und Pappe'],
  [37203, 'Recycling von Altmaterialien und Reststoffen aus Glas'],
  [37204, 'Recycling von Altmaterialien und Reststoffen aus Kunststoffen'],
  [37205, 'Recycling von sonstigen Altmaterialien und Reststoffen'],
  [40000, 'Energieversorgung'],
  [40100, 'Elektrizitätsversorgung'],
  [40110, 'Elektrizitätserzeugung'],
  [40111, 'Elektrizitätserzeugung ohne Verteilung'],
  [40112, 'Elektrizitätserzeugung aus Wärmekraft mit Fremdbezug zur Verteilung'],
  [
    40113,
    'Elektrizitätserzeugung aus erneuerbaren Energieträgern und sonstigen Energiequellen mit Fremdbezug zur Verteilung',
  ],
  [40114, 'Elektrizitätserzeugung aus Wärmekraft ohne Fremdbezug zur Verteilung'],
  [
    40115,
    'Elektrizitätserzeugung aus erneuerbaren Energieträgern und sonstigen Energiequellen ohne Fremdbezug zur Verteilung',
  ],
  [40120, 'Elektrizitätsübertragung'],
  [40130, 'Elektrizitätsverteilung und -handel'],
  [40200, 'Gasversorgung'],
  [40210, 'Gaserzeugung'],
  [40211, 'Gaserzeugung ohne Verteilung'],
  [40212, 'Gaserzeugung mit Fremdbezug zur Verteilung'],
  [40213, 'Gaserzeugung ohne Fremdbezug zur Verteilung'],
  [40220, 'Gasverteilung und -handel durch Rohrleitungen'],
  [40300, 'Wärmeversorgung'],
  [40301, 'Wärmeerzeugung durch Heizkraftwerk mit Fremdbezug zur Verteilung'],
  [40302, 'Wärmeerzeugung durch Fernheizwerk mit Fremdbezug zur Verteilung'],
  [40303, 'Wärmeerzeugung durch Heizkraftwerk ohne Fremdbezug zur Verteilung'],
  [40304, 'Wärmeerzeugung durch Fernheizwerk ohne Fremdbezug zur Verteilung'],
  [40305, 'Wärmeverteilung ohne Erzeugung'],
  [41000, 'Wasserversorgung'],
  [41001, 'Wassergewinnung mit Fremdbezug zur Verteilung'],
  [41002, 'Wassergewinnung ohne Fremdbezug zur Verteilung'],
  [41003, 'Wasserverteilung ohne Gewinnung'],
  [45000, 'Baugewerbe'],
  [45100, 'Vorbereitende Baustellenarbeiten'],
  [45110, 'Abbruch-, Spreng- und Enttrümmerungsgewerbe, Erdbewegungsarbeiten'],
  [45111, 'Abbruch-, Spreng- und Enttrümmerungsgewerbe'],
  [45112, 'Erdbewegungsarbeiten'],
  [45114, 'Aufschließung von Lagerstätten, Auffüllen stillgelegter Lagerstätten'],
  [45120, 'Test- und Suchbohrung'],
  [45200, 'Hoch- und Tiefbau'],
  [45210, 'Hochbau, Brücken- und Tunnelbau u.ä.'],
  [45211, 'Hoch- und Tiefbau, ohne ausgeprägten Schwerpunkt'],
  [45212, 'Hochbau (ohne Fertigteilbau)'],
  [45213, 'Errichtung von Fertigteilbauten aus Beton im Hochbau aus selbst hergestellten Fertigteilen'],
  [45214, 'Errichtung von Fertigteilbauten aus Beton im Hochbau aus fremd bezogenen Fertigteilen'],
  [45215, 'Errichtung von Fertigteilbauten aus Holz und Kunststoffen im Hochbau aus fremd bezogenen Fertigteilen'],
  [45216, 'Brücken- und Tunnelbau u.ä.'],
  [45217, 'Rohrleitungs- und Kabelleitungstiefbau'],
  [45220, 'Dachdeckerei, Bauspenglerei, Abdichtungen und Zimmerei'],
  [45221, 'Dachdeckerei und Bauspenglerei'],
  [45222, 'Abdichtung gegen Wasser und Feuchtigkeit'],
  [45223, 'Zimmerei und Ingenieurholzbau'],
  [45230, 'Bau von Straßen, Bahnverkehrsstrecken, Rollbahnen und Sportanlagen'],
  [45231, 'Bau von Straßen, Rollbahnen und Sportanlagen'],
  [45232, 'Bau von Bahnverkehrsstrecken'],
  [45240, 'Wasserbau'],
  [45250, 'Sonstiger spezialisierter Hoch- und Tiefbau'],
  [45251, 'Brunnenbau'],
  [45252, 'Schachtbau'],
  [45253, 'Schornstein-, Feuerungs- und Industrieofenbau'],
  [45254, 'Gerüstbau'],
  [45255, 'Gebäudetrocknung'],
  [45256, 'Sonstiger spezialisierter Hoch- und Tiefbau, anderweitig nicht genannt'],
  [45300, 'Bauinstallation'],
  [45310, 'Elektroinstallation'],
  [45320, 'Dämmung gegen Kälte, Wärme, Schall und Erschütterung'],
  [45330, 'Klempnerei, Gas-, Wasser-, Heizungs- und Lüftungsinstallation'],
  [45340, 'Sonstige Bauinstallation'],
  [45400, 'Sonstiges Ausbaugewerbe'],
  [45410, 'Stuckateurgewerbe, Gipserei und Verputzerei'],
  [45420, 'Bautischlerei und -schlosserei'],
  [45430, 'Fußboden-, Fliesen- und Plattenlegerei, Raumausstattung'],
  [45431, 'Parkettlegerei'],
  [45432, 'Fliesen-, Platten- und Mosaiklegerei'],
  [45433, 'Estrichlegerei'],
  [45434, 'Sonstige Fußbodenlegerei und -kleberei'],
  [45435, 'Tapetenkleberei'],
  [45436, 'Raumausstattung, ohne ausgeprägten Schwerpunkt'],
  [45440, 'Maler- und Glasergewerbe'],
  [45441, 'Maler- und Lackierergewerbe'],
  [45442, 'Glasergewerbe'],
  [45450, 'Baugewerbe, anderweitig nicht genannt'],
  [45451, 'Fassadenreinigung'],
  [45453, 'Ausbaugewerbe, anderweitig nicht genannt'],
  [45500, 'Vermietung von Baumaschinen und -geräten mit Bedienungspersonal'],
  [45501, 'Vermietung von Betonpumpen mit Bedienungspersonal'],
  [45502, 'Vermietung von sonstigen Baumaschinen und -geräten mit Bedienungspersonal'],
  [50000, 'Kraftfahrzeughandel, Instandhaltung und Reparatur von Kraftfahrzeugen, Tankstellen'],
  [50100, 'Handel mit Kraftwagen'],
  [50101, 'Handelsvermittlung von Kraftwagen'],
  [50102, 'Großhandel mit Kraftwagen'],
  [50103, 'Einzelhandel mit Kraftwagen'],
  [50200, 'Instandhaltung und Reparatur von Kraftwagen'],
  [50203, 'Lackierung von Kraftwagen'],
  [50204, 'Autowaschanlagen'],
  [50205, 'Instandhaltung und Reparatur von Kraftwagen (ohne Lackierung und Autowäsche)'],
  [50300, 'Handel mit Kraftwagenteilen und -zubehör'],
  [50301, 'Handelsvermittlung von Kraftwagenteilen und -zubehör'],
  [50302, 'Großhandel mit Kraftwagenteilen und -zubehör'],
  [50303, 'Einzelhandel mit Kraftwagenteilen und -zubehör'],
  [50400, 'Handel mit Krafträdern, Kraftradteilen und -zubehör, Instandhaltung und Reparatur von Krafträdern'],
  [50401, 'Handelsvermittlung von Krafträdern, Kraftradteilen und -zubehör'],
  [50402, 'Großhandel mit Krafträdern, Kraftradteilen und -zubehör'],
  [50403, 'Einzelhandel mit Krafträdern, Kraftradteilen und -zubehör'],
  [50404, 'Instandhaltung und Reparatur von Krafträdern'],
  [50500, 'Tankstellen'],
  [50501, 'Tankstellen mit Absatz in fremdem Namen (Agenturtankstellen)'],
  [50502, 'Tankstellen mit Absatz in eigenem Namen (Freie Tankstellen)'],
  [50900, 'Zuständige Warenzentrale'],
  [51000, 'Handelsvermittlung und Großhandel (ohne Handel mit Kraftfahrzeugen)'],
  [51100, 'Handelsvermittlung'],
  [
    51110,
    'Handelsvermittlung von landwirtschaftlichen Grundstoffen, lebenden Tieren, textilen Rohstoffen und Halbwaren',
  ],
  [51112, 'Handelsvermittlung von Blumen und Pflanzen'],
  [51114, 'Handelsvermittlung von lebenden Tieren'],
  [51115, 'Handelsvermittlung von textilen Rohstoffen und Halbwaren, Häuten, Fellen und Leder'],
  [51116, 'Handelsvermittlung von Getreide, Saaten, Futtermitteln und Rohtabak'],
  [51120, 'Handelsvermittlung von Brennstoffen, Erzen, Metallen und technischen Chemikalien'],
  [51121, 'Handelsvermittlung von festen Brennstoffen und Mineralölerzeugnissen'],
  [51123, 'Handelsvermittlung von technischen Chemikalien, Rohdrogen, Kautschuk, Kunststoffen und Düngemitteln'],
  [51124, 'Handelsvermittlung von Erzen, Eisen, Stahl und NE-Metallen'],
  [51125, 'Handelsvermittlung von Eisen-, Stahl- und NE-Metallhalbzeug'],
  [51130, 'Handelsvermittlung von Holz, Baustoffen und Anstrichmitteln'],
  [51131, 'Handelsvermittlung von Rohholz, Holzhalbwaren und Bauelementen aus Holz'],
  [51132, 'Handelsvermittlung von Baustoffen, Bauelementen aus Stahl und mineralischen Stoffen und von Flachglas'],
  [51133, 'Handelsvermittlung von Anstrichmitteln'],
  [51134, 'Handelsvermittlung von chemisch-technischen Erzeugnissen'],
  [51140, 'Handelsvermittlung von Maschinen, technischem Bedarf, Wasser- und Luftfahrzeugen'],
  [
    51141,
    'Handelsvermittlung von Maschinen und technischem Bedarf (ohne landwirtschaftliche Maschinen und Büromaschinen)',
  ],
  [51142, 'Handelsvermittlung von Wasser- und Luftfahrzeugen'],
  [51144, 'Handelsvermittlung von Geräten der Unterhaltungselektronik und Zubehör'],
  [51145, 'Handelsvermittlung von Werkzeugen'],
  [51146, 'Handelsvermittlung von Büromaschinen und Software'],
  [51147, 'Handelsvermittlung von landwirtschaftlichen Maschinen und Geräten'],
  [51148, 'Handelsvermittlung von Installationsbedarf für Gas, Wasser, Heizung und Klimatechnik'],
  [51149, 'Handelsvermittlung von elektrotechnischen und elektronischen Erzeugnissen, anderweitig nicht genannt'],
  [51150, 'Handelsvermittlung von Möbeln, Einrichtungs- und Haushaltsgegenständen, Eisen-und Metallwaren'],
  [51151, 'Handelsvermittlung von Möbeln, Einrichtungsgegenständen und Antiquitäten'],
  [
    51152,
    'Handelsvermittlung von keramischen Erzeugnissen, Glaswaren, Holzwaren, anderweitig nicht genannt, sowie Flecht- und Korbwaren',
  ],
  [51153, 'Handelsvermittlung von elektrischen Haushaltsgeräten'],
  [51154, 'Handelsvermittlung von Eisen-, Metall- und Kunststoffwaren, anderweitig nicht genannt'],
  [51155, 'Handelsvermittlung von Putz- und Reinigungsmitteln'],
  [51160, 'Handelsvermittlung von Textilien, Bekleidung, Schuhen und Lederwaren'],
  [51161, 'Handelsvermittlung von Meterware für Bekleidung und Wäsche'],
  [51162, 'Handelsvermittlung von Heim- und Haushaltstextilien und Bodenbelägen'],
  [51167, 'Handelsvermittlung von Bekleidung'],
  [51168, 'Handelsvermittlung von Bekleidungszubehör'],
  [51169, 'Handelsvermittlung von Schuhen, Leder- und Täschnerwaren'],
  [51170, 'Handelsvermittlung von Nahrungsmitteln, Getränken und Tabakwaren'],
  [51171, 'Handelsvermittlung von Nahrungsmitteln, Getränken und Tabakwaren, ohne ausgeprägten Schwerpunkt'],
  [51172, 'Handelsvermittlung von Obst, Gemüse und Kartoffeln'],
  [51173, 'Handelsvermittlung von Zucker und Süßwaren'],
  [
    51174,
    'Handelsvermittlung von Milch, Milcherzeugnissen, Eiern, Speiseölen, Nahrungsfetten, Fleisch, Fleischwaren, Geflügel und Wild',
  ],
  [51175, 'Handelsvermittlung von Kaffee, Tee, Kakao und Gewürzen'],
  [51176, 'Handelsvermittlung von Wein, Sekt und Spirituosen'],
  [51177, 'Handelsvermittlung von sonstigen Getränken'],
  [51178, 'Handelsvermittlung von tiefgefrorenen Nahrungsmitteln'],
  [51179, 'Handelsvermittlung von sonstigen Nahrungsmitteln sowie Tabakwaren'],
  [51180, 'Handelsvermittlung von Waren, anderweitig nicht genannt'],
  [51181, 'Handelsvermittlung von feinmechanischen, Foto- und optischen Erzeugnissen'],
  [51182, 'Handelsvermittlung von Uhren, Edelmetallwaren und Schmuck'],
  [51183, 'Handelsvermittlung von Spielwaren und Musikinstrumenten'],
  [
    51184,
    'Handelsvermittlung von Fahrrädern, Fahrradteilen und -zubehör, Sport- und Campingartikeln (ohne Campingmöbel)',
  ],
  [
    51185,
    'Handelsvermittlung von pharmazeutischen Erzeugnissen, medizinischen und orthopädischen Artikeln und Laborbedarf, Ärztebedarf, Dentalbedarf, zahnärztlichen Instrumenten, Krankenhaus- und Altenpflegebedarf',
  ],
  [51186, 'Handelsvermittlung von kosmetischen Erzeugnissen und Körperpflegemitteln'],
  [
    51187,
    'Handelsvermittlung von Karton, Papier und Pappe, Schreibwaren, Bürobedarf, Geschenk- und Werbeartikeln, Verpackungsmitteln und Tapeten',
  ],
  [51188, 'Handelsvermittlung von Büchern, Zeitschriften, Zeitungen, Musikalien und sonstigen Druckerzeugnissen'],
  [51189, 'Handelsvermittlung von Altmaterialien und Reststoffen'],
  [51190, 'Handelsvermittlung von Waren, ohne ausgeprägten Schwerpunkt'],
  [51200, 'Großhandel mit landwirtschaftlichen Grundstoffen und lebenden Tieren'],
  [51210, 'Großhandel mit Getreide, Saatgut und Futtermitteln'],
  [51220, 'Großhandel mit Blumen und Pflanzen'],
  [51230, 'Großhandel mit lebenden Tieren'],
  [51240, 'Großhandel mit Häuten, Fellen und Leder'],
  [51250, 'Großhandel mit Rohtabak'],
  [51300, 'Großhandel mit Nahrungsmitteln, Getränken und Tabakwaren'],
  [51310, 'Großhandel mit Obst, Gemüse und Kartoffeln'],
  [51320, 'Großhandel mit Fleisch, Fleischwaren, Geflügel und Wild'],
  [51330, 'Großhandel mit Milch, Milcherzeugnissen, Eiern, Speiseölen und Nahrungsfetten'],
  [51340, 'Großhandel mit Getränken'],
  [51341, 'Großhandel mit Getränken, ohne ausgeprägten Schwerpunkt'],
  [51342, 'Großhandel mit Wein, Sekt und Spirituosen'],
  [51345, 'Großhandel mit sonstigen Getränken'],
  [51350, 'Großhandel mit Tabakwaren'],
  [51360, 'Großhandel mit Zucker, Süßwaren und Backwaren'],
  [51361, 'Großhandel mit Zucker'],
  [51362, 'Großhandel mit Süßwaren'],
  [51363, 'Großhandel mit Backwaren'],
  [51370, 'Großhandel mit Kaffee, Tee, Kakao und Gewürzen'],
  [51371, 'Großhandel mit Kaffee, Tee und Kakao'],
  [51372, 'Großhandel mit Gewürzen'],
  [51380, 'Großhandel mit sonstigen Nahrungsmitteln'],
  [51381, 'Großhandel mit Fisch und Fischerzeugnissen'],
  [51382, 'Großhandel mit Mehl und Getreideprodukten'],
  [51383, 'Großhandel mit Nahrungsmitteln, anderweitig nicht genannt'],
  [51390, 'Großhandel mit Nahrungsmitteln, Getränken und Tabakwaren, ohne ausgeprägten Schwerpunkt'],
  [51391, 'Großhandel mit tiefgefrorenen Nahrungsmitteln, ohne ausgeprägten Schwerpunkt'],
  [51392, 'Großhandel mit sonstigen Nahrungsmitteln, Getränken und Tabakwaren, ohne ausgeprägten Schwerpunkt'],
  [51400, 'Großhandel mit Gebrauchs- und Verbrauchsgütern'],
  [51410, 'Großhandel mit Textilien'],
  [51411, 'Großhandel mit Meterware für Bekleidung und Wäsche'],
  [51412, 'Großhandel mit Heim- und Haushaltstextilien'],
  [51420, 'Großhandel mit Bekleidung und Schuhen'],
  [51423, 'Großhandel mit Schuhen'],
  [51424, 'Großhandel mit Oberbekleidung sowie Bekleidungszubehör'],
  [51425, 'Großhandel mit Unterbekleidung, Pullovern u.ä.'],
  [51430, 'Großhandel mit elektrischen Haushaltsgeräten und Geräten der Unterhaltungselektronik'],
  [
    51431,
    'Großhandel mit elektrischen Haushaltsgeräten und Geräten der Unterhaltungselektronik, ohne ausgeprägten Schwerpunkt',
  ],
  [51432, 'Großhandel mit elektrischen Haushaltsgeräten'],
  [51433, 'Großhandel mit Geräten der Unterhaltungselektronik und Zubehör'],
  [51434, 'Großhandel mit elektrotechnischem Zubehör und Elektroinstallationszubehör'],
  [
    51440,
    'Großhandel mit Haushaltswaren aus Metall, keramischen Erzeugnissen, Glaswaren, Tapeten und Reinigungsmitteln',
  ],
  [51441, 'Großhandel mit Haushaltswaren aus Metall'],
  [51442, 'Großhandel mit keramischen Erzeugnissen und Glaswaren'],
  [51443, 'Großhandel mit Tapeten'],
  [51444, 'Großhandel mit Wasch-, Putz- und Reinigungsmitteln'],
  [51450, 'Großhandel mit kosmetischen Erzeugnissen und Körperpflegemitteln'],
  [51460, 'Großhandel mit pharmazeutischen, medizinischen und orthopädischen Erzeugnissen'],
  [51461, 'Großhandel mit pharmazeutischen Erzeugnissen'],
  [51462, 'Großhandel mit medizinischen und orthopädischen Artikeln und Laborbedarf'],
  [51463, 'Großhandel mit Dentalbedarf'],
  [51470, 'Großhandel mit sonstigen Gebrauchs- und Verbrauchsgütern'],
  [51471, 'Großhandel mit nicht elektrischen Haushaltsgeräten'],
  [51472, 'Großhandel mit Spielwaren und Musikinstrumenten'],
  [51473, 'Großhandel mit Fahrrädern, Fahrradteilen und -zubehör, Sport- und Campingartikeln (ohne Campingmöbel)'],
  [51474, 'Großhandel mit Uhren, Edelmetallwaren und Schmuck'],
  [51475, 'Großhandel mit Leder- und Täschnerwaren, Geschenk- und Werbeartikeln'],
  [51476, 'Großhandel mit Möbeln, Einrichtungsgegenständen, Antiquitäten und Bodenbelägen'],
  [51477, 'Großhandel mit feinmechanischen, Foto- und optischen Erzeugnissen'],
  [51478, 'Großhandel mit Karton, Papier, Pappe, Schreibwaren, Bürobedarf, Büchern, Zeitschriften und Zeitungen'],
  [51500, 'Großhandel mit nicht landwirtschaftlichen Halbwaren, Altmaterialien und Reststoffen'],
  [51510, 'Großhandel mit festen Brennstoffen und Mineralölerzeugnissen'],
  [51512, 'Großhandel mit festen Brennstoffen'],
  [51513, 'Großhandel mit Mineralölerzeugnissen'],
  [51520, 'Großhandel mit Erzen, Metallen und Metallhalbzeug'],
  [51521, 'Großhandel mit Erzen'],
  [51522, 'Großhandel mit Eisen, Stahl, Eisen- und Stahlhalbzeug'],
  [51523, 'Großhandel mit NE-Metallen und NE-Metallhalbzeug'],
  [51530, 'Großhandel mit Holz, Baustoffen, Anstrichmitteln und Sanitärkeramik'],
  [51531, 'Großhandel mit Holz, Baustoffen, Anstrichmitteln und Sanitärkeramik, ohne ausgeprägten Schwerpunkt'],
  [51532, 'Großhandel mit Roh- und Schnittholz'],
  [51533, 'Großhandel mit sonstigen Holzhalbwaren sowie Bauelementen aus Holz'],
  [51534, 'Großhandel mit Baustoffen und Bauelementen aus mineralischen Stoffen'],
  [51535, 'Großhandel mit Flachglas'],
  [51536, 'Großhandel mit Anstrichmitteln'],
  [51537, 'Großhandel mit Sanitärkeramik'],
  [
    51540,
    'Großhandel mit Metall- und Kunststoffwaren für Bauzwecke sowie Installationsbedarf für Gas, Wasser und Heizung',
  ],
  [51542, 'Großhandel mit Werkzeugen und Kleineisenwaren'],
  [51543, 'Großhandel mit Installationsbedarf für Gas, Wasser und Heizung'],
  [51544, 'Großhandel mit Metall- und Kunststoffwaren für Bauzwecke'],
  [51550, 'Großhandel mit chemischen Erzeugnissen'],
  [51551, 'Großhandel mit chemischen Erzeugnissen, ohne ausgeprägten Schwerpunkt'],
  [51552, 'Großhandel mit technischen Chemikalien und Rohdrogen'],
  [51553, 'Großhandel mit rohen technischen Fetten und Ölen sowie Kautschuk'],
  [51554, 'Großhandel mit chemisch-technischen Erzeugnissen'],
  [51555, 'Großhandel mit Düngemitteln'],
  [51560, 'Großhandel mit sonstigen Halbwaren'],
  [51570, 'Großhandel mit Altmaterialien und Reststoffen'],
  [51571, 'Großhandel mit Altmaterialien und Reststoffen, ohne ausgeprägten Schwerpunkt'],
  [51572, 'Großhandel mit metallischen Altmaterialien und Reststoffen'],
  [51573, 'Großhandel mit sonstigen Altmaterialien und Reststoffen'],
  [51800, 'Großhandel mit Maschinen, Ausrüstungen und Zubehör'],
  [51810, 'Großhandel mit Werkzeugmaschinen'],
  [51811, 'Großhandel mit Werkzeugmaschinen (ohne Holzbearbeitungsmaschinen)'],
  [51812, 'Großhandel mit Holzbearbeitungsmaschinen'],
  [51820, 'Großhandel mit Bergwerks-, Bau- und Baustoffmaschinen'],
  [51830, 'Großhandel mit Textil-, Näh- und Strickmaschinen'],
  [51840, 'Großhandel mit Datenverarbeitungsgeräten, peripheren Einheiten und Software'],
  [51850, 'Großhandel mit sonstigen Büromaschinen und Büromöbeln'],
  [51851, 'Großhandel mit sonstigen Büromaschinen'],
  [51852, 'Großhandel mit Büromöbeln'],
  [51860, 'Großhandel mit elektronischen Bauelementen'],
  [51870, 'Großhandel mit sonstigen Maschinen, Ausrüstungen und Zubehör (ohne landwirtschaftliche Maschinen)'],
  [51871, 'Großhandel mit Flurförderzeugen und Fahrzeugen, anderweitig nicht genannt'],
  [51872, 'Großhandel mit sonstigen Maschinen (ohne landwirtschaftliche Maschinen)'],
  [51873, 'Großhandel mit sonstigen Ausrüstungen und Zubehör für Maschinen sowie technischem Bedarf'],
  [51880, 'Großhandel mit landwirtschaftlichen Maschinen und Geräten'],
  [51900, 'Sonstiger Großhandel'],
  [51901, 'Großhandel mit Rohstoffen, Halb- und Fertigwaren, ohne ausgeprägten Schwerpunkt'],
  [51902, 'Großhandel mit Rohstoffen und Halbwaren, ohne ausgeprägten Schwerpunkt'],
  [51903, 'Großhandel mit Fertigwaren, ohne ausgeprägten Schwerpunkt'],
  [52000, 'Einzelhandel (ohne Handel mit Kraftfahrzeugen und ohne Tankstellen), Reparatur von Gebrauchsgütern'],
  [52100, 'Einzelhandel mit Waren verschiedener Art (in Verkaufsräumen)'],
  [52110, 'Einzelhandel mit Waren verschiedener Art, Hauptrichtung Nahrungsmittel, Getränke und Tabakwaren'],
  [52111, 'Einzelhandel mit Nahrungsmitteln, Getränken und Tabakwaren, ohne ausgeprägten Schwerpunkt'],
  [52112, 'Sonstiger Einzelhandel mit Waren verschiedener Art, Hauptrichtung Nahrungsmittel, Getränke und Tabakwaren'],
  [52120, 'Sonstiger Einzelhandel mit Waren verschiedener Art'],
  [52121, 'Einzelhandel mit Waren verschiedener Art (ohne Nahrungsmittel)'],
  [52122, 'Einzelhandel mit Waren verschiedener Art, Hauptrichtung Nicht-Nahrungsmittel'],
  [52200, 'Facheinzelhandel mit Nahrungsmitteln, Getränken und Tabakwaren (in Verkaufsräumen)'],
  [52210, 'Einzelhandel mit Obst, Gemüse und Kartoffeln'],
  [52220, 'Einzelhandel mit Fleisch, Fleischwaren, Geflügel und Wild'],
  [52230, 'Einzelhandel mit Fisch, Meeresfrüchten und Fischerzeugnissen'],
  [52240, 'Einzelhandel mit Back- und Süßwaren'],
  [52241, 'Einzelhandel mit Backwaren'],
  [52242, 'Einzelhandel mit Süßwaren'],
  [52250, 'Einzelhandel mit Getränken'],
  [52251, 'Einzelhandel mit Wein, Sekt und Spirituosen'],
  [52252, 'Einzelhandel mit sonstigen Getränken'],
  [52260, 'Einzelhandel mit Tabakwaren'],
  [52270, 'Sonstiger Facheinzelhandel mit Nahrungsmitteln'],
  [52271, 'Einzelhandel mit Reformwaren'],
  [52275, 'Sonstiger Facheinzelhandel mit Nahrungsmitteln (ohne Reformwaren)'],
  [
    52300,
    'Apotheken, Facheinzelhandel mit medizinischen, orthopädischen und kosmetischen Artikeln (in Verkaufsräumen)',
  ],
  [52310, 'Apotheken'],
  [52320, 'Einzelhandel mit medizinischen und orthopädischen Artikeln'],
  [52330, 'Einzelhandel mit Parfümeriewaren und Körperpflegemitteln'],
  [52331, 'Einzelhandel mit kosmetischen Erzeugnissen und Körperpflegemitteln (ohne Drogerieartikel)'],
  [52332, 'Einzelhandel mit Drogerieartikeln'],
  [52400, 'Sonstiger Facheinzelhandel (in Verkaufsräumen)'],
  [52410, 'Einzelhandel mit Textilien'],
  [52411, 'Einzelhandel mit Haushaltstextilien'],
  [52412, 'Einzelhandel mit Kurzwaren, Schneidereibedarf, Handarbeiten sowie Meterware für Bekleidung und Wäsche'],
  [52420, 'Einzelhandel mit Bekleidung'],
  [52421, 'Einzelhandel mit Bekleidung, ohne ausgeprägten Schwerpunkt'],
  [52422, 'Einzelhandel mit Herrenbekleidung und Bekleidungszubehör'],
  [52423, 'Einzelhandel mit Damenbekleidung und Bekleidungszubehör'],
  [52424, 'Einzelhandel mit Kinder- und Säuglingsbekleidung und Bekleidungszubehör'],
  [52425, 'Einzelhandel mit Kürschnerwaren'],
  [52430, 'Einzelhandel mit Schuhen und Lederwaren'],
  [52431, 'Einzelhandel mit Schuhen'],
  [52432, 'Einzelhandel mit Leder- und Täschnerwaren'],
  [52440, 'Einzelhandel mit Möbeln, Einrichtungsgegenständen und Hausrat, anderweitig nicht genannt'],
  [52441, 'Einzelhandel mit Wohnmöbeln'],
  [52442, 'Einzelhandel mit Beleuchtungsartikeln'],
  [52443, 'Einzelhandel mit Haushaltsgegenständen'],
  [52444, 'Einzelhandel mit keramischen Erzeugnissen und Glaswaren'],
  [52446, 'Einzelhandel mit Holz-, Kork-, Flecht- und Korbwaren'],
  [52447, 'Einzelhandel mit Heimtextilien'],
  [52450, 'Einzelhandel mit elektrischen Haushaltsgeräten, Geräten der Unterhaltungselektronik und Musikinstrumenten'],
  [
    52451,
    'Einzelhandel mit elektrischen Haushaltsgeräten und elektrotechnischen Erzeugnissen, anderweitig nicht genannt',
  ],
  [52452, 'Einzelhandel mit Geräten der Unterhaltungselektronik und Zubehör'],
  [52453, 'Einzelhandel mit Musikinstrumenten und Musikalien'],
  [52460, 'Einzelhandel mit Metallwaren, Anstrichmitteln, Bau- und Heimwerkerbedarf'],
  [52461, 'Einzelhandel mit Eisen-, Metall- und Kunststoffwaren, anderweitig nicht genannt'],
  [52462, 'Einzelhandel mit Anstrichmitteln'],
  [52463, 'Einzelhandel mit Bau- und Heimwerkerbedarf'],
  [52470, 'Einzelhandel mit Büchern, Zeitschriften, Zeitungen, Schreibwaren und Bürobedarf'],
  [52471, 'Einzelhandel mit Schreib- und Papierwaren, Schul- und Büroartikeln'],
  [52472, 'Einzelhandel mit Büchern und Fachzeitschriften'],
  [52473, 'Einzelhandel mit Unterhaltungszeitschriften und Zeitungen'],
  [
    52480,
    'Einzelhandel mit Tapeten, Bodenbelägen, Kunstgegenständen, Briefmarken, Münzen, Geschenkartikeln, Uhren, Schmuck und Spielwaren',
  ],
  [52481, 'Einzelhandel mit Tapeten und Bodenbelägen'],
  [
    52482,
    'Einzelhandel mit Kunstgegenständen, Bildern, kunstgewerblichen Erzeugnissen, Briefmarken, Münzen und Geschenkartikeln',
  ],
  [52485, 'Einzelhandel mit Uhren, Edelmetallwaren und Schmuck'],
  [52486, 'Einzelhandel mit Spielwaren'],
  [52490, 'Facheinzelhandel, anderweitig nicht genannt (in Verkaufsräumen)'],
  [52491, 'Einzelhandel mit Blumen, Pflanzen und Saatgut'],
  [52492, 'Einzelhandel mit zoologischem Bedarf und lebenden Tieren'],
  [52493, 'Augenoptiker'],
  [52494, 'Einzelhandel mit Foto- und optischen Erzeugnissen (ohne Augenoptiker)'],
  [52495, 'Einzelhandel mit Computern, Computerteilen, peripheren Einheiten und Software'],
  [52496, 'Einzelhandel mit Telekommunikationsendgeräten und Mobiltelefonen'],
  [52497, 'Einzelhandel mit Fahrrädern, Fahrradteilen und -zubehör'],
  [52498, 'Einzelhandel mit Sport- und Campingartikeln (ohne Campingmöbel)'],
  [52499, 'Sonstiger Facheinzelhandel, anderweitig nicht genannt (in Verkaufsräumen)'],
  [52500, 'Einzelhandel mit Antiquitäten und Gebrauchtwaren (in Verkaufsräumen)'],
  [52501, 'Einzelhandel mit Antiquitäten und antiken Teppichen'],
  [52502, 'Antiquariate'],
  [52503, 'Einzelhandel mit sonstigen Gebrauchtwaren'],
  [52600, 'Einzelhandel (nicht in Verkaufsräumen)'],
  [52610, 'Versandhandel'],
  [52611, 'Versandhandel mit Waren, ohne ausgeprägten Schwerpunkt'],
  [52612, 'Versandhandel mit Textilien, Bekleidung, Schuhen und Lederwaren'],
  [52613, 'Sonstiger Fachversandhandel'],
  [52620, 'Einzelhandel an Verkaufsständen und auf Märkten'],
  [52621, 'Einzelhandel mit Nahrungsmitteln und Getränken an Verkaufsständen und auf Märkten'],
  [52622, 'Sonstiger Einzelhandel an Verkaufsständen und auf Märkten'],
  [52630, 'Sonstiger Einzelhandel (nicht in Verkaufsräumen)'],
  [52631, 'Einzelhandel vom Lager mit Brennstoffen'],
  [52634, 'Sonstiger Einzelhandel, anderweitig nicht genannt (nicht in Verkaufsräumen)'],
  [52700, 'Reparatur von Gebrauchsgütern'],
  [52710, 'Reparatur von Schuhen und Lederwaren'],
  [52720, 'Reparatur von elektrischen Haushaltsgeräten'],
  [52721, 'Reparatur von elektrischen Haushaltsgeräten (ohne Geräte der Unterhaltungselektronik)'],
  [52722, 'Reparatur von Geräten der Unterhaltungselektronik'],
  [52730, 'Reparatur von Uhren und Schmuck'],
  [52740, 'Reparatur von sonstigen Gebrauchsgütern'],
  [52741, 'Reparatur von Fahrrädern'],
  [52742, 'Reparatur von sonstigen Gebrauchsgütern, anderweitig nicht genannt'],
  [55000, 'Gastgewerbe'],
  [55100, 'Hotellerie'],
  [55101, 'Hotels (ohne Hotels garnis)'],
  [55102, 'Hotels garnis'],
  [55103, 'Gasthöfe'],
  [55104, 'Pensionen'],
  [55200, 'Sonstiges Beherbergungsgewerbe'],
  [55210, 'Jugendherbergen und Hütten'],
  [55220, 'Campingplätze'],
  [55230, 'Beherbergungsgewerbe, anderweitig nicht genannt'],
  [55231, 'Erholungs- und Ferienheime'],
  [55232, 'Ferienzentren'],
  [55233, 'Ferienhäuser und Ferienwohnungen'],
  [55234, 'Privatquartiere'],
  [55236, 'Boardinghouses'],
  [55237, 'Sonstiges Beherbergungsgewerbe, anderweitig nicht genannt'],
  [55300, 'Speisengeprägte Gastronomie'],
  [55301, 'Restaurants mit herkömmlicher Bedienung'],
  [55302, 'Restaurants mit Selbstbedienung'],
  [55303, 'Cafés'],
  [55304, 'Eissalons'],
  [55305, 'Imbissstuben'],
  [55400, 'Getränkegeprägte Gastronomie'],
  [55401, 'Schankwirtschaften'],
  [55403, 'Diskotheken und Tanzlokale'],
  [55405, 'Bars'],
  [55406, 'Vergnügungslokale'],
  [55407, 'Sonstige getränkegeprägte Gastronomie'],
  [55500, 'Kantinen und Caterer'],
  [55510, 'Kantinen'],
  [55520, 'Caterer'],
  [60000, 'Landverkehr, Transport in Rohrfernleitungen'],
  [60100, 'Eisenbahnverkehr'],
  [60200, 'Sonstiger Landverkehr'],
  [60210, 'Personenbeförderung im Linienverkehr zu Land'],
  [60211, 'Personenbeförderung im Omnibus-Orts- und -Nachbarortslinienverkehr'],
  [60212, 'Personenbeförderung im Omnibus-Überlandlinienverkehr'],
  [60213, 'Personenbeförderung mit Stadtschnellbahnen und Straßenbahnen'],
  [60214, 'Berg- und Seilbahnen'],
  [60220, 'Betrieb von Taxis und Mietwagen mit Fahrer'],
  [60230, 'Sonstige Personenbeförderung im Landverkehr'],
  [60231, 'Personenbeförderung im Omnibus-Gelegenheitsverkehr'],
  [60232, 'Personenbeförderung im Landverkehr, anderweitig nicht genannt'],
  [60240, 'Güterbeförderung im Straßenverkehr'],
  [60245, 'Erlaubnispflichtiger gewerblicher Güterkraftverkehr'],
  [60246, 'Erlaubnisfreier und freigestellter Straßengüterverkehr'],
  [60300, 'Transport in Rohrfernleitungen'],
  [60400, 'Deutsche Bahn AG'],
  [61000, 'Schifffahrt'],
  [61100, 'See- und Küstenschifffahrt'],
  [61200, 'Binnenschifffahrt'],
  [61201, 'Personenbeförderung in der Binnenschifffahrt'],
  [61202, 'Güterbeförderung in der Binnenschifffahrt durch Reedereien'],
  [61203, 'Güterbeförderung in der Binnenschifffahrt durch Partikuliere'],
  [61204, 'Fluss- und Kanalfähren, Hafenschifffahrt'],
  [62000, 'Luftfahrt'],
  [62100, 'Linienflugverkehr'],
  [62200, 'Gelegenheitsflugverkehr'],
  [62300, 'Raumtransport'],
  [63000, 'Hilfs- und Nebentätigkeiten für den Verkehr, Verkehrsvermittlung'],
  [63100, 'Frachtumschlag und Lagerei'],
  [63110, 'Frachtumschlag'],
  [63120, 'Lagerei'],
  [63121, 'Lagerei (ohne Kühlhäuser)'],
  [63122, 'Kühlhäuser'],
  [63200, 'Sonstige Hilfs- und Nebentätigkeiten für den Verkehr'],
  [63210, 'Sonstige Hilfs- und Nebentätigkeiten für den Landverkehr'],
  [63211, 'Parkhäuser und Parkplätze'],
  [63212, 'Hilfs- und Nebentätigkeiten für den Landverkehr, anderweitig nicht genannt'],
  [63220, 'Sonstige Hilfs- und Nebentätigkeiten für die Schifffahrt'],
  [63221, 'Sonstige Hilfs- und Nebentätigkeiten für die Binnenschifffahrt'],
  [63222, 'Seehafenbetriebe'],
  [63223, 'Bugsier- und Bergungsschifffahrt'],
  [63224, 'Lotsbetriebe'],
  [63230, 'Sonstige Hilfs- und Nebentätigkeiten für die Luftfahrt'],
  [63231, 'Flughafenbetriebe'],
  [63232, 'Landeplätze für Luftfahrzeuge'],
  [63233, 'Hilfs- und Nebentätigkeiten für die Luftfahrt, anderweitig nicht genannt'],
  [63300, 'Reisebüros und Reiseveranstalter'],
  [63301, 'Reisebüros'],
  [63302, 'Reiseveranstalter und Fremdenführung'],
  [63400, 'Spedition, sonstige Verkehrsvermittlung'],
  [63401, 'Spedition'],
  [63402, 'Schiffsmaklerbüros und -agenturen'],
  [63404, 'Logistische Dienstleistungen, anderweitig nicht genannt'],
  [63405, 'Verkehrsvermittlung, anderweitig nicht genannt'],
  [64000, 'Nachrichtenübermittlung'],
  [64100, 'Postverwaltung und private Post- und Kurierdienste'],
  [64110, 'Postverwaltung'],
  [64120, 'Private Post- und Kurierdienste'],
  [64121, 'Briefdienste'],
  [64122, 'Zeitungsdienste'],
  [64123, 'Paketdienste'],
  [64125, 'Expressdienste'],
  [64126, 'Kurierdienste'],
  [64127, 'Erbringung von sonstigen postalischen Dienstleistungen'],
  [64300, 'Fernmeldedienste'],
  [64301, 'Erbringung von festnetzgebundenen Telekommunikationsdienstleistungen'],
  [64302, 'Erbringung von Mobilfunkdienstleistungen'],
  [64303, 'Erbringung von Satellitenfunkdienstleistungen'],
  [64304, 'Erbringung von sonstigen Telekommunikationsdienstleistungen'],
  [64310, 'Erbringung sonstiger Reservierungsdienstleistungen'],
  [64400, 'Telekom AG'],
  [64500, 'Ehemalige Deutsche Bundespost'],
  [64600, 'Deutsche Post AG'],
  [65000, 'Kreditgewerbe'],
  [65100, 'Wertpapierfirma'],
  [65200, 'Sonstige Finanzierungsinstitutionen'],
  [65210, 'Institutionen für Finanzierungsleasing'],
  [65220, 'Spezialkreditinstitute'],
  [65230, 'Finanzierungsinstitutionen, anderweitig nicht genannt'],
  [65231, 'Kapitalverwaltungsgesellschaften gem. § 17 KAGB'],
  [65232, 'Leihhäuser'],
  [65233, 'Sonstige Finanzierungsinstitutionen, anderweitig nicht genannt'],
  [65240, 'Verbriefungszweckgesellschaften'],
  [65250, 'Finanzhandelsinstitute gem. §25f Abs. 1 KWG'],
  [65300, 'Fonds von Kapitalgesellschaften'],
  [65500, 'Ausländ. Finanzges. als Kreditinst. gem. §1Abs.1KWG'],
  [65600, 'Geldmarktfonds'],
  [65700, 'Beteiligungsunternehmen mit überwiegend finanziellem Anteilsbesitz'],
  [66000, 'Versicherungsgewerbe'],
  [66010, 'Lebensversicherungen'],
  [66011, 'Lebensversicherungen (ohne Rückversicherungen)'],
  [66012, 'Rückversicherungen für die Lebensversicherungen'],
  [66020, 'Pensions- und Sterbekassen'],
  [66030, 'Sonstiges Versicherungsgewerbe'],
  [66031, 'Krankenversicherungen'],
  [66032, 'Schaden- und Unfallversicherungen'],
  [66033, 'Rückversicherungen für das sonstige Versicherungsgewerbe'],
  [66100, 'Beteiligungsunternehmen mit aktivem Versicherungsgeschäft (neuer Branchenschlüssel 74c)'],
  [67000, 'Mit dem Kredit- und Versicherungsgewerbe verbundene Tätigkeiten'],
  [67100, 'Mit dem Kreditgewerbe verbundene Tätigkeiten'],
  [67110, 'Effekten- und Warenbörsen'],
  [67120, 'Effektenvermittlung und -verwaltung (ohne Effektenverwahrung)'],
  [67130, 'Sonstige mit dem Kreditgewerbe verbundene Tätigkeiten'],
  [67200, 'Mit dem Versicherungsgewerbe verbundene Tätigkeiten'],
  [67201, 'Versicherungsvertreter'],
  [67202, 'Versicherungsmakler'],
  [67203, 'Sonstige mit dem Versicherungsgewerbe verbundene Tätigkeiten'],
  [67300, 'Börseneinrichtungen mit tägl. Einschüssen'],
  [68000, 'Deutsche Bundesbank, Zentralnotenbanken'],
  [68100, 'Postgiroämter'],
  [68200, 'Kred.Anst.f.Wiederaufbau(KfW)'],
  [68300, 'Zuständige genossenschaftliche Zentralbank'],
  [68400, 'Andere Genossenschaftliche Zentralbanken'],
  [68500, 'Angeschlossene Kreditgenoss.(nur für genoss. Zentralbanken)'],
  [68600, 'Sonstige mindestreservepflichtige Kreditinstitute'],
  [68700, 'Ausländische Kreditinstitute gem. § 53 KWG'],
  [68800, 'Internationale Organisationen im Bereich KI'],
  [68900, 'Multilaterale Entwicklungsbanken nicht mindestreservefrei und ohne Nullgewichtung'],
  [69000, 'Bausparkassen'],
  [69100, 'LbNRW/InvB Berlin/Hessen/WK/LaBo/BremAB'],
  [69200, 'Multilaterale Entwicklungsbanken nicht mindestreservefrei mit Nullgewichtung'],
  [69300, 'Sonstige mindestreservefreie Kreditinstitute'],
  [69400, 'Internat. Organisationen im Bereich KI (AMR-frei)'],
  [69500, 'Europäische Investitionsbank'],
  [69600, 'Multilaterale Entwicklungsbanken (AMR-frei)'],
  [69700, 'Multilaterale Entwicklungsbanken mindestreservefrei und  mit Nullgewichtung'],
  [70000, 'Grundstücks- und Wohnungswesen'],
  [70100, 'Erschließung, Kauf und Verkauf von Grundstücken, Gebäuden und Wohnungen'],
  [70110, 'Erschließung von Grundstücken'],
  [70111, 'Erschließung von unbebauten Grundstücken'],
  [70112, 'Bauträger für Nichtwohngebäude'],
  [70113, 'Bauträger für Wohngebäude'],
  [70120, 'Kauf und Verkauf von eigenen Grundstücken, Gebäuden und Wohnungen'],
  [70121, 'Kauf und Verkauf von eigenen Grundstücken und Nichtwohngebäuden'],
  [70122, 'Kauf und Verkauf von eigenen Wohngebäuden und Wohnungen'],
  [70200, 'Vermietung und Verpachtung von eigenen Grundstücken, Gebäuden und Wohnungen'],
  [70201, 'Vermietung und Verpachtung von eigenen Grundstücken und Nichtwohngebäuden'],
  [70202, 'Vermietung und Verpachtung von eigenen Wohngebäuden und Wohnungen'],
  [70300, 'Vermittlung und Verwaltung von fremden Grundstücken, Gebäuden und Wohnungen'],
  [70310, 'Vermittlung von fremden Grundstücken, Gebäuden und Wohnungen'],
  [70311, 'Vermittlung von Gewerbegrundstücken und Nichtwohngebäuden für Dritte'],
  [70320, 'Verwaltung von fremden Grundstücken, Gebäuden und Wohnungen'],
  [70321, 'Verwaltung von Gewerbegrundstücken und Nichtwohngebäuden für Dritte'],
  [
    70400,
    'Vermietung und Verpachtung von eigenen Grundstücken und Nichtwohngebäuden und Bauträger für Nichtwohngebäude',
  ],
  [71000, 'Vermietung beweglicher Sachen ohne Bedienungspersonal'],
  [71100, 'Vermietung von Kraftwagen bis 3,5 t Gesamtgewicht'],
  [71200, 'Vermietung von sonstigen Verkehrsmitteln'],
  [71210, 'Vermietung von Landfahrzeugen (ohne Kraftwagen bis 3,5 t Gesamtgewicht)'],
  [71220, 'Vermietung von Wasserfahrzeugen'],
  [71230, 'Vermietung von Luftfahrzeugen'],
  [71300, 'Vermietung von Maschinen und Geräten'],
  [71310, 'Vermietung von landwirtschaftlichen Maschinen und Geräten'],
  [71320, 'Vermietung von Baumaschinen und -geräten'],
  [71330, 'Vermietung von Büromaschinen, Datenverarbeitungsgeräten und -einrichtungen'],
  [71340, 'Vermietung von sonstigen Maschinen und Geräten'],
  [71400, 'Vermietung von Gebrauchsgütern, anderweitig nicht genannt'],
  [71401, 'Verleih von Wäsche und Arbeitskleidung'],
  [71402, 'Verleih von Sportgeräten und Fahrrädern'],
  [71403, 'Leihbüchereien und Lesezirkel'],
  [71404, 'Videotheken'],
  [71405, 'Vermietung von sonstigen Gebrauchsgütern, anderweitig nicht genannt'],
  [71500, 'Leasing von nichtfinanziellen immatriellen Vermögensgegenständen (ohne Copyrights)'],
  [72000, 'Datenverarbeitung, Datenbanken und Rundfunkveranstalter'],
  [72100, 'Hardwareberatung'],
  [72200, 'Softwarehäuser'],
  [72210, 'Verlegen von Software'],
  [72220, 'Softwareberatung und -entwicklung'],
  [72221, 'Softwareberatung'],
  [72222, 'Entwicklung und Programmierung von Internetpräsentationen'],
  [72223, 'Sonstige Softwareentwicklung'],
  [72300, 'Datenverarbeitungsdienste'],
  [72301, 'Datenerfassungsdienste'],
  [72303, 'Bereitstellungsdienste für Teilnehmersysteme'],
  [72305, 'Sonstige Datenverarbeitungsdienste'],
  [72400, 'Datenbanken'],
  [72500, 'Instandhaltung und Reparatur von Büromaschinen, Datenverarbeitungsgeräten und -einrichtungen'],
  [72600, 'Sonstige mit der Datenverarbeitung verbundene Tätigkeiten und Rundfunkveranstalter'],
  [72601, 'Informationsvermittlung'],
  [72602, 'Mit der Datenverarbeitung verbundene Tätigkeiten, anderweitig nicht genannt'],
  [72610, 'Rundfunkveranstalter'],
  [73000, 'Forschung und Entwicklung'],
  [73100, 'Forschung und Entwicklung im Bereich Natur-, Ingenieur-, Agrarwissenschaften und Medizin'],
  [73101, 'Forschung und Entwicklung im Bereich Naturwissenschaften und Mathematik'],
  [73102, 'Forschung und Entwicklung im Bereich Ingenieurwissenschaften'],
  [73103, 'Forschung und Entwicklung im Bereich Agrar-, Forst- und Ernährungswissenschaften'],
  [73104, 'Forschung und Entwicklung im Bereich Medizin'],
  [73105, 'Forschung und Entwicklung im Umweltbereich'],
  [
    73200,
    'Forschung und Entwicklung im Bereich Rechts-, Wirtschafts- und Sozialwissenschaften sowie im Bereich Sprach-, Kultur- und Kunstwissenschaften',
  ],
  [73201, 'Forschung und Entwicklung im Bereich Rechts-, Wirtschafts- und Sozialwissenschaften'],
  [73202, 'Forschung und Entwicklung im Bereich Sprach-, Kultur- und Kunstwissenschaften'],
  [74000, 'Erbringung von wirtschaftlichen Dienstleistungen, anderweitig nicht genannt'],
  [
    74100,
    'Rechts-, Steuer- und Unternehmensberatung, Wirtschaftsprüfung, Buchführung, Markt- und Meinungsforschung, Managementtätigkeiten von Holdinggesellschaften',
  ],
  [74110, 'Rechtsberatung'],
  [74111, 'Rechtsanwaltskanzleien mit Notariat'],
  [74112, 'Rechtsanwaltskanzleien ohne Notariat'],
  [74113, 'Notariate'],
  [74114, 'Patentanwaltskanzleien'],
  [74115, 'Sonstige Rechtsberatung'],
  [74120, 'Wirtschafts- und Buchprüfung und Steuerberatung, Buchführung'],
  [74121, 'Praxen von Wirtschaftsprüferinnen und -prüfern, Wirtschaftsprüfungsgesellschaften'],
  [74122, 'Praxen von vereidigten Buchprüferinnen und -prüfern, Buchprüfungsgesellschaften'],
  [74123, 'Praxen von Steuerberaterinnen und -beratern, Steuerberatungsgesellschaften'],
  [74124, 'Praxen von Steuerbevollmächtigten'],
  [74125, 'Buchführung (ohne Datenverarbeitungsdienste)'],
  [74130, 'Markt- und Meinungsforschung'],
  [74131, 'Marktforschung'],
  [74132, 'Meinungsforschung'],
  [74140, 'Unternehmens- und Public-Relations-Beratung'],
  [74141, 'Unternehmensberatung'],
  [74142, 'Public-Relations-Beratung'],
  [74150, 'Managementtätigkeiten von Holdinggesellschaften'],
  [74151, 'Managementtätigkeiten von Holdinggesellschaften mit Schwerpunkt im Produzierenden Gewerbe'],
  [74152, 'Managementtätigkeiten von sonstigen Holdinggesellschaften (ohne geschlossene Immobilienfonds)'],
  [74153, 'Geschlossene Immobilienfonds mit Nichtwohngebäuden'],
  [74154, 'Geschlossene Immobilienfonds mit Wohngebäuden'],
  [74155, 'Komplementärgesellschaften'],
  [74156, 'Verwaltung und Führung von Unternehmen und Betrieben'],
  [74157, 'Holdinggesellschaften ohne Managementfunktion'],
  [74200, 'Architektur- und Ingenieurbüros'],
  [74201, 'Architekturbüros für Hochbau und für Innenarchitektur'],
  [74202, 'Architekturbüros für Orts-, Regional- und Landesplanung'],
  [74203, 'Architekturbüros für Garten- und Landschaftsgestaltung'],
  [74204, 'Ingenieurbüros für bautechnische Gesamtplanung'],
  [74205, 'Ingenieurbüros für technische Fachplanung'],
  [74206, 'Büros für Industrie-Design'],
  [74207, 'Büros baufachlicher Sachverständiger'],
  [74208, 'Büros für technisch-wirtschaftliche Beratung'],
  [74209, 'Vermessungsbüros'],
  [74300, 'Technische, physikalische und chemische Untersuchung'],
  [74301, 'Technische Untersuchung und Beratung'],
  [74302, 'Physikalische Untersuchung und Beratung'],
  [74303, 'Chemische Untersuchung und Beratung'],
  [74304, 'Betrieb von Messnetzen und Messstationen'],
  [74400, 'Werbung'],
  [74401, 'Werbegestaltung'],
  [74402, 'Werbemittelverbreitung und Werbevermittlung'],
  [74500, 'Personal- und Stellenvermittlung, Überlassung von Arbeitskräften'],
  [74501, 'Personal- und Stellenvermittlung'],
  [74502, 'Überlassung von Arbeitskräften'],
  [74600, 'Wach- und Sicherheitsdienste sowie Detekteien'],
  [74601, 'Detekteien'],
  [74602, 'Wach- und Sicherheitsdienste'],
  [74700, 'Reinigung von Gebäuden, Inventar und Verkehrsmitteln'],
  [74701, 'Reinigung von Gebäuden, Räumen und Inventar'],
  [74702, 'Schornsteinreinigung'],
  [74703, 'Reinigung von Verkehrsmitteln'],
  [74704, 'Desinfektion und Schädlingsbekämpfung'],
  [74800, 'Erbringung von sonstigen wirtschaftlichen Dienstleistungen, anderweitig nicht genannt'],
  [74810, 'Fotografisches Gewerbe und fotografische Laboratorien'],
  [74811, 'Fotografisches Gewerbe'],
  [74812, 'Fotografische Laboratorien'],
  [74820, 'Abfüll- und Verpackungsgewerbe'],
  [74850, 'Sekretariats-, Schreib- und Übersetzungsdienste, Copy-Shops'],
  [74851, 'Freiberufliche Dolmetscher'],
  [74852, 'Übersetzungsbüros'],
  [74853, 'Sekretariats- und Schreibdienste, Copy-Shops'],
  [74860, 'Call Centers'],
  [
    74870,
    'Erbringung von wirtschaftlichen Dienstleistungen für Unternehmen und Privatpersonen, anderweitig nicht genannt',
  ],
  [74871, 'Ausstellungs-, Messe- und Warenmarkteinrichtungen'],
  [74872, 'Sachverständige, anderweitig nicht genannt'],
  [74873, 'Versteigerungsgewerbe'],
  [74874, 'Ateliers für Textil-, Schmuck-, Möbel- u.ä. Design'],
  [74875, 'Auskunfteien'],
  [74876, 'Inkassobüros'],
  [74877, 'Sonstige Vermögensberatung'],
  [
    74878,
    'Erbringung von sonstigen wirtschaftlichen Dienstleistungen für Unternehmen und Privatpersonen, anderweitig nicht genannt',
  ],
  [74880, 'Eigene Vermögensverwaltung Unternehmen'],
  [
    74900,
    'Beteiligungsunternehmen mit überwiegend nicht finanziellem Anteilsbesitz (diese verbleiben in dem bisherigen Branchenschlüssel 74b)',
  ],
  [75000, 'Öffentliche Verwaltung, Verteidigung, Sozialversicherung'],
  [75100, 'Öffentliche Verwaltung'],
  [75110, 'Allgemeine öffentliche Verwaltung'],
  [75120, 'Öffentliche Verwaltung auf den Gebieten Gesundheitswesen, Bildung, Kultur und Sozialwesen'],
  [75122, 'Öffentliche Verwaltung auf den Gebieten Bildung und Kultur'],
  [75123, 'Öffentliche Verwaltung auf dem Gebiet Sozialwesen'],
  [75124, 'Öffentliche Verwaltung auf dem Gebiet Gesundheitswesen'],
  [75125, 'Öffentliche Verwaltung auf dem Gebiet Sport'],
  [75130, 'Wirtschaftsförderung, -ordnung und -aufsicht'],
  [75140, 'Sonstige mit der öffentlichen Verwaltung verbundene Tätigkeiten'],
  [75200, 'Auswärtige Angelegenheiten, Verteidigung, Rechtspflege, öffentliche Sicherheit und Ordnung'],
  [75210, 'Auswärtige Angelegenheiten'],
  [75220, 'Verteidigung'],
  [75230, 'Rechtspflege'],
  [75240, 'Öffentliche Sicherheit und Ordnung'],
  [75250, 'Feuerschutz'],
  [75300, 'Sozialversicherung und Arbeitsförderung'],
  [75301, 'Gesetzliche Rentenversicherung (ohne knappschaftliche Rentenversicherung und Altershilfe für Landwirte)'],
  [75302, 'Knappschaftliche Rentenversicherung'],
  [75303, 'Altershilfe für Landwirte'],
  [75304, 'Zusatzversorgung für Angehörige des öffentlichen Dienstes'],
  [75305, 'Gesetzliche Krankenversicherung (ohne knappschaftliche Krankenversicherung)'],
  [75306, 'Knappschaftliche Krankenversicherung'],
  [75307, 'Gesetzliche Unfallversicherung'],
  [75308, 'Arbeitsförderung'],
  [75309, 'Sonstige Sozialversicherung'],
  [76000, 'Bund'],
  [76100, 'Fonds Deutsche Einheit / Erblastentilgungsfonds'],
  [76200, 'ERP-Sondervermögen'],
  [76300, 'Ehemalige Treuhandanstalt'],
  [76400, 'Bundeseisenbahnvermögen'],
  [77000, 'Länder'],
  [77500, 'Gemeinden'],
  [77600, 'Kommunale Zweckverbände'],
  [77700, 'Sonstige ausländische Gebietskörperschaften'],
  [80000, 'Erziehung und Unterricht'],
  [80100, 'Kindergärten, Vor- und Grundschulen'],
  [80101, 'Kindergärten'],
  [80102, 'Kinderhorte'],
  [80104, 'Vorklassen, Schulkindergärten'],
  [80105, 'Grundschulen'],
  [80200, 'Weiterführende Schulen'],
  [80210, 'Allgemein bildende weiterführende Schulen'],
  [80211, 'Allgemein bildende weiterführende Schulen Sekundarbereich I'],
  [80212, 'Allgemein bildende weiterführende Schulen Sekundarbereich II'],
  [80220, 'Berufsbildende weiterführende Schulen im Sekundarbereich'],
  [80300, 'Hochschulen und andere Bildungseinrichtungen des Tertiärsbereichs'],
  [80305, 'Universitäten'],
  [80306, 'Allgemeine Fachhochschulen'],
  [80307, 'Verwaltungsfachhochschulen'],
  [80308, 'Berufsakademien, Fachakademien, Schulen des Gesundheitswesens'],
  [80400, 'Erwachsenenbildung und sonstiger Unterricht'],
  [80410, 'Fahr- und Flugschulen'],
  [80411, 'Kraftfahrschulen'],
  [80412, 'Flug-, Bootsführer-, Segel- u.ä. Schulen'],
  [80420, 'Erwachsenenbildung und Unterricht, anderweitig nicht genannt'],
  [80421, 'Allgemeine und politische Erwachsenenbildung'],
  [80422, 'Berufliche Erwachsenenbildung'],
  [80423, 'Selbstständige Lehrerinnen und Lehrer'],
  [80424, 'Unterricht, anderweitig nicht genannt'],
  [85000, 'Gesundheits-, Veterinär- und Sozialwesen'],
  [85100, 'Gesundheitswesen'],
  [85110, 'Krankenhäuser'],
  [85111, 'Krankenhäuser (ohne Hochschulkliniken sowie Vorsorge- und Rehabilitationskliniken)'],
  [85112, 'Hochschulkliniken'],
  [85113, 'Vorsorge- und Rehabilitationskliniken'],
  [85120, 'Arztpraxen (ohne Zahnarztpraxen)'],
  [85121, 'Arztpraxen für Allgemeinmedizin und Praxen von praktischen Ärztinnen und Ärzten'],
  [85122, 'Facharztpraxen (ohne Arztpraxen für Allgemeinmedizin)'],
  [85130, 'Zahnarztpraxen'],
  [85140, 'Gesundheitswesen, anderweitig nicht genannt'],
  [85141, 'Praxen von psychologischen Psychotherapeutinnen und -therapeuten'],
  [
    85142,
    'Massagepraxen, Praxen von medizinischen Bademeisterinnen und Bademeistern, Krankengymnastikpraxen, Praxen von Hebammen und Entbindungspflegern sowie von verwandten Berufen',
  ],
  [85143, 'Heilpraktikerpraxen'],
  [85144, 'Sonstige selbstständige Tätigkeiten im Gesundheitswesen'],
  [85145, 'Krankentransport- und Rettungsdienste'],
  [85146, 'Sonstige Anstalten und Einrichtungen des Gesundheitswesens'],
  [85200, 'Veterinärwesen'],
  [85201, 'Tierarztpraxen'],
  [85202, 'Sonstige selbstständige Tätigkeiten im Veterinärwesen'],
  [85203, 'Anstalten und Einrichtungen des Veterinärwesens'],
  [85300, 'Sozialwesen'],
  [85310, 'Heime (ohne Erholungs- und Ferienheime)'],
  [85311, 'Jugendwohnheime'],
  [85312, 'Erziehungsheime'],
  [85313, 'Altenwohnheime'],
  [85314, 'Altenheime'],
  [85315, 'Altenpflegeheime'],
  [85316, 'Heime für werdende Mütter sowie Mütter oder Väter mit Kind'],
  [85317, 'Einrichtungen zur Eingliederung und Pflege Behinderter'],
  [85318, 'Wohnheime für Behinderte'],
  [85319, 'Sonstige Heime (ohne Erholungs- und Ferienheime)'],
  [85320, 'Sozialwesen, anderweitig nicht genannt'],
  [85321, 'Tagesstätten (ohne Kinderkrippen, Kindergärten, Kinderhorte und Jugendzentren)'],
  [85322, 'Kinderkrippen und außerhäusliche Kinderbetreuung, anderweitig nicht genannt'],
  [85323, 'Jugendzentren und Häuser der offenen Tür'],
  [85324, 'Erziehungs-, Jugend- und Familienberatungsstellen'],
  [85325, 'Sonstige soziale Beratungsstellen'],
  [85326, 'Ambulante soziale Dienste'],
  [85327, 'Organisationen der freien Wohlfahrtspflege und Jugendhilfe'],
  [85328, 'Unterstützungskassen'],
  [85329, 'Sonstiges Sozialwesen, anderweitig nicht genannt'],
  [90000, 'Abwasser- und Abfallbeseitigung und sonstige Entsorgung'],
  [90010, 'Abwasserbeseitigung'],
  [90011, 'Kläranlagen'],
  [90012, 'Sammelkanalisation'],
  [90020, 'Abfallbeseitigung'],
  [90021, 'Sammlung, Beförderung und Zwischenlagerung von Abfällen'],
  [90022, 'Thermische Abfallbeseitigung'],
  [90023, 'Abfalldeponien'],
  [90024, 'Biologische Abfallbeseitigung'],
  [90025, 'Sonstige Abfallbeseitigung'],
  [90030, 'Beseitigung von Umweltverschmutzungen und sonstige Entsorgung'],
  [91000, 'Interessenvertretungen sowie kirchliche und sonstige Vereinigungen (ohne Sozialwesen, Kultur und Sport)'],
  [91100, 'Wirtschafts- und Arbeitgeberverbände, Berufsorganisationen'],
  [91110, 'Wirtschafts- und Arbeitgeberverbände'],
  [91111, 'Wirtschaftsverbände (ohne öffentlich-rechtliche Wirtschaftsvertretungen)'],
  [91112, 'Öffentlich-rechtliche Wirtschaftsvertretungen'],
  [91113, 'Arbeitgeberverbände'],
  [91120, 'Berufsorganisationen'],
  [91121, 'Berufsorganisationen (ohne öffentlich-rechtliche Berufsvertretungen)'],
  [91122, 'Öffentlich-rechtliche Berufsvertretungen'],
  [
    91300,
    'Kirchliche Vereinigungen, politische Parteien sowie sonstige Interessenvertretungen und Vereinigungen, anderweitig nicht genannt',
  ],
  [91310, 'Kirchliche und sonstige religiöse Vereinigungen'],
  [91311, 'Kirchen und kirchlich-religiöse Vereinigungen'],
  [91312, 'Sonstige religiöse und weltanschauliche Vereinigungen'],
  [91320, 'Politische Parteien und Vereinigungen'],
  [91330, 'Interessenvertretungen und Vereinigungen, anderweitig nicht genannt'],
  [91331, 'Organisationen der Bildung, Wissenschaft, Forschung und Kultur'],
  [91332, 'Organisationen des Gesundheitswesens'],
  [91333, 'Jugendorganisationen'],
  [91334, 'Verbraucherorganisationen'],
  [91335, 'Kommunale Spitzen- und Regionalverbände'],
  [91336, 'Verbände der Sozialversicherungsträger'],
  [91337, 'Sonstige Interessenvertretungen und Vereinigungen, anderweitig nicht genannt'],
  [92000, 'Kultur, Sport und Unterhaltung'],
  [92100, 'Film- und Videofilmherstellung, -verleih und -vertrieb, Kinos'],
  [92110, 'Film- und Videofilmherstellung'],
  [92111, 'Herstellung von Kinofilmen'],
  [92112, 'Herstellung von Fernsehfilmen'],
  [92113, 'Herstellung von Industrie-, Wirtschafts- und Werbefilmen'],
  [92114, 'Sonstige Filmherstellung'],
  [92115, 'Filmtechnik'],
  [92116, 'Tonstudios'],
  [92120, 'Filmverleih und Videoprogrammanbieter'],
  [92121, 'Filmverleih'],
  [92122, 'Videoprogrammanbieter'],
  [92123, 'Filmvertrieb'],
  [92130, 'Kinos'],
  [92200, 'Rundfunkveranstalter, Herstellung von Hörfunk- und Fernsehprogrammen'],
  [92201, 'Rundfunkveranstalter'],
  [92202, 'Herstellung von Hörfunk- und Fernsehprogrammen'],
  [92300, 'Erbringung von sonstigen kulturellen und unterhaltenden Leistungen'],
  [92310, 'Künstlerische und schriftstellerische Tätigkeiten und Darbietungen'],
  [92311, 'Theaterensembles'],
  [92312, 'Ballettgruppen, Orchester, Kapellen und Chöre'],
  [92313, 'Selbstständige bildende Künstlerinnen und Künstler'],
  [92314, 'Selbstständige Restauratorinnen und Restauratoren'],
  [92315, 'Selbstständige Komponistinnen, Komponisten, Musikbearbeiterinnen und Musikbearbeiter'],
  [92316, 'Selbstständige Schriftstellerinnen und Schriftsteller'],
  [92317, 'Selbstständige Bühnen-, Film-, Hörfunk- und Fernsehkünstlerinnen und -künstler'],
  [92318, 'Selbstständige Artistinnen und Artisten'],
  [92320, 'Betrieb von Kultur- und Unterhaltungseinrichtungen und Hilfsdienste dafür'],
  [92321, 'Theater- und Konzertveranstalter'],
  [92322, 'Opern- und Schauspielhäuser, Konzerthallen und ähnliche Einrichtungen'],
  [92323, 'Varietés und Kleinkunstbühnen'],
  [92325, 'Technische Hilfsdienste für kulturelle und unterhaltende Leistungen'],
  [92330, 'Schaustellergewerbe und Vergnügungsparks'],
  [92340, 'Erbringung von kulturellen und unterhaltenden Leistungen, anderweitig nicht genannt'],
  [92341, 'Tanzschulen'],
  [92342, 'Erbringung von sonstigen kulturellen und unterhaltenden Leistungen, anderweitig nicht genannt'],
  [92400, 'Korrespondenz- und Nachrichtenbüros, selbstständige Journalistinnen und Journalisten'],
  [92401, 'Korrespondenz- und Nachrichtenbüros'],
  [92402, 'Selbstständige Journalistinnen, Journalisten, Pressefotografinnen und Pressefotografen'],
  [92500, 'Bibliotheken, Archive, Museen, botanische und zoologische Gärten'],
  [92510, 'Bibliotheken und Archive'],
  [92520, 'Museen und Denkmalschutzeinrichtungen'],
  [92521, 'Museen und Kunstausstellungen'],
  [92522, 'Denkmalschutzeinrichtungen'],
  [92530, 'Botanische und zoologische Gärten sowie Naturparks'],
  [92531, 'Botanische und zoologische Gärten'],
  [92532, 'Naturparks und Tiergehege'],
  [92533, 'Natur- und Landschaftsschutz'],
  [92600, 'Sport'],
  [92610, 'Betrieb von Sportanlagen'],
  [92620, 'Erbringung von sonstigen Dienstleistungen des Sports'],
  [92621, 'Sportverbände und Sportvereine'],
  [92622, 'Professionelle Sportmannschaften und Rennställe'],
  [92623, 'Selbstständige Berufssportlerinnen und -sportler sowie -trainerinnen und -trainer'],
  [92624, 'Sportpromoter und sonstige professionelle Sportveranstalter'],
  [92625, 'Sportschulen und selbstständige Sportlehrerinnen und -lehrer'],
  [92700, 'Erbringung von sonstigen Dienstleistungen für Unterhaltung, Erholung und Freizeit'],
  [92710, 'Spiel-, Wett- und Lotteriewesen'],
  [92711, 'Spielhallen und Betrieb von Spielautomaten'],
  [92712, 'Spielbanken und Spielklubs'],
  [92713, 'Wett-, Toto- und Lotteriewesen'],
  [92720, 'Erbringung von Dienstleistungen für Unterhaltung, Erholung und Freizeit, anderweitig nicht genannt'],
  [92721, 'Garten- und Grünanlagen'],
  [
    92722,
    'Erbringung von sonstigen Dienstleistungen für Unterhaltung, Erholung und Freizeit, anderweitig nicht genannt',
  ],
  [93000, 'Erbringung von sonstigen Dienstleistungen'],
  [93010, 'Wäscherei und chemische Reinigung'],
  [93011, 'Wäscherei'],
  [93012, 'Annahmestellen für Wäscherei'],
  [93013, 'Chemische Reinigung und Bekleidungsfärberei'],
  [93014, 'Annahmestellen für chemische Reinigung und Bekleidungsfärberei'],
  [93015, 'Heißmangelei und Bügelei'],
  [93020, 'Frisör- und Kosmetiksalons'],
  [93024, 'Kosmetiksalons'],
  [93025, 'Frisörsalons'],
  [93030, 'Bestattungswesen'],
  [93031, 'Bestattungsinstitute'],
  [93032, 'Friedhöfe und Krematorien'],
  [93040, 'Saunas, Solarien, Fitnesszentren u.ä.'],
  [93041, 'Bäder und Saunas (ohne medizinische Bäder)'],
  [93042, 'Solarien, Massagesalons (ohne medizinische Massagen), Fitnesszentren u.ä.'],
  [93050, 'Erbringung von Dienstleistungen, anderweitig nicht genannt'],
  [93051, 'Ehevermittlungsinstitute'],
  [93052, 'Erbringung von sonstigen persönlichen Dienstleistungen'],
  [93053, 'Erbringung von sonstigen Dienstleistungen, anderweitig nicht genannt'],
  [93054, 'Fischbestandsberatung'],
  [95000, 'Private Haushalte mit Hauspersonal'],
  [95002, 'Private Haushalte mit Hauspersonal zur Kinderbetreuung'],
  [95003, 'Private Haushalte mit sonstigem Hauspersonal'],
  [96000, 'Kirchen mit Steuererhebungsrecht'],
  [96100, 'Kirchen,Orden,rel/weltansch.Verein.o.Steuererhebungsrecht'],
  [96200, 'Organisationen der freien Wohlfahrts-/Jugendpflege'],
  [96300, 'Organisationen der Bildung/Wissenschaft/Forsch./Kultur'],
  [96400, 'Organisationen des Sports und Gesundheitswesens'],
  [96500, 'Gewerkschaften'],
  [96600, 'Kommunale Spitzen-/Regionalverbände (ohne.komm.Zweckverb.)'],
  [96700, 'Verbände der Sozialversicherung'],
  [96800, 'Politische Parteien'],
  [96900, 'Sonstige Organisationen ohne Erwerbszweck'],
  [99000, 'Exterritoriale Organisationen und Körperschaften'],
  [99001, 'Vertretungen fremder Staaten'],
  [99002, 'Dienststellen von Stationierungsstreitkräften'],
  [99003, 'Internationale und supranationale Organisationen mit Behördencharakter'],
  [99100, 'Europäische Gemeinschaften'],
]);

export const RechtsformMap = new Map([
  ['Gesellschaft mit beschränkter Haftung (GmbH)', 'GMBH'],
  ['Offene Handelsgesellschaft (OHG)', 'OHG'],
  ['Sonstige Vereinigung von Privatpersonen', 'GBR'],
  ['Ges.m.beschr.Haftung KG (GmbH KG/GmbH & Co.KG)', 'GMBH_CO_KG'],
  ['Einzelunternehmen (e.K.)', 'EK'],
  ['Kommanditgesellschaft (KG)', 'KG'],
  ['Unternehmergesellschaft (haftungsbeschränkt) UG', 'UG'],
  ['Freiberufler(in) m.überw.gesch.Charakter', 'FREIBERUFLER'],
]);
