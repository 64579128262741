<h1 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.TITLE_TEXT'"></h1>
<form class="col-xl-10 card-container" [formGroup]="answerFormGroup" *ngIf="!juriPerson">
  <div class="row">
    <div class="col-12">
      <div class="row section-container">
        <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.FIRMENINFORMATION'"></h2>
        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.EINGETRAGENERNAME'"></mat-label>
            <input matInput formControlName="eingetragenerName" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('eingetragenerName')?.valid" class="check-icon"
              >check</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col-4"></div>
      </div>

      <div class="row section-container">
        <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.INHABER'"></h2>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.ANREDE'"></mat-label>
            <input matInput formControlName="anrede" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('anrede')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.TITEL'"></mat-label>
            <input matInput formControlName="titel" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('titel')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.VORNAME'"></mat-label>
            <input matInput formControlName="vorname" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('vorname')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.NACHNAME'"></mat-label>
            <input matInput formControlName="nachname" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('nachname')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.GEBURTSDATUM'"></mat-label>
            <input matInput formControlName="geburtsdatum" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('geburtsdatum')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.NATIONALITÄT'"></mat-label>
            <input matInput formControlName="nationalität" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('nationalität')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="row section-container">
        <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.FIRMENANSCHRIFT'"></h2>
        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.STRASSEMITHAUSNUMMER'"></mat-label>
            <input matInput formControlName="strasseMitHausnummer" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('strasseMitHausnummer')?.valid" class="check-icon"
              >check</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.POSTLEITZAHL'"></mat-label>
            <input matInput formControlName="postleitzahl" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('postleitzahl')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-8">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.ORT'"></mat-label>
            <input matInput formControlName="ort" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('ort')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.LAND'"></mat-label>
            <input matInput formControlName="land" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('land')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="row section-container">
        <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.AUSWEISDATEN'"></h2>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.AUSWEISNUMMER'"></mat-label>
            <input matInput formControlName="ausweisnummer" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('ausweisnummer')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.AUSGESTELLTAM'"></mat-label>
            <input matInput formControlName="ausgestelltAm" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('ausgestelltAm')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.GÜLTIGBIS'"></mat-label>
            <input matInput formControlName="gültigBis" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('gültigBis')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.AUSWEISART'"></mat-label>
            <input matInput formControlName="ausweisart" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('ausweisart')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-8">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.AUSZUSTELLENDEBEHÖRDE'"></mat-label>
            <input matInput formControlName="auszustellendeBehörde" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('auszustellendeBehörde')?.valid" class="check-icon"
              >check</mat-icon
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row section-container">
        <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.KONTAKT_INFO'"></h2>
        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" aria-disabled="true">
            <mat-label [translate]="'STAMMDATEN.EMAIL'"></mat-label>
            <input matInput formControlName="email" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('email')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label>Persönliche Telefonnummer</mat-label>
            <input matInput formControlName="telefon" [readonly]="!telefonEditable" />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('telefon')?.valid && !telefonEditable" class="check-icon"
              >check</mat-icon
            >
            <mat-icon matSuffix *ngIf="telefonEditable" class="error-icon">edit</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6 btn-right">
          <button
            mat-stroked-button
            [ngClass]="{
              'mat-stroked-button': !telefonEditable,
              'color-change': telefonEditable,
            }"
            color="accent"
            (click)="telefonEditableToggle()"
          >
            {{
              (telefonEditable === true ? 'STAMMDATEN.ÄNDERUNG_BESTÄTIGEN' : 'STAMMDATEN.TELEFONNUMMER_ÄNDERN')
                | translate
            }}
          </button>
        </div>
      </div>
      <!-- <div class="row section-container">
        <h2
          class="text-primary"
          appTranslationModal
          [translate]="'STAMMDATEN.STEUER_ID'"
        ></h2>
        <div class="col-12">
          <p
            class="mb-3"
            appTranslationModal
            [translate]="'STAMMDATEN.STEUER_ID_SUB_TEXT'"
          ></p>
        </div>
        <div class="col-12 col-lg-6 input-icon-wrapper">
          <mat-form-field
            floatLabel="always"
            class="full-width"
            appearance="outline"
          >
            <mat-label>Steuer-ID</mat-label>
            <input
              matInput
              formControlName="steuerId"
              placeholder="Eingabe SteuerId"
              [readonly]="!steuerIdEditable"
            />
            <mat-icon
              matSuffix
              *ngIf="answerFormGroup.get('telefon')?.valid && !steuerIdEditable"
              class="check-icon"
              >check</mat-icon
            >
            <mat-icon matSuffix *ngIf="steuerIdEditable" class="error-icon"
              >edit</mat-icon
            >
          </mat-form-field>
          <app-info-card [header]="'STAMMDATEN.INFO_TEXT_HEADER' | translate">
            {{ "STAMMDATEN.INFO_TEXT" | translate }}
          </app-info-card>
        </div>
        <div class="col-12 col-lg-6 btn-right">
          <button
            mat-stroked-button
            color="accent"
            [ngClass]="{
              'mat-stroked-button': !steuerIdEditable,
              'color-change': steuerIdEditable
            }"
            (click)="steuerIdEditableToggle()"
          >
            {{
              (steuerIdEditable === true
                ? "STAMMDATEN.ÄNDERUNG_BESTÄTIGEN"
                : "STAMMDATEN.STEUER_ÄNDERN"
              ) | translate
            }}
          </button>
        </div>
      </div> -->
    </div>
  </div>
</form>

<form class="col-xl-10 card-container" [formGroup]="answerFormGroup" *ngIf="juriPerson">
  <div class="row">
    <div class="col-12">
      <div class="row section-container">
        <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.FIRMENINFORMATION'"></h2>
        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.EINGETRAGENERFIRMENNAMENAME'"></mat-label>
            <input matInput formControlName="eingetragenerName" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('eingetragenerName')?.valid" class="check-icon"
              >check</mat-icon
            >
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.RECHTSFORMAUSPRÄGUNG'"></mat-label>
            <input matInput [value]="rechtsformAuspraegungControl.value" readonly />
            <mat-icon matSuffix *ngIf="rechtsformAuspraegungControl.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-4"></div>
      </div>

      <div class="row section-container">
        <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.FIRMENANSCHRIFT'"></h2>
        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.STRASSEMITHAUSNUMMER'"></mat-label>
            <input matInput formControlName="strasseMitHausnummer" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('strasseMitHausnummer')?.valid" class="check-icon"
              >check</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.POSTLEITZAHL'"></mat-label>
            <input matInput formControlName="postleitzahl" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('postleitzahl')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-8">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.ORT'"></mat-label>
            <input matInput formControlName="ort" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('ort')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.LAND'"></mat-label>
            <input matInput formControlName="land" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('land')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="row section-container">
        <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.STEUERNUMMERHANDELSREGISTER'"></h2>
        <div class="col-12 col-lg-6 input-icon-wrapper">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.STEUERNUMMER_FÜR_ERTRAGSSTEUERZWECKE'"></mat-label>
            <input
              matInput
              formControlName="Steuernummer"
              placeholder="Eingabe Steuernummer"
              [readonly]="!steuerIdEditable"
            />
            <mat-icon
              matSuffix
              *ngIf="answerFormGroup.get('Steuernummer')?.value !== '' && !steuerIdEditable"
              class="check-icon"
              >check</mat-icon
            >
            <mat-icon matSuffix *ngIf="steuerIdEditable && auth.user.lang === 'mcc'" class="error-icon">edit</mat-icon>
            <mat-icon matSuffix *ngIf="steuerIdEditable && auth.user.lang === 'odfin'" class="odfincolor"
              >edit</mat-icon
            >
          </mat-form-field>
          <app-info-card [header]="'Wo finde ich die Steuer-Nummer?' | translate">
            <p [innerHTML]="'STAMMDATEN.DESCRIPTION' | translate"></p>
          </app-info-card>
        </div>

        <div class="col-12 col-lg-6 btn-right">
          <button
            *ngIf="auth.user.lang === 'mcc'"
            mat-stroked-button
            color="accent"
            [ngClass]="{
              'mat-stroked-button': !steuerIdEditable,
              'color-change': steuerIdEditable,
            }"
            (click)="steuerIdEditableToggle()"
          >
            {{ (steuerIdEditable === true ? 'STAMMDATEN.ÄNDERUNG_BESTÄTIGEN' : 'Steuernummer Ändern') | translate }}
          </button>

          <button
            *ngIf="auth.user.lang === 'odfin'"
            mat-stroked-button
            color="accent"
            [ngClass]="{
              'mat-stroked-button': !steuerIdEditable,
              'odfincolor-change': steuerIdEditable,
            }"
            (click)="steuerIdEditableToggle()"
          >
            {{ (steuerIdEditable === true ? 'STAMMDATEN.ÄNDERUNG_BESTÄTIGEN' : 'Steuernummer Ändern') | translate }}
          </button>
        </div>

        <div class="col-12 col-lg-12 input-icon-wrapper">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.REGISTERGERICHT'"></mat-label>
            <input matInput [value]="registergerichtControl.value" placeholder="Eingabe SteuerId" />
            <mat-icon matSuffix *ngIf="registergerichtControl?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.HANDELSREGISTER'"></mat-label>
            <input matInput [value]="registernummerControl.value" placeholder="Eingabe SteuerId" />
            <mat-icon matSuffix *ngIf="registernummerControl?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="row section-container">
        <h2
          class="text-primary"
          appTranslationModal
          [translate]="'Ihre persönlichen Daten als Geschäftsführer/ges. Vertreter'"
        ></h2>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.ANREDE'"></mat-label>
            <input matInput formControlName="anrede" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('anrede')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.TITEL'"></mat-label>
            <input matInput formControlName="titel" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('titel')?.value !== ''" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.VORNAME'"></mat-label>
            <input matInput formControlName="vorname" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('vorname')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.NACHNAME'"></mat-label>
            <input matInput formControlName="nachname" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('nachname')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.GEBURTSDATUM'"></mat-label>
            <input matInput formControlName="geburtsdatum" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('geburtsdatum')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.NATIONALITÄT'"></mat-label>
            <input matInput formControlName="nationalität" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('nationalität')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="row section-container">
        <h2
          class="text-primary"
          appTranslationModal
          [translate]="'Meldeadresse des Geschäftsführer/ges. Vertreter'"
        ></h2>
        <div class="line-wrapper" style="margin-bottom: 1rem">
          <mat-icon class="info-icon">info</mat-icon>
          <div>
            <span
              appTranslationModal
              [translate]="'An diese Addresse wird die persönliche Post geschickt (z.B. Onlinebanking Zugang)'"
            ></span>
          </div>
        </div>
        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.STRASSEMITHAUSNUMMER'"></mat-label>
            <input matInput formControlName="meldeadresseStrasse" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('meldeadresseStrasse')?.valid" class="check-icon"
              >check</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.POSTLEITZAHL'"></mat-label>
            <input matInput formControlName="meldeadressePLZ" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('meldeadressePLZ')?.valid" class="check-icon"
              >check</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-8">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'STAMMDATEN.ORT'"></mat-label>
            <input matInput formControlName="meldeadresseOrt" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('meldeadresseOrt')?.valid" class="check-icon"
              >check</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.LAND'"></mat-label>
            <input matInput formControlName="meldeadresseLand" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('meldeadresseLand')?.valid" class="check-icon"
              >check</mat-icon
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row section-container">
        <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.AUSWEISDATEN'"></h2>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.AUSWEISNUMMER'"></mat-label>
            <input matInput formControlName="ausweisnummer" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('ausweisnummer')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.AUSGESTELLTAM'"></mat-label>
            <input matInput formControlName="ausgestelltAm" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('ausgestelltAm')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.GÜLTIGBIS'"></mat-label>
            <input matInput formControlName="gültigBis" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('gültigBis')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.AUSWEISART'"></mat-label>
            <input matInput formControlName="ausweisart" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('ausweisart')?.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-8">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
            <mat-label [translate]="'STAMMDATEN.AUSZUSTELLENDEBEHÖRDE'"></mat-label>
            <input matInput formControlName="auszustellendeBehörde" readonly />
            <mat-icon matSuffix *ngIf="answerFormGroup.get('auszustellendeBehörde')?.valid" class="check-icon"
              >check</mat-icon
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row section-container">
        <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.KONTAKTDATEN'"></h2>
        <div class="col-12">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline" aria-disabled="true">
            <mat-label [translate]="'STAMMDATEN.EMAIL'"></mat-label>
            <input matInput [value]="EmailControl.value" readonly />
            <mat-icon matSuffix *ngIf="EmailControl.valid" class="check-icon">check</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label [translate]="'Telefonnummer'"></mat-label>
            <input matInput formControlName="telefon" [readonly]="!telefonEditable" />
            <mat-icon matSuffix *ngIf="TelefonControl?.valid && !telefonEditable" class="check-icon">check</mat-icon>
            <mat-icon matSuffix *ngIf="telefonEditable && auth.user.lang === 'odfin'" class="error-icon odfincolor"
              >edit</mat-icon
            >
            <mat-icon matSuffix *ngIf="telefonEditable && auth.user.lang === 'mcc'" class="error-icon">edit</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6 btn-right">
          <button
            *ngIf="auth.user.lang === 'mcc'"
            mat-stroked-button
            [ngClass]="{
              'mat-stroked-button': !telefonEditable,
              'color-change': telefonEditable,
            }"
            color="accent"
            (click)="telefonEditableToggle()"
          >
            {{ (telefonEditable === true ? 'Änderung bestätigen' : 'Telefonnummer ändern') | translate }}
          </button>

          <button
            *ngIf="auth.user.lang === 'odfin'"
            mat-stroked-button
            [ngClass]="{
              'mat-stroked-button': !telefonEditable,
              'odfincolor-change': telefonEditable,
            }"
            color="accent"
            (click)="telefonEditableToggle()"
          >
            {{ (telefonEditable === true ? 'Änderung bestätigen' : 'Telefonnummer ändern') | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="col-xl-10 info-container">
  <!--<div class="line-wrapper">
    <mat-icon style="overflow: inherit" class="info-icon">info</mat-icon>
    <div>
      <span
        appTranslationModal
        [translate]="'STAMMDATEN.MARKIERUNG_INFO'"
      ></span>
      <span><mat-icon class="check-icon ckeckIconHint">check</mat-icon></span>
      <span
        appTranslationModal
        [translate]="'STAMMDATEN.MARKIERUNG_INFO_END'"
      ></span>
    </div>
  </div>-->
  <div class="row">
    <div class="col-12 col-lg-8 line-wrapper">
      <mat-icon *ngIf="auth.user.lang === 'mcc'" class="error-icon" style="overflow: inherit">error</mat-icon>
      <mat-icon *ngIf="auth.user.lang === 'odfin'" class="error-icon odfincolor" style="overflow: inherit"
        >error</mat-icon
      >
      <p appTranslationModal [translate]="'STAMMDATEN.ABWEICHENDEN_STAMMDATEN_INFO'"></p>
    </div>
    <div class="col-12 col-lg-4 btn-right" style="display: flex; justify-content: flex-start">
      <button mat-stroked-button color="accent" (click)="openPopUp()">
        {{ 'STAMMDATEN.ABWEICHUNGEN_MELDEN' | translate }}
      </button>
    </div>
  </div>
</div>

<form class="col-xl-10 card-container mb-3" [formGroup]="answerFormGroup" *ngIf="abweichungenGemeldet">
  <div class="d-flex flex-column">
    <h2 appTranslationModal [translate]="'STAMMDATEN.ABWEICHUNGEN_TITLE'"></h2>
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
          <mat-label [translate]="'STAMMDATEN.FIRMENNAME'"></mat-label>
          <input matInput formControlName="eingetragenerName" readonly />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label [translate]="'STAMMDATEN.TITEL'"></mat-label>
          <input matInput formControlName="titel" readonly />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
          <mat-label [translate]="'STAMMDATEN.VORNAME'"></mat-label>
          <input matInput formControlName="vorname" readonly />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline" [hideRequiredMarker]="true">
          <mat-label [translate]="'STAMMDATEN.NACHNAME'"></mat-label>
          <input matInput formControlName="nachname" readonly />
        </mat-form-field>
      </div>
    </div>
    <div class="col-12">
      <mat-form-field floatLabel="always" class="full-width" appearance="outline">
        <mat-label [translate]="'STAMMDATEN.NACHRICHT'"></mat-label>
        <textarea
          matInput
          formControlName="abweichungNachricht"
          placeholder="Bitte geben Sie Abweichungen ein..."
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-center">
      <div class="text-center p-2 m-1">
        <button type="button" mat-stroked-button (click)="closePopUp()" color="accent">
          {{ 'STAMMDATEN.ABBRECHEN' | translate }}
        </button>
      </div>
      <div class="text-center p-2 m-1">
        <button
          type="button"
          mat-stroked-button
          (click)="abweichungenSenden()"
          color="accent"
          [disabled]="!answerFormGroup.controls['abweichungNachricht'].valid"
        >
          Senden
        </button>
      </div>
    </div>
  </div>
</form>

<form class="col-xl-10 confirm-container" [formGroup]="answerFormGroup">
  <div class="row">
    <div class="col-12">
      <h2 appTranslationModal [translate]="'STAMMDATEN.BESTAETIGUNG_TITLE'"></h2>
      <hr />
      <p appTranslationModal [translate]="'STAMMDATEN.BESTAETIGUNG_TEXT'"></p>
      <div *ngIf="!juriPerson" class="confirm-checkbox">
        <mat-checkbox formControlName="bestätigt" data-cy="natPerson_stammdaten-bestaetigen">
          <p class="text-bold" appTranslationModal [translate]="'STAMMDATEN.BESTAETIGUNG_CHECKBOX_TEXT'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="juriPerson" class="confirm-checkbox">
        <mat-checkbox formControlName="bestätigt_second" data-cy="jurPerson_geschaeftsfuehrer-bestaetigen">
          <p class="text-bold" appTranslationModal [translate]="'STAMMDATEN.BESTAETIGUNG_CHECKBOX_TEXT3'"></p>
        </mat-checkbox>
        <mat-checkbox formControlName="bestätigt" data-cy="jurPerson_stammdaten-bestaetigen">
          <p class="text-bold" appTranslationModal [translate]="'STAMMDATEN.BESTAETIGUNG_CHECKBOX_TEXT'"></p>
        </mat-checkbox>
      </div>
    </div>
  </div>
</form>
