<mat-icon class="clickable help-icon" (click)="openDialog()">help_outline</mat-icon>

<ng-template #infoContent>
  <div class="contant-container">
    <div class="contant-header">
      <div class="close-header">
        <mat-icon (click)="close()" class="clickable" aria-hidden="false" color="accent" aria-label="Close"
          >close</mat-icon
        >
      </div>
      <h2 *ngIf="header" mat-dialog-title>{{ header }}</h2>
    </div>

    <div class="info-container">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
