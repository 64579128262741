<h1>Wartung einstellen</h1>
<p>
  Hier können Sie dem Server eine Wartung hinzufügen, die eine E-Mail an alle Benutzer sendet, und die Anmeldung für die
  festgelegte Zeit blockieren.
</p>

<form #wartungForm="ngForm" class="mb-4">
  <div class="row">
    <div class="col-6">
      <mat-form-field floatLabel="always" class="full-width">
        <mat-label>Tag</mat-label>
        <input matInput [matDatepicker]="picker" [min]="minDate" required [(ngModel)]="day" name="day" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field floatLabel="always" class="full-width">
        <mat-label>Von</mat-label>
        <input matInput placeholder="HH:MM" pattern="\d\d:\d\d" required [(ngModel)]="from" name="from" />
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field floatLabel="always" class="full-width">
        <mat-label>Bis</mat-label>
        <input matInput placeholder="HH:MM" pattern="\d\d:\d\d" required [(ngModel)]="to" name="to" />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-checkbox [(ngModel)]="emails" name="emails">Email an alle kunden Senden?</mat-checkbox>
    </div>
  </div>
</form>
<div mat-dialog-actions class="text-right">
  <button mat-button (click)="cancel()" translate="ADMIN.BUTTON_CANCEL">Cancel</button>
  <button mat-button (click)="save()" translate="ADMIN.BUTTON_OK" [disabled]="wartungForm.invalid">Ok</button>
</div>
