import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { NgClass } from '@angular/common';
import { TranslationDirective } from '../../translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-default-dialog-box',
    templateUrl: './default-dialog-box.component.html',
    styleUrls: ['./default-dialog-box.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        TranslationDirective,
        TranslateModule,
        MatButton,
        MatDialogClose,
    ],
})
export class DefaultDialogBoxComponent {
  imagelang: string;
  headerText: string;
  subText: string;
  confirm: string;
  decline: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService
  ) {
    this.imagelang = this.auth.user.lang;
    this.headerText = this.data.headerText;
    this.subText = this.data.subText;
    this.confirm = this.data.confirm || 'ja';
    this.decline = this.data.decline || 'nein';
  }
}
