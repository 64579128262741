import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';

import { DatePipe, NgFor, NgIf, NgClass } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Rol } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { TranslationLogComponent } from './translation-log/translation-log.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { TranslationDirective } from '../../../component/translata-modal/translation.directive';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { MatIcon } from '@angular/material/icon';

/**
 * Translation Seite
 */
@Component({
    selector: 'app-translation-admin',
    templateUrl: './translation-admin.component.html',
    styleUrls: ['./translation-admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatFormField,
        MatLabel,
        TranslationDirective,
        TranslateModule,
        MatSelect,
        FormsModule,
        NgFor,
        MatOption,
        MatInput,
        NgIf,
        MatButton,
        MatTabGroup,
        NgClass,
        MatTab,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        MatIcon,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
    ],
})
export class TranslationAdminComponent implements OnInit {
  langList: string[];
  selectedLang: string;
  translationArray: Array<any>;
  selectedTranslation: { name: string; value: string };
  hasAdminBB: boolean;
  user: any;
  selectedDate: string;
  isZinsen: boolean;
  constructor(
    private translate: TranslateService,
    private dataService: DataService,
    private notification: NotificationService,
    private authService: AuthService,
    public dialog: MatDialog,
    private datePipe: DatePipe
  ) {
    this.langList = this.translate.getLangs();
    this.selectedTranslation = { name: '', value: '' };
  }

  ngOnInit() {
    this.selectedLang = this.translate.getDefaultLang();
    this.loadLang(this.selectedLang);
    this.authService.checkLogin().subscribe(() => {
      const user = this.authService.user;
      this.user = user;
    });
    this.checkUserAdminBB();
  }

  loadLang(val: string) {
    this.translate.getTranslation(val).subscribe((trans) => {
      this.translationArray = this._parseLangObjToArray(trans);
    });
  }

  /**
   * um die tabelle anzeigen, mussen die daten tranformiert werden.
   * @param langObj translation obj
   */
  private _parseLangObjToArray(langObj: object) {
    const out: { name: string; value: string | any[] }[] = [];

    for (const key in langObj) {
      if (langObj.hasOwnProperty(key)) {
        const element = langObj[key];

        if (Array.isArray(element) || typeof element === 'string') {
          out.push({ name: key.trim(), value: element });
        } else {
          const valueArray = this._parseLangObjToArray(element);
          out.push({ name: key.trim(), value: valueArray });
        }
      }
    }

    return out;
  }

  editTranslation(parentName: string, element: any) {
    this.isZinsen = parentName == 'Zinsen' ? true : false;
    this.selectedTranslation = {
      name: parentName + '.' + element.name,
      value: element.value,
    };
  }

  async deleteTranslation(parentName: string, element: any) {
    const toastSiecher = await this.notification.showInfoTranslate('ADMIN.SIND_SIE_SIECHER_FRAGE', 6000);
    const response = await toastSiecher.afterDismissed().toPromise();

    if (response && !response.dismissedByAction) {
      return;
    }

    const currentTranslation = await this.translate.getTranslation(this.selectedLang).toPromise();

    delete currentTranslation[parentName][element.name];

    if (Object.keys(currentTranslation[parentName]).length === 0) {
      delete currentTranslation[parentName];
    }

    await this.translate.setTranslation(this.selectedLang, currentTranslation, true);

    await this.dataService.setTranslation(this.selectedLang, currentTranslation).toPromise();

    this.loadLang(this.selectedLang);
  }

  // Raul sagt: das ist eine scheiße
  applyFilter(filterValue: string, tabelle) {
    if (!(tabelle.dataSource instanceof MatTableDataSource))
      tabelle.dataSource = new MatTableDataSource(tabelle.dataSource);

    tabelle.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // Translation log beggin
  openTranslationLog() {
    const dialogRef = this.dialog.open(TranslationLogComponent, {
      width: '500rem',
    });
  }

  checkUserAdminBB() {
    if (this.user && this.user.rol === Rol.adminBB) {
      this.hasAdminBB = true;
    }
  }

  checkTranslationArray() {
    if (this.translationArray && this.translationArray.length > 0) {
      return this.translationArray.some((item) => item.name === 'Zinsen' && this.hasAdminBB);
    }
    return false;
  }

  dateInputValid(): boolean {
    const regexPattern = /^\d{4}-\d{2}-\d{2}$/;
    const isValidFormat = regexPattern.test(this.selectedDate);

    return isValidFormat;
  }

  saveTranslationLogs() {
    const key = this.selectedTranslation.name;
    const newValue = this.selectedTranslation.value;
    const date = new Date(this.selectedDate);

    const translation = {
      institute: this.selectedLang,
      key: key,
      newValue: newValue,
      newValueEffectiveFrom: date,
      changedBy: this.user.email,
      confirmedBy: null,
      confirmedOn: null,
      confirmed: false,
      isValueUpdated: false,
    };

    if (this.selectedTranslation.name.indexOf('.') < 0) this.notification.showError('ERROR.TEXT_KEY_NOT_VALID');
    else
      this.translate.reloadLang(this.selectedLang).subscribe((currentTranslation) => {
        if (currentTranslation instanceof Array) currentTranslation = {};
        const parts = key.split('.');
        const beforeDot = parts[0];

        if (beforeDot === 'Zinsen') {
          this.dataService.setTranslationLogs(this.selectedLang, translation).subscribe(() => {
            console.log('neu TranslationLogs');
          });
        } else {
          this.saveTranslation();
        }
      });
  }

  saveTranslation() {
    if (this.selectedTranslation.name.indexOf('.') < 0) this.notification.showError('ERROR.TEXT_KEY_NOT_VALID');
    else
      this.translate.reloadLang(this.selectedLang).subscribe((currentTranslation) => {
        if (currentTranslation instanceof Array) currentTranslation = {};
        this._createJsonTree(currentTranslation, this.selectedTranslation.name, this.selectedTranslation.value.trim());

        this.translate.setTranslation(this.selectedLang, currentTranslation, true);

        this.dataService.setTranslation(this.selectedLang, currentTranslation).subscribe(() => {
          this.selectedTranslation = { name: '', value: '' };

          this.loadLang(this.selectedLang);
        });
      });
  }

  _createJsonTree(obj: any, fullKey: string, value: string) {
    const splitedKey = fullKey.split('.');
    const key = splitedKey.shift() || '';
    if (!obj[key]) obj[key] = {};
    if (splitedKey.length === 0) {
      obj[key] = value;
      return;
    }

    return this._createJsonTree(obj[key], splitedKey.join('.'), value);
  }
}
