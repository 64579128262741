import { saveAs } from 'file-saver';
import { User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { ZusammenfassungComponent } from '../../../pages/questionnaire/questions/zusammenfassung/zusammenfassung.component';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';

@Component({
    selector: 'app-user-detail-admin',
    templateUrl: './user-detail-admin.component.html',
    styleUrls: ['./user-detail-admin.component.scss'],
    standalone: true,
    imports: [
        MatButton,
        MatTabGroup,
        MatTab,
        MatTabContent,
        NgIf,
        ZusammenfassungComponent,
        QuestionAnswerComponent,
    ],
})
export class UserDetailAdminComponent implements OnInit {
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  selectedUser: User;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.adminService.getUserById(data['userId']).subscribe((user) => {
        this.selectedUser = user;
      });
    });
  }

  downloadUserZip() {
    this.adminService.getUserZipData(this.selectedUser._id).subscribe((res) => {
      saveAs(res, `${this.selectedUser.name}_${this.selectedUser.lastname}.zip`);
    });
  }

  priveSend() {
    this.adminService.setPriveUser(this.selectedUser._id).subscribe((res) => {
      this.notification.showSuccessTranslate('ADMIN.KONTO_AN_PRIVE_GESENDET');
    });
  }
}
