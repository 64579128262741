import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Question } from '../models/question';
import { User } from '../models/user';
import { QuestionTemplate } from '../pages/questionnaire/questions/question-template';
import { AuthService } from './auth.service';

class QuestionData {
  route: string;
  component?: QuestionTemplate;
  stepPage: boolean;
  showHeader?: boolean;
  showIconInButton?: boolean;
  nextButtonTitle: string;
  description?: { title: string; text: string };
  imagePath?: string;
  step?: number;
}

/**
 * Service mit die config und funktionalität für die fragewogen.
 * Hier sind die route von jede frage, mit ten title und text die in die web auf die rechte seite angezeigt wird.
 * Diese title und hilfe text kann mann finden in der questionnnaire component.
 */
@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  questionIndex: number;
  questionList: Array<QuestionData>;
  private observable: Observable<QuestionData>;
  private subscriber: Subscriber<QuestionData>;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private auth: AuthService
  ) {
    this.questionIndex = 0;
    this.questionList = new Array(
      {
        route: 'overview',
        stepPage: false,
        showHeader: false,
        showIconInButton: false,
        nextButtonTitle: '',
        imagePath: 'assets/img/overview-mainImage',
      },
      {
        route: 'bank-wahl',
        stepPage: true,
        showHeader: true,
        showIconInButton: true,
        nextButtonTitle: 'Nächster Schritt – Bestätigung Stammdaten',
        description: {
          title: '',
          text: this.translate.instant('PRODUKTAUSWAHL.INFO_TEXT'),
        },
        step: 0,
      },
      {
        route: 'zusammenfassung',
        stepPage: true,
        showHeader: true,
        showIconInButton: true,
        nextButtonTitle: 'Nächster Schritt - Rechtliche Angaben',
        imagePath: 'assets/img/stammdaten',
        step: 1,
      },
      {
        route: 'dokumente',
        stepPage: true,
        showHeader: true,
        showIconInButton: true,
        nextButtonTitle: 'Nächster Schritt - Rechtliche Angaben',
        imagePath: 'assets/img/stammdaten',
        step: 1,
      },
      {
        route: 'blanko-dokumente',
        stepPage: true,
        showHeader: true,
        showIconInButton: true,
        nextButtonTitle: 'Nächster Schritt - Geschäftskonto eröffnen',
        imagePath: 'assets/img/rechtliches',
        step: 1,
      },
      {
        route: 'einsicht',
        stepPage: true,
        showHeader: true,
        showIconInButton: false,
        nextButtonTitle: 'Jetzt Geschäftskonto eröffnen',
        step: 2,
      },
      {
        route: 'identifizierung',
        stepPage: false,
        showHeader: true,
        showIconInButton: false,
        nextButtonTitle: 'Gleich weiter zum Dashboard',
        imagePath: 'assets/img/abschluss',
        step: 3,
      }
    );
    this.observable = new Observable((subscriber) => {
      this.subscriber = subscriber;
    });
  }

  initJurPerson() {
    if (!this.auth.isJurPerson()) {
      this.questionList = this.questionList.filter((item) => item.route !== 'dokumente');
    }
  }
  /**
   * load question
   * @param route route name
   * @param component component kind zu laden
   */
  setComponent(route: string, component: QuestionTemplate) {
    this.initJurPerson();
    const found = this.questionList.find((item, index) => {
      if (item.route === route) this.questionIndex = index;
      return item.route === route;
    });
    if (found) found.component = component;

    if (this.subscriber) {
      this.subscriber.next(found);
      this.subscriber.complete();
    }
  }

  async getQuestionObjByRoute(route: string) {
    this.initJurPerson();
    await this.observable.toPromise();
    return this.questionList.find((q) => q.route === route);
  }

  getQuestionIndexByRoute(route: string) {
    this.initJurPerson();
    return this.questionList.findIndex((q) => q.route === route);
  }

  async getQuestionObj(index: number) {
    this.initJurPerson();
    if (index < this.questionList.length) {
      await this.observable.toPromise();
      return this.questionList[index];
    } else throw Error();
  }

  getComponent(index: number) {
    this.initJurPerson();
    return this.questionList[index].component;
  }

  getRouteName(index: number) {
    this.initJurPerson();
    if (index < this.questionList.length) return this.questionList[index].route;
    else return null;
  }

  saveQuestions(userId, userquestions) {
    return this.http.post<User>(
      `${environment.apiURL}/user/questionaire`,
      { userid: userId, userquestions: userquestions },
      { withCredentials: true }
    );
  }

  getLastQuestion(userQuestionList: Question[]): QuestionData {
    this.initJurPerson();
    let last = this.questionList[0];
    this.questionList.forEach((element, index) => {
      for (const question of userQuestionList) {
        if (question.name === this.questionList[index].route) {
          last = this.questionList[index + 1];
        }
      }
    });
    return last;
  }
}
