import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

/**
 * Service die zeigt notification/alerts
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    public snackBar: MatSnackBar,
    public translate: TranslateService
  ) {}

  showSuccess(message: string, duration?: number) {
    return this.snackBar.open(message, 'Ok', {
      panelClass: ['success-toast'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: duration || 3000,
    });
  }

  async showSuccessTranslate(key: string, duration?: number) {
    const message = await this.translate.get(key).toPromise();
    return this.showSuccess(message, duration);
  }

  showInfo(message: string, duration?: number) {
    return this.snackBar.open(message, 'Ok', {
      panelClass: ['info-toast'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: duration || 3000,
    });
  }

  async showInfoTranslate(key: string, duration?: number) {
    const text = await this.translate.get(key).toPromise();
    return this.showInfo(text, duration);
  }

  async showError(message: string, duration?: number) {
    const text = await this.translate.get(message).toPromise();
    return this.snackBar.open(text, 'X', {
      panelClass: ['error-toast'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: duration || 6000,
    });
  }

  async showErrorTranslate(key: string, duration?: number) {
    const text = await this.translate.get(key).toPromise();
    return this.showError(text, duration);
  }
}
