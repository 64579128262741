import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DefaultDialogBoxComponent } from 'src/app/component/dialog-boxes/default-dialog-box/default-dialog-box.component';
import { Status } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { QuestionService } from 'src/app/services/question.service';
import { Land } from 'src/app/types/mappingEvaDifa';
import { environment } from 'src/environments/environment';
import { QuestionTemplate } from '../question-template';
import { TranslationDirective } from '../../../../component/translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgClass } from '@angular/common';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { InfoCardComponent } from '../../../../component/info-card/info-card.component';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'app-zusammenfassung',
    templateUrl: './zusammenfassung.component.html',
    styleUrls: ['./zusammenfassung.component.scss'],
    standalone: true,
    imports: [
        TranslationDirective,
        TranslateModule,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatIcon,
        MatSuffix,
        MatButton,
        NgClass,
        InfoCardComponent,
        MatCheckbox,
    ],
})
export class ZusammenfassungComponent extends QuestionTemplate implements OnInit {
  override answerFormGroup: FormGroup;
  abweichendeAdresse: Boolean;
  telefonEditable: Boolean;
  steuerIdEditable: Boolean;
  abweichungenGemeldet: Boolean;
  juriPerson: boolean = false;

  rechtsformAuspraegungControl: FormControl;
  PersonenartControl: FormControl;
  KundenartControl: FormControl;
  AnredeControl: FormControl;
  BriefanredeControl: FormControl;
  NamePraegekarteControl: FormControl;
  NamePraegekarte2Control: FormControl;
  FirmennameControl: FormControl;
  KurznameControl: FormControl;
  GbrArtControl: FormControl;
  regGeprueftAmControl: FormControl;
  registerartControl: FormControl;
  registergerichtControl: FormControl;
  registernummerControl: FormControl;
  eintragDatumControl: FormControl;
  regAuszugDatumControl: FormControl;
  regGeprueftVonControl: FormControl;
  TelefonControl: FormControl;
  EmailControl: FormControl;

  constructor(
    public override auth: AuthService,
    public override questionService: QuestionService,
    public override route: ActivatedRoute,
    public admin: AdminService,
    public dialog: MatDialog
  ) {
    super(auth, questionService, route);
    this.questionName = 'zusammenfassung';
  }

  override ngOnInit() {
    super.ngOnInit();
    this._initAnswerFormGroup();
    this._initUserData();
    this.juriPerson = this.auth.isJurPerson();
    if (this.juriPerson) {
      this._initJuriPerson();
      this.getJuriPersonValue();
    }
  }

  _initAnswerFormGroup() {
    this.answerFormGroup = new FormGroup({
      eingetragenerName: new FormControl(null, [Validators.required]),
      anrede: new FormControl(null, [Validators.required]),
      titel: new FormControl(null),
      vorname: new FormControl(null, [Validators.required]),
      nachname: new FormControl(null, [Validators.required]),
      geburtsdatum: new FormControl(null, [Validators.required]),
      nationalität: new FormControl(null, [Validators.required]),
      strasseMitHausnummer: new FormControl(null, [Validators.required]),
      postleitzahl: new FormControl(null, [Validators.required]),
      ort: new FormControl(null, [Validators.required]),
      land: new FormControl(null, [Validators.required]),
      ausweisnummer: new FormControl(null, [Validators.required]),
      Steuernummer: new FormControl(null),
      ausgestelltAm: new FormControl(null, [Validators.required]),
      gültigBis: new FormControl(null, [Validators.required]),
      ausweisart: new FormControl(null, [Validators.required]),
      auszustellendeBehörde: new FormControl(null, [Validators.required]),
      telefon: new FormControl(null, [Validators.required, Validators.pattern('[0-9()+/\\-\\s]+')]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      steuerId: new FormControl(''),
      bestätigt: new FormControl(false, [Validators.requiredTrue]),
      bestätigt_second: new FormControl(false),
      abweichungNachricht: new FormControl(null),
      meldeadresseStrasse: new FormControl(null),
      meldeadressePLZ: new FormControl(null),
      meldeadresseOrt: new FormControl(null),
      meldeadresseLand: new FormControl(null),
      //JurPerson FormGroup
      JurPerson: new FormGroup({
        JurDaten: new FormGroup({
          Personenart: new FormControl(null),
          Kundenart: new FormControl(null),
          Anrede: new FormControl(null),
          Briefanrede: new FormControl(null),
          NamePraegekarte: new FormControl(null),
          NamePraegekarte2: new FormControl(null),
          Firmenname: new FormControl(null),
          Kurzname: new FormControl(null),
          RechtsformAuspraegung: new FormControl(null),
          GbrArt: new FormControl(null),
        }),
        Registerdaten: new FormGroup({
          Registerart: new FormControl(null),
          Registergericht: new FormControl(null),
          Registernummer: new FormControl(null),
          EintragDatum: new FormControl(null),
          RegAuszugDatum: new FormControl(null),
          RegGeprueftVon: new FormControl(null),
          RegGeprueftAm: new FormControl(null),
        }),
        Adresse: new FormControl(null),
        Kontaktdaten: new FormGroup({
          Kontakt: new FormGroup({
            Telefon: new FormControl(null),
            Email: new FormControl(null),
          }),
        }),
      }),
    });

    if (this.auth.isJurPerson()) {
      this.answerFormGroup.controls['bestätigt'].setValidators([Validators.requiredTrue]);
      this.answerFormGroup.get('JurPerson.JurDaten.RechtsformAuspraegung')?.setValidators([Validators.required]);
      this.answerFormGroup.controls['bestätigt_second'].setValidators([Validators.requiredTrue]);
    }
  }

  telefonEditableToggle() {
    this.telefonEditable = this.telefonEditable ? false : true;
  }

  steuerIdEditableToggle() {
    this.steuerIdEditable = this.steuerIdEditable ? false : true;
  }

  openPopUp() {
    this.abweichungenGemeldet = !this.abweichungenGemeldet;
  }

  closePopUp() {
    this.abweichungenGemeldet = false;
  }

  abweichungenSenden() {
    this.dialog
      .open(DefaultDialogBoxComponent, {
        data: {
          headerText: 'STAMMDATEN.ABWEICHUNGEN_POPUP_TEXT',
          confirm: 'okay',
          decline: 'zurück',
        },
      })
      .afterClosed()
      .subscribe(async (answer) => {
        if (answer == true) {
          this.admin
            .sendAbweichungEmail(this.auth.user._id, this.answerFormGroup.controls['abweichungNachricht'].value)
            .subscribe(() => {
              this.auth.changeStatus(this.auth.user._id, Status.inactive).subscribe(() => {
                window.location.href = environment.oneDashboardURL;
              });
            });
        }
      });
    this.closePopUp();
  }

  private _initUserData() {
    const formatDateString = (dateString: String) => {
      const parts = dateString.split('-');
      return parts.reverse().join('.');
    };

    this.telefonEditable = false;
    this.abweichendeAdresse = !this.isabweichendeAdresse();

    const user = this.auth.user.natPersonen[this.auth.index];
    const person = user.Person;
    const legitimationsdaten = user.Legitimationsdaten;
    const kontakt = user.Kontakt;
    const address = this.abweichendeAdresse ? user.Firmenanschrift : user.Adresse;
    const controls = this.answerFormGroup.controls;
    controls['eingetragenerName'].setValue(user.Firmenname);
    controls['anrede'].setValue(person.Anrede);
    controls['titel'].setValue(person.Titel);
    controls['vorname'].setValue(person.Vorname);
    controls['nachname'].setValue(person.Nachname);
    controls['geburtsdatum'].setValue(formatDateString(person.Geburtsdatum));
    controls['nationalität'].setValue(Land.get(legitimationsdaten.AusstellerLand));

    controls['strasseMitHausnummer'].setValue(address.Strasse);
    controls['meldeadresseStrasse'].setValue(user.Adresse.Strasse);
    controls['meldeadressePLZ'].setValue(user.Adresse.PLZ);
    controls['meldeadresseOrt'].setValue(user.Adresse.Ort);
    controls['meldeadresseLand'].setValue(Land.get(user.Adresse.Land));
    controls['postleitzahl'].setValue(address.PLZ);
    controls['ort'].setValue(address.Ort);
    controls['land'].setValue(Land.get(address.Land));

    controls['ausweisnummer'].setValue(legitimationsdaten.Ausweisnummer);
    controls['Steuernummer'].setValue(user.SteuerID);
    controls['ausgestelltAm'].setValue(formatDateString(legitimationsdaten.AusgestelltAm));
    controls['gültigBis'].setValue(formatDateString(legitimationsdaten.GueltigBis));
    controls['ausweisart'].setValue(legitimationsdaten.Ausweisart);
    controls['auszustellendeBehörde'].setValue(legitimationsdaten.AusstellerBehoerde);
    controls['telefon'].setValue(kontakt.Telefon);
    controls['email'].setValue(kontakt.Email);
    controls['steuerId'].setValue(user.SteuerID);
  }

  getControl(formGroup: FormGroup, controlName: string): AbstractControl | null {
    return formGroup.get(controlName);
  }

  _initJuriPerson() {
    const jurPerson = this.auth.user.JurPerson;
    const jurPersonControls = this.answerFormGroup.get('JurPerson') as FormGroup;
    const jurDatenControls = this.getControl(jurPersonControls, 'JurDaten') as FormGroup;
    const registerdatenControls = this.getControl(jurPersonControls, 'Registerdaten') as FormGroup;
    const Kontaktdaten = this.getControl(jurPersonControls, 'Kontaktdaten') as FormGroup;
    const Kontakt = this.getControl(Kontaktdaten, 'Kontakt') as FormGroup;
    const telefon = jurPerson.Kontaktdaten.Kontakt.Telefon;
    const email = jurPerson.Kontaktdaten.Kontakt.Email;
    const Dokumente = this.getControl(jurPersonControls, 'Dokumente') as FormGroup;

    for (const key in jurPerson.JurDaten) {
      if (jurDatenControls.get(key)) {
        jurDatenControls.get(key)?.setValue(jurPerson.JurDaten[key]);
      }
    }

    for (const key in jurPerson.Registerdaten) {
      if (registerdatenControls.get(key)) {
        registerdatenControls.get(key)?.setValue(jurPerson.Registerdaten[key]);
      }
    }

    Kontakt.get('Telefon')?.setValue(telefon);
    Kontakt.get('Email')?.setValue(email);
  }

  getJuriPersonValue() {
    this.rechtsformAuspraegungControl = this.answerFormGroup.get(
      'JurPerson.JurDaten.RechtsformAuspraegung'
    ) as FormControl;

    this.PersonenartControl = this.answerFormGroup.get('JurPerson.JurDaten.Personenart') as FormControl;

    this.KundenartControl = this.answerFormGroup.get('JurPerson.JurDaten.Kundenart') as FormControl;

    this.AnredeControl = this.answerFormGroup.get('JurPerson.JurDaten.Anrede') as FormControl;

    this.BriefanredeControl = this.answerFormGroup.get('JurPerson.JurDaten.Briefanrede') as FormControl;

    this.NamePraegekarteControl = this.answerFormGroup.get('JurPerson.JurDaten.NamePraegekarte') as FormControl;

    this.NamePraegekarte2Control = this.answerFormGroup.get('JurPerson.JurDaten.NamePraegekarte2') as FormControl;

    this.FirmennameControl = this.answerFormGroup.get('JurPerson.JurDaten.Firmenname') as FormControl;

    this.KurznameControl = this.answerFormGroup.get('JurPerson.JurDaten.Kurzname') as FormControl;

    this.GbrArtControl = this.answerFormGroup.get('JurPerson.JurDaten.GbrArt') as FormControl;

    this.registerartControl = this.answerFormGroup.get('JurPerson.Registerdaten.Registerart') as FormControl;

    this.registergerichtControl = this.answerFormGroup.get('JurPerson.Registerdaten.Registergericht') as FormControl;

    this.registernummerControl = this.answerFormGroup.get('JurPerson.Registerdaten.Registernummer') as FormControl;

    this.eintragDatumControl = this.answerFormGroup.get('JurPerson.Registerdaten.EintragDatum') as FormControl;

    this.regAuszugDatumControl = this.answerFormGroup.get('JurPerson.Registerdaten.RegAuszugDatum') as FormControl;

    this.regGeprueftVonControl = this.answerFormGroup.get('JurPerson.Registerdaten.RegGeprueftVon') as FormControl;

    this.regGeprueftAmControl = this.answerFormGroup.get('JurPerson.Registerdaten.RegGeprueftAm') as FormControl;

    this.TelefonControl = this.answerFormGroup.get('JurPerson.Kontaktdaten.Kontakt.Telefon') as FormControl;

    this.EmailControl = this.answerFormGroup.get('JurPerson.Kontaktdaten.Kontakt.Email') as FormControl;

    //this.setValidatorsJuriPerson();
  }

  setValidatorsJuriPerson() {
    const controlKeys = [
      'RechtsformAuspraegung',
      'Personenart',
      'Kundenart',
      'Anrede',
      'Briefanrede',
      'NamePraegekarte',
      'NamePraegekarte2',
      'Firmenname',
      'Kurzname',
      'GbrArt',
      'Registerart',
      'Registergericht',
      'Registernummer',
      'EintragDatum',
      'RegAuszugDatum',
      'RegGeprueftVon',
      'RegGeprueftAm',
      'Telefon',
      'Email',
    ];

    controlKeys.forEach((key) => {
      const control = this.answerFormGroup.get(`JurPerson.JurDaten.${key}`) as FormControl;
      if (control) {
        control.setValidators([Validators.required]);
        control.updateValueAndValidity();
      }
    });
  }

  isabweichendeAdresse() {
    const natPerson = this.auth.user.natPersonen[this.auth.index];
    var Adresse = natPerson.Adresse;
    var Firmenanschrift = natPerson.Firmenanschrift;
    if (natPerson.Firmenanschrift.Strasse == '') {
      return false;
    }

    for (const element in Adresse) {
      if (Adresse[element] !== Firmenanschrift[element]) {
        return false;
      }
    }

    return true;
  }
}
