import { Email } from 'src/app/models/email';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';

import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslationDirective } from '../../../component/translata-modal/translation.directive';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { QuillEditorComponent } from 'ngx-quill';

/**
 * Emails seite
 */
@Component({
    selector: 'app-emails',
    templateUrl: './emails.component.html',
    styleUrls: ['./emails.component.scss'],
    standalone: true,
    imports: [
        MatFormField,
        MatInput,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        MatIconButton,
        MatIcon,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatLabel,
        TranslationDirective,
        TranslateModule,
        MatSelect,
        FormsModule,
        NgFor,
        MatOption,
        QuillEditorComponent,
        MatButton,
    ],
})
export class EmailsComponent implements OnInit {
  langList: string[];
  emailList: Email[];
  dataSource: MatTableDataSource<Email>;
  selectedEmail: Email;

  // buttons in text editor
  public defaultModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],

      ['clean'],
      ['link'],
    ],
  };

  constructor(
    private translate: TranslateService,
    private adminService: AdminService,
    private notification: NotificationService
  ) {
    this.langList = this.translate.getLangs();
  }

  ngOnInit() {
    this.selectedEmail = new Email();
    this.adminService.getEmailsList().subscribe((list) => {
      this.emailList = list;
      this.dataSource = new MatTableDataSource(this.emailList);
    });
  }

  editEmail(email: Email) {
    this.selectedEmail = email;
  }

  /**
   *
   * @param filterValue text zum suchen
   * @param tabelle tabelle wo suchen
   */
  applyFilter(filterValue: string, tabelle) {
    if (!(tabelle.dataSource instanceof MatTableDataSource))
      tabelle.dataSource = new MatTableDataSource(tabelle.dataSource);

    tabelle.dataSource.filter = filterValue.trim().toLowerCase();
  }

  saveEmail() {
    if (this.selectedEmail.lang && this.selectedEmail.function && this.selectedEmail.subject)
      this.adminService.setEmail(this.selectedEmail).subscribe((a) => {
        this.notification.showSuccess('EMAIL.EMAIL_SAVED');
        this.adminService.getEmailsList().subscribe((list) => {
          this.emailList = list;
          this.dataSource = new MatTableDataSource(this.emailList);
        });
        this.selectedEmail = new Email();
      });
  }
}
