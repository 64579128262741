import { DatePipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-translation-log',
    templateUrl: './translation-log.component.html',
    styleUrls: ['./translation-log.component.scss'],
    standalone: true,
    imports: [
        CdkScrollable,
        MatDialogContent,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        NgIf,
        MatIcon,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ],
})
export class TranslationLogComponent implements OnInit {
  displayedColumns: string[] = ['institute', 'key', 'text', 'geändertVon', 'geprüftVon', 'geprüftAm', 'buttons'];
  translationLogs: any[] = [];
  user: any;
  selectedLang: string;
  selectedTranslation: { name: string; value: string };
  translationArray: Array<any>;
  constructor(
    private translate: TranslateService,
    private dataService: DataService,
    private notification: NotificationService,
    private authService: AuthService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.authService.checkLogin().subscribe(() => {
      const user = this.authService.user;
      this.user = user;
    });
    this.getTranslationLogs().subscribe((translationLogs: any) => {
      this.translationLogs = translationLogs;
    });
  }

  confirmTranslation(index: number) {
    const selectedLog = this.translationLogs[index];

    const confirmedTranslation = {
      key: selectedLog.key,
      newValue: selectedLog.newValue,
      institute: selectedLog.institute,
      changedBy: selectedLog.changedBy,
      confirmedBy: this.user.email,
      confirmedOn: new Date(),
      confirmed: true,
      isValueUpdated: false,
      newValueEffectiveFrom: selectedLog.date,
      id: selectedLog._id,
    };

    this.dataService.updateTranslationLog(confirmedTranslation).subscribe(() => {
      this.translationLogs[index] = confirmedTranslation;
      this.getTranslationLogs().subscribe((translationLogs: any) => {
        this.translationLogs = translationLogs;
      });
    });
  }

  rejectTranslation(index: number) {
    const selectedLog = this.translationLogs[index];

    const rejectedTranslation = {
      key: selectedLog.key,
      newValue: selectedLog.newValue,
      institute: selectedLog.institute,
      changedBy: selectedLog.changedBy,
      confirmedBy: this.user.email,
      confirmedOn: new Date(),
      confirmed: false,
      isValueUpdated: false,
      newValueEffectiveFrom: selectedLog.date,
      id: selectedLog._id,
    };

    this.dataService.updateTranslationLog(rejectedTranslation).subscribe(() => {
      this.translationLogs[index] = rejectedTranslation;
      this.getTranslationLogs().subscribe((translationLogs: any) => {
        this.translationLogs = translationLogs;
      });
    });
  }

  getTranslationLogs() {
    return this.http.get(`${environment.apiURL}/lang/translationLogs/all`);
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd')!;
  }
}
