import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { Rol } from '../models/user';

import { AuthService } from '../services/auth.service';
import { MantinancePopUpComponent } from './mantinance-pop-up/mantinance-pop-up.component';
import { MatToolbar } from '@angular/material/toolbar';
import { MatAnchor, MatIconButton } from '@angular/material/button';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';

/**
 * Seite ramen von den admin bereich
 */
@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatAnchor,
        RouterLink,
        NgIf,
        RouterLinkActive,
        MatMenu,
        MatMenuItem,
        TranslateModule,
        MatDivider,
        MatIconButton,
        MatMenuTrigger,
        MatIcon,
        RouterOutlet,
    ],
})
export class AdminComponent {
  isAdmin: Boolean;
  isAdminBB: boolean;
  isClient: boolean;
  constructor(
    private auth: AuthService,
    public dialog: MatDialog,
    private keycloackService: KeycloakService
  ) {
    this.isClient = this.auth.user.rol === Rol.client;
    this.isAdminBB = this.auth.user.rol === Rol.adminBB;
    this.isAdmin = this.auth.user.rol == Rol.admin;
  }

  logout() {
    window.sessionStorage.clear();
    this.keycloackService.logout();
  }

  showMaintinanceModal() {
    const dialogRef = this.dialog.open(MantinancePopUpComponent, {
      maxWidth: 500,
    });
  }
}
