import { Rol } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

import { Directive, ElementRef, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { TranslateModalComponent } from './translate-modal.component';

/**
 * Beim dopple click mach die translate modal component auf
 */
@Directive({
    selector: '[appTranslationModal]',
    standalone: true,
})
export class TranslationDirective {
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private el: ElementRef,
    private sanitizer: DomSanitizer
  ) {}

  @Input('translate') pathTranslate: string;
  @Input('appTranslationModal') pathTranslation: string;

  @HostListener('dblclick')
  ondblclick() {
    const path = this.pathTranslate || this.pathTranslation;
    this.authService.checkLogin().subscribe(() => {
      const user = this.authService.user;
      if (user && user.rol === Rol.adminBB) {
        const dialogRef = this.dialog.open(TranslateModalComponent, {
          data: {
            translationKey: path,
          },
        });
      }
    });
  }
}
