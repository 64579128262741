<div class="container-fluid">
  <div class="row">
    <div class="col-10 mb-5 mx-auto">
      <app-depot-bank-wahl [user]="user" #bankWahl></app-depot-bank-wahl>
      <button mat-raised-button class="mt-3" (click)="saveUser(bankWahl)" color="primary">
        {{ 'ADMIN.BUTTON_SAVE' | translate }}
      </button>
    </div>
  </div>
</div>
