<div class="card-container">
  <div class="row">
    <div class="col-3">
      <h3>Allgemein</h3>
      <div [formGroup]="allgemeinFormGroup">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Mandatref</mat-label><input matInput formControlName="Mandatref"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Mandatkennung</mat-label><input matInput formControlName="Mandatkennung"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Firmenname</mat-label><input matInput formControlName="Firmenname"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Rechtsform</mat-label><input matInput formControlName="Rechtsform"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>CrefoID</mat-label><input matInput formControlName="CrefoID"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>SteuerID</mat-label>
          <div class="line-wrapper">
            <input matInput formControlName="SteuerID" />
            <app-info-card>
              minLength: 11 <br />
              maxLength: 11 <br />
              Eine SteuerID darf nicht mit einer 0 <br />
              beginnen. Ebenfalls muss sich eine der ersten 10 Ziffern einmal wiederholen! Eine Überprüfung der
              Prüfziffer erfolgt nicht.
            </app-info-card>
          </div>
        </mat-form-field>
      </div>
      <div [formGroup]="BrancheInfoGroup">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>BrancheInfoFachgebiet</mat-label><input matInput formControlName="Fachgebiet" />
        </mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>BrancheInfoArt</mat-label><input matInput formControlName="Art"
        /></mat-form-field>
      </div>
      <h3>Image World</h3>
      <div [formGroup]="ImagelangFormGroup">
        <mat-radio-group formControlName="imagelang">
          <mat-radio-button value="mcc" class="pe-2">MCC</mat-radio-button>
          <!-- <mat-radio-button value="odfin" class="ps-2">Odfin</mat-radio-button> -->
        </mat-radio-group>
      </div>
    </div>
    <div class="col-3" [formGroup]="PersonFormGroup">
      <h3>PersonDaten</h3>
      <div class="row">
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label>Personenart </mat-label><input matInput formControlName="Personenart" readonly
          /></mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label>Kundenart </mat-label><input matInput formControlName="Kundenart" readonly
          /></mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label>Anrede </mat-label><input matInput formControlName="Anrede"
          /></mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label>Titel </mat-label><input matInput formControlName="Titel"
          /></mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label>Vorname </mat-label><input matInput formControlName="Vorname"
          /></mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field floatLabel="always" class="full-width" appearance="outline">
            <mat-label>Nachname </mat-label><input matInput formControlName="Nachname"
          /></mat-form-field>
        </div>
      </div>

      <mat-form-field floatLabel="always" class="full-width" appearance="outline">
        <mat-label>Briefanrede </mat-label><input matInput formControlName="Briefanrede"
      /></mat-form-field>
      <mat-form-field floatLabel="always" class="full-width" appearance="outline">
        <mat-label>Geburtsname </mat-label><input matInput formControlName="Geburtsname"
      /></mat-form-field>
      <mat-form-field floatLabel="always" class="full-width" appearance="outline">
        <mat-label>Geburtsdatum </mat-label><input matInput formControlName="Geburtsdatum"
      /></mat-form-field>
      <mat-form-field floatLabel="always" class="full-width" appearance="outline">
        <mat-label>Geburtsort </mat-label><input matInput formControlName="Geburtsort"
      /></mat-form-field>
      <mat-form-field floatLabel="always" class="full-width" appearance="outline">
        <mat-label>Geburtsland </mat-label><input matInput formControlName="Geburtsland"
      /></mat-form-field>
      <div class="line-wrapper">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>RechtsformAuspraegung </mat-label>

          <input matInput formControlName="RechtsformAuspraegung" readonly />
        </mat-form-field>
        <app-info-card>
          Enum [ Freiberufler(in) m.überw.gesch.Charakter, Sonst.wirtsch.selbst.Privatpers.m.überw.gesch.Ch.,
          Freiberufler(in) m. überw. privatem Charakter, Sonst.wirtsch.selbst.Privatp.m.überw.priv.Char.,
          Hausfrau/-mann, Kind, Person mit unbekanntem Beruf, Schüler(in), Student(in), Auszubildende(r), Person
          m.Eink.überw.a.Vermögen, Sonstige Vereinigung von Privatpersonen, Selbst.Handwerker(in)
          m.Eintr.i.d.Handwerksrolle(gesch), Selbst.Handwerker(in) m.Eintr.i.d.Handwerksrolle(priv.), Beamte(r),
          Angestellte(r), Arbeiter(in), Leitende(r) Beamte(r), Leitende(r) Angestellte(r), Arbeitslose(r)
          Arbeitnehmer(in), Pensionär(in), Rentner(in) ]
        </app-info-card>
      </div>
    </div>

    <div class="col-3">
      <h3>Legitimationsdaten</h3>
      <div [formGroup]="LegiFormGroup">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Ausweisart </mat-label><input matInput formControlName="Ausweisart"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Ausweisnummer </mat-label><input matInput formControlName="Ausweisnummer"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>AusstellerLand </mat-label><input matInput formControlName="AusstellerLand"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>AusstellerBehoerde </mat-label><input matInput formControlName="AusstellerBehoerde"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>AusgestelltAm </mat-label><input matInput formControlName="AusgestelltAm"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>GueltigBis </mat-label><input matInput formControlName="GueltigBis"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>GeprueftAm </mat-label><input matInput formControlName="GeprueftAm"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>GeprueftVonID </mat-label><input matInput formControlName="GeprueftVonID" readonly
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>AusfOrganisation </mat-label><input matInput formControlName="AusfOrganisation" readonly
        /></mat-form-field>
      </div>
      <div [formGroup]="DokuFormGroup">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Ausweiskopie </mat-label><input matInput formControlName="Ausweiskopie" readonly
        /></mat-form-field>
      </div>
    </div>
    <div class="col-3">
      <h3>Firmenanschrift</h3>
      <div [formGroup]="AddresseFormGroup">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Strasse </mat-label><input matInput formControlName="Strasse"
        /></mat-form-field>
        <div class="row">
          <div class="col-6">
            <mat-form-field floatLabel="always" class="full-width" appearance="outline">
              <mat-label>PLZ </mat-label><input matInput formControlName="PLZ"
            /></mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field floatLabel="always" class="full-width" appearance="outline">
              <mat-label>Ort </mat-label><input matInput formControlName="Ort"
            /></mat-form-field>
          </div>
        </div>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Land </mat-label><input matInput formControlName="Land"
        /></mat-form-field>
      </div>
      <h3>Abw. Firmenanschrift</h3>
      <div [formGroup]="AbwAddresseFormGroup">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Strasse Abw.</mat-label><input matInput formControlName="Strasse"
        /></mat-form-field>
        <div class="row">
          <div class="col-6">
            <mat-form-field floatLabel="always" class="full-width" appearance="outline">
              <mat-label>PLZ Abw.</mat-label><input matInput formControlName="PLZ"
            /></mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field floatLabel="always" class="full-width" appearance="outline">
              <mat-label>Ort Abw.</mat-label><input matInput formControlName="Ort"
            /></mat-form-field>
          </div>
        </div>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Land Abw.</mat-label><input matInput formControlName="Land"
        /></mat-form-field>
      </div>
      <h3>Kontakt</h3>
      <div [formGroup]="KontaktFormGroup">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Telefon </mat-label><input matInput formControlName="Telefon"
        /></mat-form-field>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Email</mat-label><input matInput formControlName="Email"
        /></mat-form-field>
      </div>
      <button
        style="width: 100%"
        mat-raised-button
        (click)="importUser()"
        color="primary"
        [disabled]="
          !allgemeinFormGroup.valid ||
          !BrancheInfoGroup.valid ||
          !ImagelangFormGroup.valid ||
          !PersonFormGroup.valid ||
          !LegiFormGroup.valid ||
          !DokuFormGroup.valid ||
          !AddresseFormGroup.valid ||
          !AbwAddresseFormGroup.valid ||
          !KontaktFormGroup.valid
        "
      >
        Import User
      </button>
    </div>
  </div>
  <div class="row pt-3">
    <textarea cols="50" style="min-height: 10rem">
      {{ report }}
      </textarea
    >
  </div>
</div>
