import { Rol, User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DateTime } from 'luxon';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import { Router } from '@angular/router';

import { FormControl, FormGroup, Validators, FormsModule } from '@angular/forms';
import { CreditCheckDialogComponent } from 'src/app/component/dialog-boxes/credit-check-dialog/credit-check-dialog.component';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, NgFor, NgClass, TitleCasePipe, DatePipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { TranslationDirective } from '../../../component/translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

/**
 * Seite mit die benutzer liste
 */
@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    NgIf,
    MatButton,
    TranslationDirective,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    FormsModule,
    TranslateModule,
    MatSelect,
    NgFor,
    MatOption,
    MatTooltip,
    NgClass,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    MatProgressSpinner,
    TitleCasePipe,
    DatePipe,
  ],
})
export class UserAdminComponent implements OnInit {
  public data: any[];
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'name',
    'customInfo',
    'Firmenname',
    'RechtsformAuspraegung',
    'bank',
    'status',
    'subStatus',
    'rol',
    'email',
    'createAt',
    'lastLogin',
    'products',
    'daysInSys',
    'creditCheck',
    'buttons',
  ];
  public role = Rol;
  public roleList: { name: string; key: string }[];
  public currentUser: User;
  loading: boolean = true;
  isProd: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  natPerson: any;
  customInfoForm: FormGroup;

  constructor(
    private adminService: AdminService,
    private notificationService: NotificationService,
    public auth: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.currentUser = this.auth.user;
    this.roleList = [];
    this.customInfoForm = new FormGroup({
      customInfo: new FormControl('', [Validators.maxLength(1000)]),
    });
  }

  ngOnInit() {
    this.isProd = environment.name === 'prod';
    for (const key in Rol) this.roleList.push({ name: key, key: Rol[key] });
    this.loading = true;
    this.adminService.getUserList(this.currentUser.rol, this.currentUser.lang).subscribe((userlist) => {
      if (this.isProd) {
        userlist = userlist.filter((user) => user.rol === 'KUNDE');
      }
      this.data = userlist;
      userlist.sort((a, b) => new Date(b.createAt).getTime() - new Date(a.createAt).getTime());

      for (let index = 0; index < this.data.length; index++) {
        const natPersonen = this.data[index].natPersonen;
        this.natPerson = natPersonen[this.findFlaggedNatPerson(natPersonen)];
      }
      this.dataSource = new MatTableDataSource(userlist);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.loading = false;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
      }, 200);
    });
  }

  saveCustomInfo(userId: string, customInfo: string) {
    this.adminService.saveCustomInfo(userId, customInfo).subscribe(
      () => {
        console.log('Custom info saved successfully.');
      },
      (error) => {
        console.error('Error saving custom info:', error);
      }
    );
  }

  /**
   * Such filter
   * @param filterValue such text
   */
  applyFilter(filterValue: string) {
    const filter = filterValue.trim().toLowerCase();

    const filteredData = this.data?.filter((user) => {
      return this.matchesFilter(user, filter);
    });

    this.dataSource.data = filteredData;
  }

  private matchesFilter(user: any, filter: string): boolean {
    const fieldsToCheck = [
      user?.name,
      user?.lastname,
      user?.email,
      user?.lang,
      user.natPersonen[this.findFlaggedNatPerson(user.natPersonen)]?.Firmenname,
      user?.status,
      user?.subStatus,
      user?.customInfo,
      user?.RechtsformAuspraegung,
      user?.finapiStatus,
      user?.rol,
      this.formatDate(user?.lastLogin),
      this.formatDate(user?.createAt),
      this.formatDate(user?.firstTimeStatusActive),
    ];

    return fieldsToCheck.some((field) => field?.toString().toLowerCase().includes(filter));
  }

  private formatDate(date: any): string {
    if (!date) return '';
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return '';

    const day = ('0' + parsedDate.getDate()).slice(-2);
    const month = ('0' + (parsedDate.getMonth() + 1)).slice(-2);
    const year = parsedDate.getFullYear();
    return `${day}.${month}.${year}`;
  }
  /**
   * Funktion für reset password button
   * @param user benutzer
   */
  // resetPassword(user: User) {
  //   this.notificationService.showInfo("Sending Email...");

  //   this.adminService.resetPassword(user).subscribe(() => {
  //     this.notificationService.showSuccess("Email gesended!");
  //   });
  // }

  /**
   * Funktion für reset questions button
   * * @param id
   */
  resetUser(id: string) {
    this.adminService.resetUser(id).subscribe(() => {
      this.notificationService.showSuccess('ADMIN.USER_QUESTIONS_RESETED_SUCCESSFULLY');
      window.sessionStorage.clear();
    });
  }

  userHistoryClicked(id: string) {
    this.router.navigateByUrl(`admin/history/${id}`);
  }

  disableTheRole(): boolean {
    return this.currentUser.rol !== this.role.admin ? true : false;
  }

  disableTheCustomerInfo(): boolean {
    return !(this.currentUser.rol === this.role.adminBB || this.currentUser.rol === this.role.consultant);
  }

  /**
   * Funktion für role ändern
   * @param selectedUser benutzer
   */
  saveRole(selectedUser: User) {
    if (selectedUser.rol === this.role.admin && this.currentUser.rol === this.role.consultant)
      this.notificationService.showErrorTranslate('ADMIN.CONSULTANT_CAN_NOT_DO_ADMINS');
    else
      this.adminService.changeRoleUser(selectedUser._id, selectedUser.rol, this.auth.user).subscribe(() => {
        this.notificationService.showSuccessTranslate('ADMIN.USER_ROLE_CHANGED_SUCCESSFULLY');
      });
  }

  /**
   * Funktion für delete user button
   * @param id
   */
  deleteUser(id: string) {
    this.adminService.deleteUserById(id).subscribe(() => {
      const index = this.data.findIndex((u) => u._id === id);
      this.data.splice(index, 1);
      this.dataSource.data = this.data;
      this.notificationService.showSuccess('ADMIN.USER_DELETED_SUCCESSFULLY');
    });
  }

  unblockUser(id: string) {
    this.adminService.unblockUserById(id).subscribe(() => {
      this.notificationService.showSuccess('ADMIN.USER_UNBLOCKED_SUCCESSFULLY');
      this.router.navigateByUrl('/overview');
    });
  }

  calculateDiff(createAt: any) {
    const dateCreateAt = new Date(createAt).getTime();
    const dateNow = new Date(Date.now()).getTime();
    return Math.floor((dateNow - dateCreateAt) / (1000 * 60 * 60 * 24));
  }

  async viewCreditCheckDetails(user) {
    if (!user.finapi.creditCheck) {
      return;
    } else {
      await this.showcreditCheckDialogDialog(
        'Creditcheck info',
        user.finapi.creditCheck.decisionCredit,
        user.finapi.creditCheck.creditBureauDataSchufa.scoreSegment,
        user.finapi.creditCheck.creditBureauDataCrefo.solvencyIndexTwoZero,
        user.finapi.creditCheck.creditBureauDataSchufa.scoreText,
        user.finapi.creditCheck.creditBureauDataCrefo.response
      );
    }
  }

  async showcreditCheckDialogDialog(headerText, decision, schufa, crefo, textSchufa, textCrefo) {
    this.dialog.open(CreditCheckDialogComponent, {
      data: {
        headerText,
        decision,
        schufa,
        crefo,
        textSchufa,
        textCrefo,
      },
    });
  }

  exportUsers() {
    let userCsvArray = this.setUserArrayForCsv();
    const replacer = (key, value) => (value === null || !value ? 'NA' : value);
    const header = Object.keys(userCsvArray[0]);
    const csv = userCsvArray.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(';')
    );
    csv.unshift(header.join(';'));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob(['\uFEFF' + csvArray], {
      type: 'text/csv; charset=utf-8',
    });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'users.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  private setUserArrayForCsv() {
    const formatValue = (value) => (value === undefined || value === null ? 'NA' : value);

    const formatDate = (date) => (date ? DateTime.fromISO(date).toFormat('dd-MM-yyyy') : 'NA');

    return this.data.map((user) => {
      const natPerson = user.natPersonen[this.findFlaggedNatPerson(user.natPersonen)];
      const csvUser = {
        zulieferer: formatValue(user.lang),
        mandantennummer: formatValue(natPerson.Mandatref),
        vorname: formatValue(user.name),
        name: formatValue(user.lastname),
        firmenname: formatValue(natPerson.Firmenname),
        telefonnummer: formatValue(natPerson.Kontakt?.Telefon),
        emailAdresse: formatValue(natPerson.Kontakt?.Email),
        status: formatValue(user.status),
        subStatus: formatValue(user.subStatus),
        'Letzter Import': formatDate(user.createAt),
        erstesDatumStatusActive: formatDate(user.firstTimeStatusActive),
        erstesDatumBesuchLandingpage: formatDate(user.firstTimeSubStatusInProcess),
        kontokorrent: formatValue(this.getValueBasedOnStatus(user, 'kontokorrentkredit')),
        kreditkarte: formatValue(this.getValueBasedOnStatus(user, 'kreditKarte')),
        kontowechselservice: formatValue(this.getValueBasedOnStatus(user, 'kontoWechselService')),
        abtretungsvertrag: formatValue(this.getValueBasedOnStatus(user, 'abtretungsvertrag')),
        decisionAccount: formatValue(user.finapi?.creditCheck?.decisionAccount),
        decisionCredit: formatValue(user.finapi?.creditCheck?.decisionCredit),
        datumAbschlussProduktkauf: formatDate(user.dateStatusFinish),
        customInfo: formatValue(user.customInfo),
        exportdatum: DateTime.now().toFormat('dd-MM-yyyy'),
      };
      return csvUser;
    });
  }

  getValueBasedOnStatus(user, field) {
    const isFinapiStatus = this.isFinAPIStatus(user);
    return isFinapiStatus ? user[field] : user.questions[0]?.answer[field];
  }

  isFinAPIStatus(user) {
    return user.finapiStatus === 'finAPIProzessAbgebrochen';
  }

  public findFlaggedNatPerson(natPersonen) {
    for (let i = 0; i < natPersonen.length; i++) {
      if (natPersonen[i].Person.VollmachtKontoeroeffnung) {
        return i;
      }
    }
    return 0;
  }
}
