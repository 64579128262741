<div>
  <div class="d-flex">
    <h1>
      Edit User
      <small>{{ selectedUser.name }} {{ selectedUser.lastname }} ({{ selectedUser.email }})</small>
      <button mat-flat-button color="primary" class="ml-4" (click)="downloadUserZip()">Herunterladen</button>
    </h1>
  </div>

  <mat-tab-group>
    <mat-tab label="User Personal Data">
      <ng-template matTabContent>
        <div class="container-fluid">
          <app-zusammenfassung [user]="selectedUser" *ngIf="selectedUser"></app-zusammenfassung>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="User Question Answer">
      <ng-template matTabContent>
        <app-question-answer [user]="selectedUser" *ngIf="selectedUser"></app-question-answer>
      </ng-template>
    </mat-tab>
    <mat-tab label="Documents"> </mat-tab>
  </mat-tab-group>
</div>
