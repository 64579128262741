import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { QuestionService } from 'src/app/services/question.service';

import { Directive, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

/**
 * Hier ist die basis für alle fragen.
 * Alle frage Components mussen von diese class extend.
 *
 * Hier kann mann finden:
 * - der kunde (user) wird hier initializiert, dann kann jeder child die daten ergreifen.
 * - das alle fragen mussen ein FormGroup haben.
 * - alle fragen mussen die methode loadvalues anrufen um automatisch die FormGroup einfüllen.
 */
@Directive()
export abstract class QuestionTemplate implements OnInit {
  questionName: string;
  answerFormGroup: FormGroup;
  private routeName: string;
  @Input() user: User;

  constructor(
    protected auth: AuthService,
    protected questionService: QuestionService,
    protected route: ActivatedRoute | string
  ) {
    if (typeof route === 'string') this.routeName = route;
    else {
      const url = route.snapshot.url;
      if (url.length > 0) this.routeName = url[0].path;
    }
  }

  ngOnInit() {
    this.questionService.setComponent(this.routeName, this);
    if (!this.user) this.user = this.auth.user;
  }

  /**
   * Speichern antworten
   */
  saveAnswer() {
    const question = this.user.questions.find((q) => q.name === this.questionName);
    if (question) question.answer = this.answerFormGroup.value;
    else
      this.user.questions.push({
        name: this.questionName,
        answer: this.answerFormGroup.value,
      });
    return this.questionService.saveQuestions(this.user._id, this.user.questions);
  }

  /**
   * Load question values in the FormGroup
   */
  protected loadValues() {
    const savedQuestions = this.user.questions.find((q) => q.name === this.questionName);
    if (savedQuestions)
      for (const key in savedQuestions.answer) {
        if (savedQuestions.answer.hasOwnProperty(key)) {
          const value = savedQuestions.answer[key];
          if (this.answerFormGroup.controls[key]) this.answerFormGroup.controls[key].setValue(value);
        }
      }
  }
}
