import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { InfoCardComponent } from '../../../component/info-card/info-card.component';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-importuser',
    templateUrl: './importuser.component.html',
    styleUrls: ['./importuser.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        InfoCardComponent,
        MatRadioGroup,
        MatRadioButton,
        MatButton,
    ],
})
export class ImportuserComponent implements OnInit {
  allgemeinFormGroup: FormGroup;
  BrancheInfoGroup: FormGroup;
  ImagelangFormGroup: FormGroup;
  PersonFormGroup: FormGroup;
  LegiFormGroup: FormGroup;
  DokuFormGroup: FormGroup;
  AddresseFormGroup: FormGroup;
  AbwAddresseFormGroup: FormGroup;
  KontaktFormGroup: FormGroup;
  report: String = 'hier wird das Ergebnis Ihren Import gezeigt';
  constructor(
    private adminService: AdminService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.allgemeinFormGroup = new FormGroup({
      Mandatref: new FormControl(33882, [Validators.required]),
      Mandatkennung: new FormControl('ZAHNMEDIZINER', [Validators.required]),
      Firmenname: new FormControl('Zahnarztpraxis Dr. Muster Mann', [Validators.required]),
      Rechtsform: new FormControl('Einzelpraxis', [Validators.required]),
      CrefoID: new FormControl('03453452013699'),
      SteuerID: new FormControl('67624305982'),
    });
    this.BrancheInfoGroup = new FormGroup({
      Fachgebiet: new FormControl('Zahnarztpraxen', [Validators.required]),
      Art: new FormControl('Facharzt', [Validators.required]),
    });
    this.ImagelangFormGroup = new FormGroup({
      imagelang: new FormControl('mcc', [Validators.required]),
    });
    this.PersonFormGroup = new FormGroup({
      Personenart: new FormControl('Kunde', [Validators.required]),
      Kundenart: new FormControl('Firmenkunde', [Validators.required]),
      Anrede: new FormControl('Herr', [Validators.required]),
      Titel: new FormControl('Dr.'),
      Vorname: new FormControl('Muster', [Validators.required]),
      Nachname: new FormControl('Mann', [Validators.required]),
      Briefanrede: new FormControl('Sehr geehrter Herr Muster Mann,', [Validators.required]),
      Geburtsname: new FormControl(''),
      Geburtsdatum: new FormControl('1965-06-04', [Validators.required]),
      Geburtsort: new FormControl('Köln', [Validators.required]),
      Geburtsland: new FormControl('DE', [Validators.required]),
      RechtsformAuspraegung: new FormControl('Freiberufler(in) m.überw.gesch.Charakter', [Validators.required]),
      Position: new FormControl('masdasd', [Validators.required]),
      VollmachtKontoeroeffnung: new FormControl(true, [Validators.required]),
      Vertretungsberechtigt: new FormControl(true, [Validators.required]),
      Wirtschaftlichberechtigt: new FormControl(true, [Validators.required]),
      FiktivWirtschaftlichberechtigt: new FormControl(true, [Validators.required]),
    });
    this.LegiFormGroup = new FormGroup({
      Ausweisart: new FormControl('Personalausweis', [Validators.required]),
      Ausweisnummer: new FormControl('ABCDEFG123456', [Validators.required]),
      AusstellerLand: new FormControl('DE', [Validators.required]),
      AusstellerBehoerde: new FormControl('Köln', [Validators.required]),
      AusgestelltAm: new FormControl('2013-01-12', [Validators.required]),
      GueltigBis: new FormControl('2023-01-12', [Validators.required]),
      GeprueftAm: new FormControl('2022-02-23', [Validators.required]),
      GeprueftVonID: new FormControl('YC9BMMW', [Validators.required]),
      AusfOrganisation: new FormControl('Bankfiliale', [Validators.required]),
    });
    this.DokuFormGroup = new FormGroup({
      Ausweiskopie: new FormControl('data:application/pdf;base64,...', [Validators.required]),
    });
    this.AddresseFormGroup = new FormGroup({
      PLZ: new FormControl('45326', [Validators.required]),
      Ort: new FormControl('Essen', [Validators.required]),
      Strasse: new FormControl('Stauderstraße  15E', [Validators.required]),
      Land: new FormControl('DE', [Validators.required]),
    });
    this.AbwAddresseFormGroup = new FormGroup({
      PLZ: new FormControl('45326'),
      Ort: new FormControl('Essen'),
      Strasse: new FormControl('Winkhausstraße  10'),
      Land: new FormControl('DE'),
    });
    this.KontaktFormGroup = new FormGroup({
      Telefon: new FormControl('0201331542', [Validators.required]),
      Email: new FormControl('muster.mann@muster.com', [Validators.required]),
    });
  }
  importUser() {
    this.report = 'hier wird das Ergebnis Ihren Import gezeigt....';
    var userJson = this.JsonBuilder();
    this.adminService.importUser(userJson).subscribe((res) => {
      this.report = res;
    });
  }
  JsonBuilder() {
    return JSON.stringify({
      NatPersonen: [
        {
          imagelang: this.ImagelangFormGroup.controls['imagelang'].value,
          Mandatref: this.allgemeinFormGroup.controls['Mandatref'].value,
          Mandatkennung: this.allgemeinFormGroup.controls['Mandatkennung'].value,
          Firmenname: this.allgemeinFormGroup.controls['Firmenname'].value,
          Rechtsform: this.allgemeinFormGroup.controls['Rechtsform'].value,
          CrefoID: this.allgemeinFormGroup.controls['CrefoID'].value,
          SteuerID: this.allgemeinFormGroup.controls['SteuerID'].value,
          BranchenInfo: this.BrancheInfoGroup.value,
          Person: this.PersonFormGroup.value,
          Legitimationsdaten: this.LegiFormGroup.value,
          Dokumente: this.DokuFormGroup.value,
          Adresse: this.AddresseFormGroup.value,
          Firmenanschrift: this.AbwAddresseFormGroup.value,
          Kontakt: this.KontaktFormGroup.value,
        },
      ],
    });
  }
}
