import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

import { Component } from '@angular/core';
import { MatDialogRef, MatDialogActions } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    templateUrl: './mantinance-pop-up.component.html',
    styleUrls: ['./mantinance-pop-up.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        MatCheckbox,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class MantinancePopUpComponent {
  minDate = new Date();
  day: Date;
  from: string;
  to: string;
  emails: boolean;

  constructor(
    public dialogRef: MatDialogRef<MantinancePopUpComponent>,
    public notificationService: NotificationService,
    public adminService: AdminService,
    public auth: AuthService
  ) {}

  cancel(): void {
    this.dialogRef.close();
  }

  save() {
    const dateFrom = new Date(this.day);
    const dateTo = new Date(this.day);
    dateFrom.setHours(parseInt(this.from.split(':')[0], 0));
    dateFrom.setMinutes(parseInt(this.from.split(':')[1], 0));
    dateTo.setHours(parseInt(this.to.split(':')[0], 0));
    dateTo.setMinutes(parseInt(this.to.split(':')[1], 0));

    this.adminService
      .setMaintinance({
        from: dateFrom,
        to: dateTo,
        description: '',
        sendEmails: this.emails,
        creator: this.auth.user,
      })
      .subscribe(() => {
        this.notificationService.showSuccessTranslate('ADMIN.MAINTINANCE_SAVED');
        this.dialogRef.close();
      });
  }
}
