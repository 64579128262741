<div class="container-fluid">
  <div class="row my-5">
    <div class="col-12 col-lg-6">
      <mat-form-field floatLabel="always" appearance="outline">
        <input matInput (keyup)="applyFilter($any($event.target).value, tabelle)" placeholder="Filter" name="filter" />
      </mat-form-field>

      <table mat-table [dataSource]="dataSource" class="full-width" #tabelle>
        <ng-container matColumnDef="lang">
          <th mat-header-cell *matHeaderCellDef>Lang</th>
          <td mat-cell *matCellDef="let email">{{ email.lang }}</td>
        </ng-container>

        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef>Betref</th>
          <td mat-cell *matCellDef="let email">{{ email.subject }}</td>
        </ng-container>

        <ng-container matColumnDef="function">
          <th mat-header-cell *matHeaderCellDef>Funktion</th>
          <td mat-cell *matCellDef="let email">{{ email.function }}</td>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef>Edit</th>
          <td mat-cell *matCellDef="let email">
            <!-- Inline div style -->
            <div style="min-width: 100px">
              <button mat-icon-button (click)="editEmail(email)">
                <mat-icon class="mat-18">edit</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['lang', 'subject', 'function', 'buttons']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['lang', 'subject', 'function', 'buttons']"></tr>
      </table>
    </div>

    <div class="col-12 col-lg-6">
      <mat-form-field floatLabel="always" appearance="outline" class="full-width">
        <mat-label appTranslationModal translate="ADMIN.SELECT_LANGUAGE"></mat-label>
        <mat-select [(ngModel)]="selectedEmail.lang" required>
          <mat-option *ngFor="let lang of langList" [value]="lang">
            {{ lang }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" appearance="outline" class="full-width">
        <mat-label appTranslationModal> Funktion </mat-label>
        <mat-select [(ngModel)]="selectedEmail.function" required>
          <mat-option value="ARCHIVE"> - Archivieren - </mat-option>
          <mat-option value="PASSWORD_RESET"> Kenntwort Reset </mat-option>
          <mat-option value="BLANKO_DOCUMENTS"> Blanco Dukumente </mat-option>
          <mat-option value="USER_LOCKED"> User Locked </mat-option>
          <mat-option value="ACCOUNT_VALIDATION"> Account Validation </mat-option>
          <mat-option value="NEW_ELEMENT_IN_POSTFACH"> Neu element in Postfach </mat-option>
          <mat-option value="NEW_SIGN_ELEMENT_IN_POSTFACH"> Neu element in Postfach zum Signieren </mat-option>
          <mat-option value="PROZESS_ITM_BEENDEN"> Itm Beenden </mat-option>
          <mat-option value="PROZESS_ITM_ABGESCHLOSSEN"> Itm abgeschlossen Baader </mat-option>
          <mat-option value="PROZESS_ITM_ABGESCHLOSSEN_LLB"> Itm abgeschlossen LLB </mat-option>
          <mat-option value="PROZESS_ITM_FEHLER"> Itm Fehler </mat-option>
          <mat-option value="BAADER_NOT_SEPA"> Geld Überweisung (nicht SEPA) </mat-option>
          <mat-option value="BAADER_KONTO_EROFFNET"> Konto bei Baader eröffnet </mat-option>
          <mat-option value="BAADER_GELD_EINGELEGT"> Das Geld wurde bei Baader eingelegt </mat-option>
          <mat-option value="BAADER_GELD_INVESTED"> Das Geld wurde investiert </mat-option>
          <mat-option value="MELDEBESCHEINIGUNGS"> Meldebescheinigungs </mat-option>
          <mat-option value="SERVER_MAINTENANCE"> Serverarbeiten </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" appearance="outline" class="full-width">
        <input matInput placeholder="Betref" name="betref" [(ngModel)]="selectedEmail.subject" required />
      </mat-form-field>

      <quill-editor [styles]="{ height: '300px' }" [modules]="defaultModules" [(ngModel)]="selectedEmail.body">
      </quill-editor>

      <button class="my-3" mat-raised-button color="primary" (click)="saveEmail()">
        <span appTranslationModal translate="ADMIN.BUTTON_SAVE_EMAIL"></span>
      </button>

      <div>
        <b>Text options:</b>
        <table>
          <tr>
            <td>__useranrede__</td>
            <td>Anrede Person</td>
          </tr>
          <tr>
            <td>__username__</td>
            <td>Die benutzer name</td>
          </tr>
          <tr>
            <td>__userlastname__</td>
            <td>Die benutzer nachname</td>
          </tr>
          <tr>
            <td>__validationurl__</td>
            <td>Die validation url von neu registriert benutzer</td>
          </tr>
          <tr>
            <td>__resetpassurl__</td>
            <td>Die Reset url zum reset password</td>
          </tr>
          <tr>
            <td>__maintenance__</td>
            <td>(z.B.) 01.05.20 - 10:00h ~ 10:30h</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
